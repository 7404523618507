import {CustomReport} from '@contractool/schema';
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';

import {Context as ReportTabContext} from 'contexts/reports/tab';
import {Context as ReportContext} from 'contexts/reports/tab/report';
import Report from 'components/reports/tab/Report';

import ProjectListOverlay from './ProjectListOverlay';
import EditOverlay from './EditOverlay';

const Provider: React.FC<{
    config: CustomReport;
    idx: number;
    onUpdate: (updatedConfig: CustomReport, idx: number) => Promise<CustomReport>;
    onDelete: (idx: number) => void;
    children: React.ReactNode;
}> = ({config, onUpdate, onDelete, idx, children}) => {
    const handleUpdate = React.useCallback(
        (updatedConfig: CustomReport) => onUpdate(updatedConfig, idx),
        [onUpdate, idx],
    );

    const remove = React.useCallback(async () => {
        await onDelete(idx);
    }, [onDelete, idx]);

    const [configState, setConfigState] = React.useState<CustomReport>(config);
    const configRef = React.useRef<CustomReport>(config);
    // strongly depends on config prop
    React.useEffect(() => {
        setConfigState(config);
        configRef.current = config;
    }, [config]);

    const update = React.useCallback(
        async (updatedConfig: CustomReport) => {
            const config = await handleUpdate(updatedConfig);
            setConfigState(config);
            configRef.current = config;
        },
        [handleUpdate],
    );

    const value = React.useMemo(
        () => ({
            config: configState,
            update,
            remove,
            idx,
        }),
        [configState, update, remove, idx],
    );

    return (
        <ReportContext.Provider value={value}>
            {children}
        </ReportContext.Provider>
    );
};

const Routes = () => {
    const {idx: tabIdx} = React.useContext(ReportTabContext);
    const {idx} = React.useContext(ReportContext);

    return (
        <>
            <Route path="/reports/:tabIdx">
                <Report/>
            </Route>
            <Switch>
                <Route path={`/reports/${tabIdx}/${idx}/edit`}>
                    <EditOverlay/>
                </Route>
                <Route path={`/reports/${tabIdx}/${idx}/project-list`}>
                    <ProjectListOverlay/>
                </Route>
            </Switch>
        </>
    );
};

const View: React.FC<{
    config: CustomReport;
    idx: number;
    onUpdate: (updatedConfig: CustomReport, idx: number) => Promise<CustomReport>;
    onDelete: (idx: number) => void;
}> = ({config, onUpdate, onDelete, idx}) => (
    <Provider
        config={config}
        onUpdate={onUpdate}
        onDelete={onDelete}
        idx={idx}
    >
        <Routes/>
    </Provider>
);

export default View;
