import * as React from 'react';

import {TranslationContext} from 'contexts';
import {Context as ReportsTabContext} from 'contexts/reports/tab';
import {Button} from 'components/Button';

const ReportCreateButton = () => {
    const {idx: selectedTabIdx} = React.useContext(ReportsTabContext);
    const {translate} = React.useContext(TranslationContext);

    return selectedTabIdx !== null ? (
        <Button.Link to={`/reports/${selectedTabIdx}/create`} color="blue" icon="add">
            {translate('Add report')}
        </Button.Link>
    ) : null;
};

export default ReportCreateButton;
