import React, {FC, useContext, useState} from 'react';
import {Route, Switch} from 'react-router';
import {Link} from 'react-router-dom';
import {Project, FieldTemplate} from '@contractool/schema';
import {SidePanel} from './ProjectDetail';
import {Icon} from '../../components/Icon';
import {ProjectDetailsEdit} from './ProjectDetailsEdit';
import {AppContext, GuardContext, TranslationContext} from 'contexts';
import {isAllowedForUser} from '../../utils/form';
import {permissionRequest} from '../../utils/wildcard';

export const ProjectDetailsPanel: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}> = ({project, onUpdate, onClose}) => {
    const [showMore, setShowMore] = useState(false);
    const {config} = useContext(AppContext);
    const {user} = useContext(GuardContext);
    const {translate} = useContext(TranslationContext);

    const fields: FieldTemplate[] = config.fields.filter((field: FieldTemplate) => {
        return isAllowedForUser(field, user) && field.workflow === project.workflow;
    });

    let permissions = project.can;

    return (
        <SidePanel
            heading={translate('Project Details')}
            right={
                <>
                    {config.integration === 'dell_swp' && (
                        <a
                            href="https://legal.one.dell.com/sites/Legal4Clients/NDAs/Pages/default.aspx"
                            className="text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NDA Tool
                        </a>
                    )}
                    {permissionRequest(permissions, 'project.edit') ? (
                        <Link
                            to={`/projects/${project.id}/projectdetails/edit`}
                            className="flex items-center text-blue-700"
                        >
                            <Icon name="edit" size={5}/>
                            <span className="ml-2">{translate('Edit')}</span>
                        </Link>
                    ) : null}
                </>
            }
        >
            {permissionRequest(permissions, 'project.edit') && (
                <Switch>
                    <Route
                        path={`/projects/${project.id}/projectdetails/edit`}
                        render={() => (
                            <ProjectDetailsEdit
                                onClose={onClose}
                                onUpdate={() => {
                                    onUpdate();
                                    onClose();
                                }}
                                project={project}
                            />
                        )}
                    />
                </Switch>
            )}

            <Detail left="Project ID" right={project.instance_id}/>
            <Detail left="Created date" right={project.created_at}/>
            <Detail left="Supplier" right={project.supplier?.name}/>
            {config.config.supplier_poc && <>
                <Detail left="Supplier POC" right={project.supplier_contact?.name}/>
                <Detail
                    left="Supplier POC email"
                    right={
                        <a
                            className="text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`mailto:${project.supplier_contact?.email}`}
                        >
                            {project.supplier_contact?.email}
                        </a>
                    }
                />
                <Detail left="Supplier POC phone" right={project.supplier_contact?.phone}/>
            </>}
            <Detail left="Supplier Country" right={project.supplier?.address_state}/>
            <Detail left="Requestor" right={project.team.singular.requestor?.user.label}/>
            {project.category?.title && <Detail left="Category" right={project.category?.title}/>}
            {project.finishes_at && (
                <Detail left="Target Date for completion" right={project.finishes_at}/>
            )}
            {project.types_human && <Detail left="Types" right={project.types_human}/>}
            {fields
                ? !showMore
                    ? fields.slice(0, 1).map((field, index) => {
                        return (
                            <Detail
                                key={field.label + index}
                                left={(field.group ? field.group + ': ' : '') + field.label}
                                right={project.fields_human[field.name]}
                            />
                        );
                    })
                    : fields.map((field: FieldTemplate, index) => {
                        return (
                            <Detail
                                key={field.label + index}
                                left={(field.group ? field.group + ': ' : '') + field.label}
                                right={project.fields_human[field.name]}
                            />
                        );
                    })
                : null}
            {showMore ? (
                <div
                    className="flex justify-center items-center text-blue-700 cursor-pointer"
                    onClick={() => setShowMore(false)}
                >
                    <Icon name="arrow_downward" className="rotate-180" size={5}/>
                    <span className="ml-2">{translate('Show less')}</span>
                </div>
            ) : (
                <div
                    className="flex justify-center items-center text-blue-700 cursor-pointer"
                    onClick={() => setShowMore(true)}
                >
                    <Icon name="arrow_downward" size={5}/>
                    <span className="ml-2">{translate('Show more')}</span>
                </div>
            )}
        </SidePanel>
    );
};

const Detail: FC<{ left: String; right: any }> = ({left, right}) => {
    const {config} = useContext(AppContext);

    const formattedDateString = React.useMemo(() => {
        if (
            typeof right === 'string' &&
            right.match(/-\d+-/) &&
            new Date(right) instanceof Date &&
            !isNaN(new Date(right).valueOf())
        ) {
            const date = new Date(right);
            const {
                date_format: {skeleton, day, month, year},
            } = config;
            return skeleton
                .replace('day', date.toLocaleDateString('default', {day}))
                .replace('month', date.toLocaleDateString('default', {month}))
                .replace('year', date.toLocaleDateString('default', {year}));
        }
    }, [right, config]);

    return (
        <div className="flex justify-between my-5">
            <div className="text-gray-600">{left}</div>
            <div className="text-right text-gray-700 capitalize">
                {formattedDateString || right || '---'}
            </div>
        </div>
    );
};
