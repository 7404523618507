import React, {FC, useContext, useState} from 'react';
import {Button} from '../../components/Button';
import {Confirmation} from '../../components/Confirmation';
import {Form} from '../../components/Form';
import {Icon} from '../../components/Icon';
import {Modal} from '../../components/Modal';
import {CustomField} from 'components';
import {http} from '../../utils/http';
import {PreconditionResponse} from './ProjectActions';
import {Project} from '@contractool/schema';
import {permissionRequest} from '../../utils/wildcard';
import {TranslationContext} from 'contexts';

export const ProgressPanel: FC<{project: Project; onRefresh: () => void}> = ({
    project,
    onRefresh,
}) => {
    const {translate} = useContext(TranslationContext);

    const canceled = ['denied', 'canceled'].some((item) => project.state.key.includes(item));
    const [modal, setModal] = useState(false);
    const [response, setResponse] = useState<PreconditionResponse>();
    const [modalAction, setModalAction] = useState('progress');

    const permissions = project.can;

    const TypeColor = (type: string) => {
        switch (type) {
            case 'past': {
                return {backgroundColor: 'bg-blue-700', textColor: 'text-blue-700'};
            }
            case 'current': {
                return {backgroundColor: 'bg-yellow-800', textColor: 'text-yellow-800'};
            }
            case 'finished': {
                return {backgroundColor: 'bg-green-700', textColor: 'text-green-700'};
            }
            case 'canceled': {
                return {backgroundColor: 'bg-gray-500', textColor: 'text-gray-600'};
            }
            // TODO: not designed skipped state
            case 'past-dimmed': {
                return {backgroundColor: 'bg-blue-700', textColor: 'text-blue-700'};
            }
            default:
                return {backgroundColor: 'bg-gray-100', textColor: 'text-gray-600'};
        }
    };

    const progress = (value: string) => {
        return http
            .put(`/api/projects/${project.id}/state`, {
                input: value,
            })
            .then(onRefresh)
            .catch((error) => {
                if (error.response?.status !== 412) {
                    return;
                }
                setResponse(error.response.data.data);
                setModal(true);
                setModalAction(value);
            });
    };

    return (
        <div className="flex mt-16">
            {project.statistics
                .filter((statistic) => statistic.type !== 'canceled')
                .map((statistic, index) => {
                    const {backgroundColor, textColor} = canceled
                        ? TypeColor(statistic.type)
                        : TypeColor(statistic.type);
                    return (
                        <div key={index} className="w-full mr-1" data-cy-type={statistic.type}>
                            <div className="text-center text-gray-600 mb-4">
                                {statistic.duration ? (
                                    <div className={textColor}>
                                        {/* TODO: not designed sipped state */}
                                        {statistic.duration === 'skipped'
                                            ? 'Skipped'
                                            : statistic.duration}
                                    </div>
                                ) : (
                                    <div className={textColor}>
                                        {statistic.type === 'finished' ? '-' : '-'}
                                    </div>
                                )}
                            </div>
                            <div
                                className={`w-full mr-1 h-1 mb-4 rounded-full ${backgroundColor}`}
                            ></div>
                            <div className="text-center text-gray-600">{statistic.title}</div>
                        </div>
                    );
                })}
            <div className="flex ml-12">
                {project.state.transitions.map((transition, index) => {
                    return (
                        <div key={index} className="self-center">
                            {transition.value === 'progress' &&
                                permissionRequest(permissions, 'project.progress') && (
                                    <Button
                                        onClick={() => {
                                            progress(transition.value + '');
                                        }}
                                        className="whitespace-no-wrap"
                                    >
                                        {translate(transition.label)}
                                        <Icon className="ml-3" name="arrow_forward" size={5} />
                                    </Button>
                                )}
                            {transition.value === 'regress' &&
                                permissionRequest(permissions, 'project.regress') && (
                                    <Button
                                        onClick={() => {
                                            progress(transition.value + '');
                                        }}
                                        title={translate(transition.label)}
                                        className="mr-4"
                                        color="white"
                                        icon="settings_restore"
                                    ></Button>
                                )}
                            {transition.value === 'reopen' && (
                                <div>
                                    <Confirmation
                                        onConfirm={() => {
                                            progress(transition.value + '');
                                        }}
                                        trigger={({onClick}) => (
                                            <Button
                                                onClick={onClick}
                                                color="white"
                                                className="whitespace-no-wrap"
                                            >
                                                <Icon className="mr-3" name="refresh" size={5} />
                                                {transition.label}
                                            </Button>
                                        )}
                                        heading={translate(transition.label)}
                                        buttonText={`${translate('Yes, :value', {
                                            value: translate(transition.value),
                                        })}`}
                                        color="yellow"
                                    >
                                        {transition.extra.confirm}
                                    </Confirmation>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            <Modal
                heading={
                    response?.title ? response.title : `${translate('Before you continue')}...`
                }
                isOpen={modal}
                onClose={() => setModal(false)}
                size="regular"
            >
                <Form
                    initialValues={{...response?.state}}
                    onSubmit={(values) =>
                        http.put(`/api/projects/${project.id}/state`, {
                            dialog: values,
                            input: modalAction,
                        })
                    }
                    onSuccess={() => {
                        onRefresh();
                        setModal(false);
                    }}
                    allowPristineSubmission
                    loader={'big'}
                >
                    {response?.text && <div className="my-10">{response.text}</div>}
                    {response &&
                        response.form.map((field, index) => (
                            <CustomField
                                field={field}
                                option=""
                                key={`modal-field-${index}`}
                                className="mb-5"
                            />
                        ))}

                    <Modal.Footer className="flex justify-between">
                        <Button color="white" onClick={() => setModal(false)}>
                            {translate('Cancel')}
                        </Button>
                        <Form.Submit>{translate('Save Changes')}</Form.Submit>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};
