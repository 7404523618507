import React, {useState} from 'react';
import {Dropdown, Option} from '../../components/Dropdown';
import {Section, Variation} from './DesignSystem';
import {Button} from '../../components/Button';
import {MultiselectDropdown} from '../../components/MultiselectDropdown';

type T = string | boolean | number;

export function Dropdowns() {
    const [selectOne, setSelectOne] = useState<string | null>(null);
    const [selectTwo, setSelectTwo] = useState('bar');
    const [selectFour, setSelectFour] = useState('bar');
    const options = [
        {label: 'Foo', value: 'foo'},
        {label: 'Bar', value: 'bar'},
        {label: 'Baz', value: 'baz'},
        {label: 'Qux', value: 'qux'},
    ];
    const [selectMultiple, setSelectMultiple] = useState<T[]>(['bar']);
    const handleMultipleSelect = (values: Option<string | boolean | number>[]) => {
        setSelectMultiple(values.map((value) => value.value));
    };

    return (
        <>
            <Section title="Dropdown">
                <Variation hint="Basic dropdown">
                    <Dropdown
                        name="react-select-1"
                        value={selectOne}
                        onChange={setSelectOne}
                        options={options}
                        placeholder="Select an option"
                    />
                </Variation>

                <Variation hint="Dropdown with a selected value">
                    <Dropdown
                        name="react-select-2"
                        value={selectTwo}
                        onChange={setSelectTwo}
                        options={options}
                        placeholder="Select an option"
                    />
                </Variation>

                <Variation hint="Dropdown with search">
                    <Dropdown
                        name="react-select-1"
                        value={selectOne}
                        onChange={setSelectOne}
                        options={options}
                        placeholder="Select an option"
                        autocomplete={true}
                    />
                </Variation>
            </Section>

            <Section title="Error state">
                <Variation hint="Dropdown in error state">
                    <Dropdown
                        name="react-select-3"
                        value={selectOne}
                        onChange={setSelectOne}
                        options={options}
                        placeholder="Select an option"
                        hasError={true}
                    />
                </Variation>
            </Section>

            <Section title="Rendering target">
                <Variation hint="Rendered using portal (inspect DOM)">
                    <Dropdown
                        name="react-select-4"
                        value={selectFour}
                        onChange={setSelectFour}
                        options={options}
                        placeholder="Select an option"
                        usePortal={true}
                    />
                </Variation>

                <Variation hint="Rendered in place (inspect DOM)">
                    <Dropdown
                        name="react-select-4"
                        value={selectFour}
                        onChange={setSelectTwo}
                        options={options}
                        placeholder="Select an option"
                        usePortal={false}
                    />
                </Variation>
            </Section>

            <Section title="Dropdown position">
                <Variation hint="Dropdown appears on the bottom-start">
                    <Dropdown
                        name="react-select-5"
                        value={selectFour}
                        onChange={setSelectFour}
                        options={options}
                        placeholder="Select an option"
                        dropdownPlacement="bottom-start"
                    />
                </Variation>

                <Variation hint="Dropdown appears on the bottom">
                    <Dropdown
                        name="react-select-5"
                        value={selectFour}
                        onChange={setSelectFour}
                        options={options}
                        placeholder="Select an option"
                        dropdownPlacement="bottom"
                    />
                </Variation>
            </Section>

            <Section title="MultiSelect">
                <Variation hint="MultiSelect dropdown with checkboxes">
                    <MultiselectDropdown
                        name="multiselect"
                        values={selectMultiple}
                        options={options}
                        placeholder="Role"
                        onChange={handleMultipleSelect}
                    ></MultiselectDropdown>
                </Variation>
                <Variation hint="MultiSelect dropdown with search">
                    <MultiselectDropdown
                        name="multiselect-search"
                        values={selectMultiple}
                        options={options}
                        placeholder="Role"
                        onChange={handleMultipleSelect}
                        useSearch={true}
                    ></MultiselectDropdown>
                </Variation>
            </Section>

            <Section title="Custom input">
                <Variation hint="You can replace the default input">
                    <Dropdown
                        name="react-select-4"
                        value={selectFour}
                        onChange={setSelectFour}
                        options={options}
                        placeholder="Select an option"
                        renderInput={({selectedItem, toggleMenu}) => (
                            <Button onClick={toggleMenu}>
                                Selected item: {selectedItem?.label}
                            </Button>
                        )}
                    />
                </Variation>
            </Section>
        </>
    );
}
