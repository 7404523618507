import {LogFull} from '@contractool/schema';
import React, {FC} from 'react';
import {Icon} from '../../../components/Icon';
import {ActivityContainer, ActivityHeader, ActivityBody} from '../ActivityPanel';

export const DocumentLog: FC<{
    desc: string;
    log: LogFull;
}> = ({desc, log}) => {
    const icon = iconHandler(log.message.extra.title);
    return (
        <ActivityContainer>
            <ActivityHeader
                causer={log.causer}
                desc={desc}
                created_at={log.created_at}
                timezone={log.causer.timezone}
            />
            <ActivityBody className="bg-gray-100 rounded-lg p-5 inline-block">
                <div className="flex items-center">
                    <span className="mr-4">{icon}</span>
                    {log.message.extra.title}
                </div>
            </ActivityBody>
        </ActivityContainer>
    );
};

export const iconHandler = (title: string | undefined) => {
    let extension = title?.split('.')[1]
    switch (extension) {
        case 'pdf':
            return <Icon name="pdf" size={6} className="text-red-600" />;
        case 'docx':
            return <Icon name="word" size={6} className="text-blue-600" />;
        default:
            return <Icon name="description" size={6} className="text-blue-600" />;
    }
};
