import React, {FC, useContext} from 'react';
import {Project,} from '../../../../../_common/schema';
import AppContext from "contexts/AppContext";
import {DemoTutorial} from "integrations/demo/DemoTutorial";
import {DellSwpTutorial} from "integrations/dell_swp/DellSwpTutorial";

export const Tutorial: FC<{ project: Project }> = ({project}) => {
    console.log('tutorial', project);
    const {config} = useContext(AppContext);
    let tutorial = <></>;
    switch (config.integration) {
        case 'demo' :
            tutorial = <DemoTutorial/>;
            break;
        case 'dell_swp' :
            tutorial = <DellSwpTutorial/>;
            break;
    }
    return (
        <div className="p-10 border-b border-gray-200">{tutorial}</div>
    );
};
