import React, {FC} from 'react';
import {Icon} from '../../../components/Icon';
import {ActivityContainer, ActivityHeader, ActivityBody} from '../ActivityPanel';
import {LogFull} from '@contractool/schema';

export const StateLog: FC<{
    desc: string;
    log: LogFull;
}> = ({desc, log}) => {
    // const iconFrom = iconHandler(log.message.extra.from);
    // const iconTo = iconHandler(log.message.extra.to);

    return (
        <ActivityContainer>
            <ActivityHeader
                causer={log.causer}
                desc={desc}
                created_at={log.created_at}
                timezone={log.causer.timezone}
            />
            <ActivityBody className="flex items-center">
                <>
                    {/* TODO: Find out which icons to use */}
                    {/* {iconFrom} */}
                    {log.message.extra.from}
                    <Icon name="arrow_forward" size={6} className="text-gray-600 mx-8" />
                    {/* {iconTo} */}
                    <span className="text-blue-700">{log.message.extra.to}</span>
                </>
            </ActivityBody>
        </ActivityContainer>
    );
};
