import React, {FC} from 'react';
import {RouteTabs, Tab} from '../../components/Tabs';
import {Buttons} from './Buttons';
import {Modals} from './Modals';
import {Forms} from './Forms';
import {Messages} from './Messages';
import {TabsSection} from './Tabs';
import {Toasts} from './Toasts';
import {Dropdowns} from './Dropdowns';
import {PaginationControls} from './PaginationControls';
import {Menus} from './Menus';
import {Page} from '../../components/Page';
import {FormGuards} from './FormGuards';
import {DatePickers} from './DatePickers';
import {Icons} from './Icons';
import {FormTabsSection as FormTabs} from './FormTabs';
import {Loaders} from './Loaders';

export function DesignSystem() {
    return (
        <Page heading="UI Library">
            <RouteTabs match="/ui/:tab">
                <Tab name="buttons" heading="Buttons" className="py-8">
                    <Buttons />
                </Tab>

                <Tab name="dropdowns" heading="Dropdowns" className="py-8">
                    <Dropdowns />
                </Tab>

                <Tab name="date-pickers" heading="Date picker" className="py-8">
                    <DatePickers />
                </Tab>

                <Tab name="forms" heading="Forms" className="py-8">
                    <Forms />
                </Tab>

                <Tab name="modals" heading="Modals" className="py-8">
                    <Modals />
                </Tab>

                <Tab name="tabs" heading="Tabs" className="py-8">
                    <TabsSection />
                </Tab>

                <Tab name="toasts" heading="Toasts" className="py-8">
                    <Toasts />
                </Tab>

                <Tab name="messages" heading="Messages" className="py-8">
                    <Messages />
                </Tab>

                <Tab name="pagination" heading="Pagination" className="py-8">
                    <PaginationControls />
                </Tab>

                <Tab name="menus" heading="Menus" className="py-8">
                    <Menus />
                </Tab>

                <Tab name="form-guards" heading="Form guards" className="py-8">
                    <FormGuards />
                </Tab>

                <Tab name="icons" heading="Icons" className="py-8">
                    <Icons />
                </Tab>

                <Tab name="form-tabs" heading="Form tabs" className="py-8">
                    <FormTabs />
                </Tab>

                <Tab name="loaders" heading="Loaders" className="py-8">
                    <Loaders />
                </Tab>
            </RouteTabs>
        </Page>
    );
}

export const Section: FC<{title: string}> = ({title, children}) => {
    return (
        <div>
            <h3 className="text-xl mt-8 mb-4">{title}</h3>

            <div className="flex flex-wrap -mx-6">{children}</div>
        </div>
    );
};

export const Variation: FC<{hint?: string; center?: boolean}> = ({
    children,
    hint,
    center = false,
}) => {
    return (
        <div className={`flex flex-col justify-between mx-6 ${center && 'items-center'}`}>
            <div>{children}</div>
            {hint && <p className="flex items-center text-gray-600 text-sm py-8 italic">{hint}</p>}
        </div>
    );
};
