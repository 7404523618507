import React, {FC, useContext} from 'react';
import {Button} from '../../components/Button';
import {Form} from '../../components/Form';
import {http} from '../../utils/http';
import {User, UserGroup} from '@contractool/schema';
import {Tab, RouteTabs} from '../../components/Tabs';
import {useToasts} from '../../components/Toast';
import {Modal} from '../../components/Modal';
import {useParams, useHistory} from 'react-router-dom';
import {timezones} from '../../utils/timezones';
import {Field} from '../../components/Field';
import {Switch} from '../../components/Switch';
import {useRequest} from '../../utils/hooks/useRequest';
import {Menu, MenuItem} from '../../components/Menu';
import {UserStateLabel} from './UserStateLabel';
import {AvatarUpload} from '../../components/Avatar';
import {Confirmation} from '../../components/Confirmation';
import {TranslationContext} from 'contexts';

export function TeamEdit({onUpdate}: {onUpdate: () => void}) {
    const history = useHistory();
    const {success} = useToasts();
    const {id} = useParams();
    const [user, {refresh}] = useRequest<User | undefined>(`/api/users/${id}`, undefined);

    const {translate} = useContext(TranslationContext);

    const close = () => {
        history.push('/team');
    };

    if (typeof user === 'undefined') {
        return null;
    }

    return (
        <Modal
            heading={translate('User detail')}
            compact={true}
            onClose={() => close()}
            contentClassName="h-149"
            corner={
                <StateMenu
                    user={user}
                    onRefresh={() => {
                        refresh();
                        onUpdate();
                    }}
                    onClose={() => close()}
                />
            }
        >
            <div className="flex items-center mb-11">
                <div>
                    <AvatarUpload
                        user={user}
                        onUpload={() => {
                            refresh();
                            success(
                                `${translate(":name's avatar was successfully changed", {
                                    name: user.name,
                                })}`,
                            );
                        }}
                        className="w-32 h-32"
                    />
                </div>

                <div className="ml-11">
                    <div className="flex items-center">
                        <h2 className="text-xl inline">{user.name}</h2>
                        <UserStateLabel user={user} className="mx-4"></UserStateLabel>
                    </div>
                    <p className="text-gray-600">{user.licence}</p>
                </div>
            </div>

            <RouteTabs match={`/team/${user.id}/edit/:tab`}>
                <Tab name="general" heading={translate('General')} className="pt-8">
                    <GeneralTab
                        user={user}
                        onSuccess={() => {
                            success(
                                `${translate(":name's details were successfully updated", {
                                    name: user.name,
                                })}`,
                            );
                            onUpdate();
                            close();
                        }}
                        onClose={() => close()}
                    />
                </Tab>
                <Tab name="password" heading={translate('Password')} className="pt-8">
                    <PasswordTab
                        user={user}
                        onSuccess={() => {
                            success(
                                `${translate(":name's password was successfully updated", {
                                    name: user.name,
                                })}`,
                            );
                            onUpdate();
                            close();
                        }}
                        onClose={() => close()}
                    />
                </Tab>
                <Tab name="groups" heading={translate('Groups')} className="pt-8">
                    <GroupsTab
                        user={user}
                        onSuccess={() => {
                            success(
                                `${translate(":name's groups were successfully updated", {
                                    name: user.name,
                                })}`,
                            );
                            onUpdate();
                            close();
                        }}
                        onClose={() => close()}
                    />
                </Tab>
            </RouteTabs>
        </Modal>
    );
}

const GeneralTab: FC<{user: User; onSuccess: () => void; onClose: () => void}> = ({
    user,
    onSuccess,
    onClose,
}) => {
    const {translate} = useContext(TranslationContext);

    return (
        <Form
            initialValues={user}
            onSubmit={(values) => http.put<User>(user.url, values)}
            onSuccess={onSuccess}
        >
            <Form.TextInput
                name="name"
                label={translate('Name')}
                className="mb-6"
                autoFocus={true}
            />
            <Form.TextInput name="email" label={translate('Email')} className="mb-6" />
            <Form.Dropdown
                name="timezone"
                label={translate('Timezone')}
                options={timezones}
                autocomplete
            />

            <Modal.Footer className="flex justify-between">
                <Button color="white" onClick={onClose}>
                    {translate('Cancel')}
                </Button>

                <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
        </Form>
    );
};

const PasswordTab: FC<{user: User; onSuccess: () => void; onClose: () => void}> = ({
    user,
    onSuccess,
    onClose,
}) => {
    const {translate} = useContext(TranslationContext);

    return (
        <Form
            initialValues={{password: '', password_confirmation: ''}}
            onSubmit={(values) => http.put(user.password_url, values)}
            onSuccess={onSuccess}
        >
            <Form.TextInput
                name="password"
                type="password"
                label={translate('Password')}
                className="mb-6"
                autoFocus={true}
            />
            <Form.TextInput
                name="password_confirmation"
                type="password"
                label={translate('Confirm password')}
            />

            <Modal.Footer className="flex justify-between">
                <Button color="white" onClick={onClose}>
                    {translate('Cancel')}
                </Button>

                <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
        </Form>
    );
};

const GroupsTab: FC<{user: User; onSuccess: () => void; onClose: () => void}> = ({
    user,
    onSuccess,
    onClose,
}) => {
    const [{user_groups}] = useRequest<{user_groups: UserGroup[]}>('/api/configuration', {
        user_groups: [],
    });
    const {translate} = useContext(TranslationContext);

    //TODO roles vypocitavat a zobrazit licenciu na zaklade zaskrtnutych grup
    return (
        <Form
            initialValues={{groups: user.groups}}
            onSubmit={(values) => http.put(user.roles_url, values)}
            onSuccess={onSuccess}
        >
            <Field name="groups" label={translate('Groups')}>
                <Form.Context>
                    {({values, handleChange}) => {
                        return (
                            <Switch.Multiple
                                name="groups"
                                value={values.groups}
                                items={user_groups}
                                toKey={(group) => group.key}
                                onChange={(values) => handleChange('groups', values)}
                                className="flex flex-wrap py-4"
                            >
                                {(group, Switch) => (
                                    <div key={group.key} className="w-1/2 p-2">
                                        <Switch>{group.label}</Switch>
                                    </div>
                                )}
                            </Switch.Multiple>
                        );
                    }}
                </Form.Context>
            </Field>

            <Modal.Footer className="flex justify-between">
                <Button color="white" onClick={onClose}>
                    {translate('Cancel')}
                </Button>

                <Form.Submit>{translate('Save Changes')}</Form.Submit>
            </Modal.Footer>
        </Form>
    );
};

const StateMenu: FC<{user: User; onRefresh: () => void; onClose: () => void}> = ({
    user,
    onRefresh,
    onClose,
}) => {
    const {success} = useToasts();
    const {translate} = useContext(TranslationContext);

    return (
        <Menu handle="more_vert">
            {user.state === 'active' && (
                <Confirmation
                    onConfirm={() => {
                        http.put(user.state_url, {state: 'deactivated'}).then(() => {
                            onRefresh();
                            success(
                                `${translate('User :name was successfully deactivated', {
                                    name: user.name,
                                })}.`,
                            );
                        });
                    }}
                    trigger={({onClick}) => (
                        <MenuItem
                            icon="lock"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                            }}
                        >
                            {translate('Deactivate')}
                        </MenuItem>
                    )}
                    heading={translate('Deactivate user')}
                    buttonText={translate('Yes, deactivate')}
                    color="yellow"
                >
                    {translate('Are you sure you want to deactivate user :name?', {
                        name: user.name,
                    })}
                </Confirmation>
            )}
            {user.state === 'deactivated' && (
                <Confirmation
                    onConfirm={() => {
                        http.put(user.state_url, {state: 'active'}).then(() => {
                            onRefresh();
                            success(
                                `${translate('User :name was successfully activated', {
                                    name: user.name,
                                })}.`,
                            );
                        });
                    }}
                    trigger={({onClick}) => (
                        <MenuItem
                            icon="lock_open"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                            }}
                        >
                            {translate('Activate')}
                        </MenuItem>
                    )}
                    heading={translate('Activate user')}
                    buttonText={translate('Yes, activate')}
                    color="yellow"
                >
                    {translate('Are you sure you want to activate user :name?', {
                        name: user.name,
                    })}
                </Confirmation>
            )}
            <Confirmation
                onConfirm={() => {
                    http.put(user.state_url, {state: 'deleted'}).then(() => {
                        onClose();
                        onRefresh();
                        success(
                            `${translate('User :name was successfully deleted', {
                                name: user.name,
                            })}.`,
                        );
                    });
                }}
                trigger={({onClick}) => (
                    <MenuItem
                        icon="remove_circle"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClick();
                        }}
                    >
                        {translate('Delete')}
                    </MenuItem>
                )}
                heading={translate('Delete user')}
                buttonText={translate('Yes, delete')}
                color="red"
            >
                {translate(
                    "Are you sure you want to delete user :name? You can't undo this action.",
                    {
                        name: user.name,
                    },
                )}
            </Confirmation>
        </Menu>
    );
};
