import {ReportTab, FilterTemplate} from '@contractool/schema';
import {groupBy} from 'lodash';
import * as React from 'react';
import {Route, Redirect, useHistory, useParams} from 'react-router-dom';

import {AppContext, TranslationContext} from 'contexts';
import {Icon} from 'components/Icon';
import {Page} from 'components/Page';
import {Tab, Tabs} from 'components/Tabs';
import {useRequest} from 'hooks/useRequest';
import {Context as ReportsContext} from 'contexts/reports';
import {http} from 'utils/http';
import {useToggle} from 'hooks';
import DellJiReportsView from 'integrations/dell_ji/components/views/reports';

import ReportTabView from './tab';
import TabsOverlay from './TabsOverlay';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const getTabName = (tab: ReportTab, idx: number) => tab ? `${tab.heading} ${idx}` : '';

const ReportTabs: React.FC = ({children}) => {
    const {tabs} = React.useContext(ReportsContext);

    // todo: refactor Tabs component and remove this block
    const {tabIdx} = useParams();
    const idx = Number(tabIdx);
    const tab = tabs[idx];
    const selectedTabName = getTabName(tab, idx);
    // end block

    const history = useHistory();
    const handleSelectTab = React.useCallback((name: string) => {
        const segments = name.split(' ');
        const idx = Number(segments[segments.length - 1]);
        history.push(`/reports/${idx}`);
    }, [history]);

    return tabs.length > 0 ? (
        <Tabs
            selected={selectedTabName}
            onSelect={handleSelectTab}
        >
            {tabs.map((tab: ReportTab, idx: number) => (
                <Tab
                    key={getTabName(tab, idx)}
                    name={getTabName(tab, idx)}
                    heading={tab.heading}
                    className="pt-4"
                >
                    <div className={"-mx-6"}>
                        {children}
                    </div>
                </Tab>
            ))}
        </Tabs>
    ) : null;
};

const Layout = () => {
    const {translate} = React.useContext(TranslationContext);
    const [tabsOverlayVisible, tabsOverlayDisplayToggle] = useToggle(false);

    return (
        <div className="bg-gray-000 min-h-screen">
            <Page
                heading={translate('Reports')}
                right={<div id="create-button"/>}
            >
                <div className="relative">
                    <div
                        title={translate('Manage tabs')}
                        className="absolute top-0 right-0 text-gray-500 cursor-pointer"
                    >
                        <button
                            className="p-4 bg-transparent text-blue-700 outline-none focus:shadow-outline rounded-lg"
                            onClick={tabsOverlayDisplayToggle.on}
                        >
                            <Icon name="settings" size={5}/>
                        </button>
                    </div>
                </div>
                <ReportTabs>
                    <ReportTabView/>
                </ReportTabs>
                {tabsOverlayVisible && (
                    <TabsOverlay onDismiss={tabsOverlayDisplayToggle.off}/>
                )}
            </Page>
        </div>
    );
};

const Provider: React.FC = ({children}) => {
    const [filterTemplates] = useRequest<FilterTemplate[]>('/api/project-filter-templates', []);

    const filteredFilterTemplates = filterTemplates // todo: move filtering logic to backend
        .filter((filter: FilterTemplate) => {
            return ['FetchMultiSelect', 'SELECT'].includes(filter.type) ||
                (filter.name.indexOf('fields->') === 0 && ['DATE_RANGE'].includes(filter.type)) ||
                (filter.name === 'created_at')
        });

    const groupedFilterTemplates = groupBy(filteredFilterTemplates, 'name');

    const [tabs, {refresh: refreshTabs}] = useRequest<ReportTab[]>('/api/report-tabs', []);
    const createTab = React.useCallback(async (payload: ReportTab) => {
        const {
            data: newTab
        } = await http.post<ReportTab>('/api/report-tabs', payload);

        return newTab;
    }, []);

    const updateTab = React.useCallback(async (idx: number, payload: ReportTab) => {
        const {
            data: updatedTab
        } = await http.put<ReportTab>(`/api/report-tabs/${idx}`, payload);

        return updatedTab;
    }, []);

    const removeTab = React.useCallback(async (idx: number) => {
        const {
            data: deletedTab
        } = await http.delete<ReportTab>(`/api/report-tabs/${idx}`);

        return deletedTab;
    }, []);

    const orderTabs = React.useCallback(async (order: number[]) => {
        const {
            data: tabs
        } = await http.post<ReportTab[]>(`/api/report-tabs-order`, {
            order
        });

        return tabs;
    }, []);

    const value = React.useMemo(
        () => ({
            filterTemplates: filteredFilterTemplates,
            groupedFilterTemplates,
            tabs,
            createTab,
            updateTab,
            removeTab,
            orderTabs,
            refreshTabs
        }), [
            filteredFilterTemplates,
            groupedFilterTemplates,
            tabs,
            createTab,
            updateTab,
            removeTab,
            orderTabs,
            refreshTabs
        ],
    );

    return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
};

const Routes = () => (
    <>
        <Route path="/reports" exact>
            <Redirect to={'/reports/0'}/>
        </Route>
        <Route path="/reports/:tabIdx">
            <Layout/>
        </Route>
    </>
)

const View = () => (
    <Provider>
        <Routes/>
    </Provider>
);

const ReportsPage = () => {
    const {config} = React.useContext(AppContext);
    const {integration} = config;

    return 'dell_ji' === integration ? <DellJiReportsView/>
        : 'scantraxx' === integration ? null
            : <View/>;
};

export default ReportsPage;

