import React from 'react';
import {Document} from '@contractool/schema';
import {http} from '../../utils/http';
import {Link} from 'react-router-dom';
import {Icon, Icons} from '../Icon';
import {sign} from '../../utils/auth';

export function DocumentComponent({
    document,
    modalUrl,
    see = true,
    deletable = true,
    download = true,
    change = true,
}: {
    document: Document;
    modalUrl: string;
    see?: boolean;
    deletable?: boolean;
    download?: boolean;
    change?: boolean;
}) {
    let icon: Icons;
    switch (document.icon) {
        case 'file-alt':
        case 'file-archive':
        case 'file-audio':
        case 'file-excel':
        case 'file-image':
        case 'file-pdf':
        case 'file-powerpoint':
        case 'file-video':
        case 'file-word':
            icon = document.icon;
            break;
        default:
            icon = 'description';
    }
    const deleteAttachment = (document: Document) => {
        http.delete(document.url)
            // .then(onUpdate)
            .catch((error) => {
                console.log('delete error', error);
            });
    };
    return (
        <div className="border-b border-gray-100 py-7 flex" key={document.id}>
            {change ? (
                <Link to={`/${modalUrl}/documents/${document.id}`} className="flex">
                    <Icon name={icon} size={6} />
                    <div className="leading-tighter pl-4 text-gray-700">{document.title}</div>
                </Link>
            ) : (
                <div className="flex">
                    <Icon name={icon} size={6} />
                    <div className="leading-tighter pl-4 text-gray-700">{document.title}</div>
                </div>
            )}
            <div className="flex-grow flex justify-end">
                {see && (
                    <a href={sign(document.preview_url)} target="_blank" rel="noopener noreferrer">
                        <Icon name="remove_red_eye" size={6} className="ml-3 text-gray-400" />
                    </a>
                )}
                {download && (
                    <a href={sign(document.download_url)} download>
                        <Icon name="get_app" size={6} className="ml-3 text-gray-400" />
                    </a>
                )}
                {deletable && (
                    <span
                        onClick={() => {
                            deleteAttachment(document);
                        }}
                        className="cursor-pointer focus:outline-none"
                    >
                        <Icon name="delete" size={6} className="ml-3 text-gray-400" />
                    </span>
                )}
            </div>
        </div>
    );
}
