import {TranslationContext} from 'contexts';
import React, {FC, useContext} from 'react';
import {Project} from '../../../../../_common/schema';
import {Confirmation} from '../../../components/Confirmation';
import {MenuItem} from '../../../components/Menu';

export const Clone: FC<{project: Project}> = ({project}) => {
    const {translate} = useContext(TranslationContext);

    return (
        <Confirmation
            onConfirm={() => {
                window.location.href = `/#/projects/new/form?clone=${project.id}`;
            }}
            trigger={({onClick}) => (
                <MenuItem
                    icon="duplicate"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                    }}
                >
                    {translate('Clone project')}
                </MenuItem>
            )}
            heading={`${translate('Clone project')}?`}
            buttonText={translate('Yes, clone')}
            color="yellow"
        >
            {translate('Are you sure you want to clone this project?')}
        </Confirmation>
    );
};
