import {ReportTab} from '@contractool/schema';
import * as React from 'react';
import {ReactSortable} from 'react-sortablejs';

import {TranslationContext} from 'contexts';
import {Context as ReportsContext} from 'contexts/reports';
import {Button} from 'components/Button';
import {Confirmation} from 'components/Confirmation';
import {Icon} from 'components/Icon';
import {Modal} from 'components/Modal';
import {TextInput} from 'components/TextInput';
// import {useToasts} from 'components/Toast';

const TabsOverlay: React.FC<{
    onDismiss: () => void
}> = ({onDismiss}) => {
    const {translate} = React.useContext(TranslationContext);
    const {tabs, createTab, updateTab, removeTab, orderTabs, refreshTabs} = React.useContext(ReportsContext);

    const [tabsState, setTabsState] = React.useState<ReportTab[]>(tabs);
    React.useEffect(() => {
        setTabsState(tabs);
    }, [tabs]);

    const handleClose = React.useCallback(() => {
        onDismiss();
    }, [onDismiss]);

    const [draftTab, setDraftTab] = React.useState<ReportTab | null>(null);
    const handleCreateClick = React.useCallback(() => {
        setDraftTab({
            'heading': '',
            'reports': [],
        });
    }, []);

    const handleCreate = React.useCallback(async (payload: ReportTab) => {
        await createTab(payload);
        setDraftTab(null);
        setTabsState((tabsState) => [
            ...tabsState,
            payload,
        ])
        await refreshTabs();
    }, [createTab, refreshTabs]);

    const handleUpdate = React.useCallback(async (idx: number, payload: ReportTab) => {
        await updateTab(idx, payload);
        await refreshTabs();
    }, [updateTab, refreshTabs]);

    const handleRemove = React.useCallback(async (idx: number) => {
        await removeTab(idx);
        await refreshTabs();
    }, [removeTab, refreshTabs]);

    const [sortableList, setSortableList] = React.useState(
        tabsState.map((tab, idx) => ({
            ...tab,
            id: idx,
        }))
    );
    React.useEffect(() => {
        setSortableList(
            tabsState.map((tab, idx) => ({
                ...tab,
                id: idx,
            }))
        );
    }, [tabsState]);

    const handleUpdateList = React.useCallback(async (event: any) => {
        const ids = sortableList.map((item: any) => item.id);
        ids.splice(event.oldIndex, 1);
        ids.splice(event.newIndex, 0, event.oldIndex);

        await orderTabs(ids);
        await refreshTabs();
    }, [orderTabs, sortableList, refreshTabs]);

    return (
        <Modal
            heading={translate('Manage reports')}
            isOpen={true}
            onClose={handleClose}
            size="small"
            corner={
                <Button
                    className="flex text-blue-600 items-center"
                    color="white"
                    onClick={handleCreateClick}
                >
                    <Icon name="add" size={5} className="mr-2" />
                    {translate('Create new')}
                </Button>
            }
            compact={false}
        >
            {tabsState.length > 0 && (
                <ReactSortable
                    tag="ul"
                    list={sortableList}
                    setList={setSortableList}
                    onUpdate={handleUpdateList}
                    handle=".drag-handle"
                >
                    {sortableList.map((sortableListItem: any, idx: number) => (
                        <li key={`${sortableListItem.heading}_${idx}`}>
                            <TabCrud
                                idx={idx}
                                tab={sortableListItem}
                                onUpdate={handleUpdate}
                                onRemove={handleRemove}
                            />
                        </li>
                    ))}
                </ReactSortable>
            )}
            {draftTab && (
                <TabCrud
                    tab={draftTab}
                    onCreate={handleCreate}
                    mode="create"
                />
            )}
            <div className="mt-16 flex justify-end">
                <Button color="blue" onClick={handleClose}>
                    {translate('Done')}
                </Button>
            </div>
        </Modal>
    );
}

const TabCrud: React.FC<{
    tab: any
    idx?: number
    onUpdate?: (idx: number, payload: ReportTab) => void
    onRemove?: (idx: number) => void
    onCreate?: (payload: ReportTab) => void
    mode?: string
}> = ({
    tab,
    idx,
    onUpdate,
    onRemove,
    onCreate,
    mode = 'edit',
}) => {
    // const {success} = useToasts();
    const {translate} = React.useContext(TranslationContext);

    const handleUpdate = (payload: ReportTab) => {
        if (onUpdate && typeof idx === 'number') {
            onUpdate(idx, payload);
        }
    };
    const handleRemove = () => {
        if (onRemove && typeof idx === 'number') {
            onRemove(idx);
        }
    };
    const handleCreate = (payload: ReportTab) => {
        if (onCreate) {
            onCreate(payload);
        }
    };

    return (
        <div
            className="flex items-center text-gray-700"
        >
            <div className="drag-handle cursor-pointer mr-6">
                <Icon name="reorder" size={6} className="text-gray-500" />
            </div>
            <div className="flex-auto">
                <Heading tab={tab} mode={mode} handleCreate={handleCreate} handleUpdate={handleUpdate}/>
            </div>
            <div className="flex w-6">
                {
                    mode === 'edit' && (
                        <Confirmation
                            onConfirm={handleRemove}
                            trigger={({onClick}) => (
                                <div onClick={onClick} title={translate('Delete tab')}>
                                    <Icon name="delete" size={6} className="text-gray-500 cursor-pointer" />
                                </div>
                            )}
                            heading={translate('Remove report tab')}
                            buttonText={translate('Yes, remove')}
                            color="red"
                        >
                            {translate('Are you sure you want to remove this tab, with all included reports? This action can\'t be undone.')}
                        </Confirmation>
                    )
                }
            </div>
        </div>
    );
};

export default TabsOverlay;

const input = {
    className: "leading-tighter",
};

const Heading: React.FC<{
    tab: ReportTab,
    handleCreate: (payload: ReportTab) => void,
    handleUpdate: (payload: ReportTab) => void,
    mode: string,
}> = ({tab, handleCreate, handleUpdate, mode}) => {
    const {heading} = tab;

    const handleHeadingInput = React.useCallback(async (updatedHeading) => {
        const handleInput = mode === 'create' ? handleCreate : handleUpdate;
        await handleInput({
            ...tab,
            heading: updatedHeading
        })
    }, [tab, handleCreate, handleUpdate, mode]);

    const [editing, setEditing] = React.useState<boolean>(mode === 'create');
    const [headingModel, setHeadingModel] = React.useState<string>(heading);

    const handleClick = () => {
        setEditing(true);
    };

    const handleBlur = async () => {
        await handleHeadingInput(headingModel);
        setEditing(false);
    };

    return (
        editing ? (
            <TextInput
                autoFocus
                input={input}
                name="title"
                onBlur={handleBlur}
                onChange={setHeadingModel}
                value={headingModel}
            />
        ) : <div className="border-b border-gray-200 leading-tighter py-5" onClick={handleClick}>{headingModel}</div>
    )
};
