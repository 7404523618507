import React, {useState} from 'react';
import {Field} from '../../components/Field';
import {Switch} from '../../components/Switch';
import {Checkbox} from '../../components/Checkbox';
import {Radio} from '../../components/Radio';
import {TextInput} from '../../components/TextInput';
import {Section, Variation} from './DesignSystem';
import {TextArea} from '../../components/TextArea';

export function Forms() {
    const [toggleOne, setToggleOne] = useState(true);
    const [toggleTwo, setToggleTwo] = useState(false);
    const [radioBasic, setRadioBasic] = useState('foo');
    const [checkboxOne, setCheckboxOne] = useState(['one']);
    const [basicTextArea, setBasicTextArea] = useState('');

    return (
        <>
            <Section title="Field">
                <Variation hint="Field with static text">
                    <Field name="static-field" label="Username">
                        <p className="py-4">phpftw</p>
                    </Field>
                </Variation>

                <Variation hint="Field with input">
                    <Field name="username" label="Username">
                        <TextInput name="username" placeholder="Enter your name"></TextInput>
                    </Field>
                </Variation>

                <Variation hint="Field with error">
                    <Field
                        name="email"
                        label="E-mail"
                        errorMessage="This email address is already taken"
                    >
                        <TextInput
                            name="email"
                            type="email"
                            defaultValue="invalid@email"
                            hasError={true}
                        ></TextInput>
                    </Field>
                </Variation>
            </Section>
            <Section title="TextInput">
                <Variation hint="Default text input">
                    <TextInput name="default-input"></TextInput>
                </Variation>

                <Variation hint="Input with placeholder">
                    <TextInput name="name" placeholder="Enter your name"></TextInput>
                </Variation>

                <Variation hint="Password field placeholder">
                    <TextInput name="password-input" type="password"></TextInput>
                </Variation>

                <Variation hint="Input with autoComplete=name">
                    <TextInput name="some_name" autoComplete="name"></TextInput>
                </Variation>

                <Variation hint="Input with an icon">
                    <TextInput name="some_name" icon="lock"></TextInput>
                </Variation>
            </Section>

            <Section title="TextArea">
                <Variation hint="Default text area">
                    <div className="w-72">
                        <TextArea
                            name="default-text-area"
                            value={basicTextArea}
                            onChange={setBasicTextArea}
                            placeholder="Fill me in if you can!"
                            rows={4}
                        />
                    </div>
                </Variation>
            </Section>

            <Section title="Switch">
                <Variation hint="Switch with a label">
                    <Switch name="switch_notifications" value={true} onChange={() => {}}>
                        Notifications
                    </Switch>
                </Variation>

                <Variation hint="Switch ON">
                    <Switch name="switch_on" value={toggleOne} onChange={setToggleOne}></Switch>
                </Variation>

                <Variation hint="Switch OFF">
                    <Switch name="switch_off" value={toggleTwo} onChange={setToggleTwo}></Switch>
                </Variation>
            </Section>

            <Section title="Radio buttons">
                <Variation hint="Basic radio group">
                    <Radio
                        name="radio_basic"
                        value="foo"
                        selectedValue={radioBasic}
                        onChange={setRadioBasic}
                    >
                        Binary 0
                    </Radio>

                    <Radio
                        name="radio_basic"
                        value="bar"
                        selectedValue={radioBasic}
                        onChange={setRadioBasic}
                    >
                        Binary 1
                    </Radio>
                </Variation>
            </Section>

            <Section title="Checkbox">
                <Variation hint="Basic checkbox group">
                    <Checkbox
                        name="checkbox_one"
                        value="one"
                        selectedValues={checkboxOne}
                        onChange={setCheckboxOne}
                    >
                        Option one
                    </Checkbox>

                    <Checkbox
                        name="checkbox_one"
                        value="two"
                        selectedValues={checkboxOne}
                        onChange={setCheckboxOne}
                    >
                        Option two
                    </Checkbox>
                </Variation>
            </Section>
        </>
    );
}
