import React, {FC, ReactNode} from 'react';
import ButterToast, {POS_BOTTOM, POS_CENTER} from 'butter-toast/dist/lean.min.js';
import {Icon, Icons} from './Icon';

type Style = 'success' | 'warning' | 'error' | 'info';

export function useToasts() {
    return {
        success: (message: ReactNode) => toast(message, 'success'),
        warning: (message: ReactNode) => toast(message, 'warning'),
        error: (message: ReactNode) => toast(message, 'error'),
        info: (message: ReactNode) => toast(message, 'info'),
    };
}

function toast(message: ReactNode, style: Style): void {
    ButterToast.raise({
        content: ({dismiss}: any) => (
            <Toast styleColor={style} onClose={dismiss}>
                {message}
            </Toast>
        ),
    });
}

export const ToastContainer: FC = () => {
    return <ButterToast spacing={15} position={{vertical: POS_BOTTOM, horizontal: POS_CENTER}} />;
};

export const Toast: FC<{onClose?: () => void; styleColor: Style}> = ({onClose, styleColor, children}) => {
    const {name, color, background} = iconStyle(styleColor);

    let border = '';
    if( styleColor === 'error') {
        border = 'border-red-500 border'
    }
    return (
        <div className={`w-160 bg-white rounded-xl p-5 flex items-center justify-between shadow-lg select-none ${border}`}>
            <div className="flex items-center">
                <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center ${background}`}
                >
                    <Icon name={name} size={5} className={`${color}`} />
                </div>

                <span className="mx-5 text-gray-700">{children}</span>
            </div>

            {onClose && <button onClick={onClose} className="cursor-pointer focus:outline-none">
                <Icon name="close" size={6} className="text-gray-600" />
            </button>}
        </div>
    );
};

function iconStyle(theme: Style): {name: Icons; color: string; background: string} {
    switch (theme) {
        case 'success':
            return {
                name: 'done',
                color: 'text-teal-700',
                background: 'bg-teal-100',
            };

        case 'warning':
            return {
                name: 'error',
                color: 'text-orange-600',
                background: 'bg-orange-100',
            };

        case 'error':
            return {
                name: 'close',
                color: 'text-red-700',
                background: 'bg-red-100',
            };
        case 'info':
            return {
                name: 'visibility',
                color: 'text-white',
                background: 'bg-blue-400',
            };
    }
}
