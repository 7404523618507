import React from 'react';
import {Section, Variation} from './DesignSystem';
import {Button} from '../../components/Button';
import {useToasts} from '../../components/Toast';

export function Toasts() {
    const {success, warning, error} = useToasts();

    return (
        <>
            <Section title="Toast styles">
                <Variation>
                    <Button onClick={() => success('What a wonderful world!')} color="blue">
                        Show a success toast
                    </Button>
                </Variation>

                <Variation>
                    <Button onClick={() => warning('Danger, Will Robinson!')} color="yellow">
                        Show a warning toast
                    </Button>
                </Variation>

                <Variation>
                    <Button onClick={() => error('Something went wrong!')} color="red">
                        Show an error toast
                    </Button>
                </Variation>
            </Section>
        </>
    );
}
