import {
    GetPropsCommonOptions,
    useSelect,
    UseSelectGetToggleButtonPropsOptions,
    UseSelectState,
    UseSelectStateChangeOptions,
} from 'downshift';
import React, {FC, ReactElement, ReactNode, useState} from 'react';
import {createPortal} from 'react-dom';
import {Manager, Popper, Reference} from 'react-popper';
import {Placement} from 'popper.js';
import {Option} from 'components/Dropdown';
import {Icon} from 'components/Icon';
import Search from 'components/Search';

export function MultiselectDropdown<T = string | number | boolean>({
    name,
    values = [],
    options,
    placeholder,
    onChange,
    dropdownPlacement = 'bottom-start',
    usePortal = true,
    useSearch = false,
    searchPlaceholder = 'Search...',
    hasError = false,
    shownItemSize = 2,
    renderInput = DefaultRenderMultiselect,
}: {
    name: string;
    values: T[];
    options: Option<T>[];
    placeholder: string;
    onChange: (result: Option<T>[]) => void;
    dropdownPlacement?: Placement;
    usePortal?: boolean;
    useSearch?: boolean;
    searchPlaceholder?: string;
    hasError?: boolean;
    shownItemSize?: number;
    renderInput?: FC<RenderMultiselectProps<T>>;
}): ReactElement {
    const [phrase, setPhrase] = useState<string>('');

    const findSelected = (value: T[]) => {
        let selectedItems: Option<T>[] = [];
        value.forEach((val) => {
            const find = options.find((option) => option.value === val);
            if (find) {
                if (selectedItems) {
                    selectedItems = [...selectedItems, find];
                } else {
                    selectedItems = [find];
                }
            }
        });
        return selectedItems;
    };

    const sortOptions = (values: T[]) => {
        let difference: Option<T>[] = [];
        const selectedItems = findSelected(values);

        options.forEach((opt) => {
            let selected = false;

            selectedItems.forEach((item) => {
                if (item.value === opt.value) {
                    selected = true;
                }
            });

            if (!selected) {
                difference = [...difference, opt];
            }
        });

        return [...selectedItems, ...difference];
    };

    function stateReducer(
        state: UseSelectState<Option<T>>,
        actionAndChanges: UseSelectStateChangeOptions<Option<T>>,
    ) {
        const {changes, type} = actionAndChanges;
        switch (type) {
            case useSelect.stateChangeTypes.MenuKeyDownEnter:
            case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
            case useSelect.stateChangeTypes.ItemClick:
                return {
                    ...changes,
                    isOpen: true,
                    highlightedIndex: state.highlightedIndex,
                };
            default:
                return changes;
        }
    }

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: sortOptions(values),
        stateReducer,
        selectedItem: null,
        onSelectedItemChange: ({selectedItem}) => {
            if (!selectedItem) {
                return;
            }

            const selectedItems = findSelected(values);
            if (selectedItems.some((item) => item.value === selectedItem.value)) {
                onChange([...selectedItems.filter((item) => item.value !== selectedItem.value)]);
            } else {
                onChange([...selectedItems, selectedItem]);
            }
        },
    });

    function render(content: ReactNode): ReactNode {
        return usePortal ? createPortal(content, document.body) : content;
    }

    const selectedItems = findSelected(values);
    return (
        <Manager>
            <div id={name}>
                <Reference>
                    {({ref}) => (
                        <div ref={ref}>
                            {renderInput({
                                name,
                                ref,
                                selectedItems,
                                hasError,
                                isOpen,
                                shownItemSize,
                                placeholder,
                                getToggleButtonProps,
                            })}
                        </div>
                    )}
                </Reference>

                {render(
                    <div {...getMenuProps()}>
                        {isOpen && (
                            <Popper
                                modifiers={{
                                    hide: {enabled: false},
                                    preventOverflow: {enabled: false},
                                }}
                                placement={dropdownPlacement}
                            >
                                {({ref, style, placement}) => (
                                    <div
                                        ref={ref}
                                        style={style}
                                        data-placement={placement}
                                        className="bg-white rounded-lg shadow-lg py-2 z-10"
                                    >
                                        <ul className="max-h-66 overflow-auto px-2">
                                            {useSearch && (
                                                <li className="flex items-center px-6 py-5 leading-tighter rounded cursor-pointer">
                                                    <Search
                                                        placeholder={searchPlaceholder}
                                                        value={phrase}
                                                        onChange={(phrase) => {
                                                            setPhrase(phrase);
                                                        }}
                                                    ></Search>
                                                </li>
                                            )}

                                            {sortOptions(values).map((item, index) => {
                                                const highlighted = highlightedIndex === index;
                                                return (
                                                    String(item.label.toLocaleLowerCase()).includes(
                                                        phrase.toLocaleLowerCase(),
                                                    ) && (
                                                        <li
                                                            {...getItemProps({
                                                                key: String(item.value),
                                                                item,
                                                                index,
                                                            })}
                                                            className={`flex items-center px-6 py-3 leading-tighter rounded cursor-pointer ${
                                                                highlighted ? 'bg-gray-100' : ''
                                                            }`}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedItems.some(
                                                                    (selected) =>
                                                                        selected.value ===
                                                                        item.value,
                                                                )}
                                                                value={String(item.value)}
                                                                onChange={() => {}}
                                                                className="h-6 w-6 mr-4"
                                                            />
                                                            <div>{item.label}</div>
                                                        </li>
                                                    )
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </Popper>
                        )}
                    </div>,
                )}
            </div>
        </Manager>
    );
}

export const SelectedItems: FC<{
    selectedItems: any[];
    shownSize: number;
}> = ({selectedItems, shownSize}) => {
    const shownItems = selectedItems
        .slice(0, shownSize)
        .reduce(
            (acc, item: Option<string | number | boolean>, index) =>
                index === 0 ? acc + item.label : acc + ', ' + item.label,
            '',
        );

    const hiddenItemsSize = selectedItems.slice(shownSize).length;

    return (
        <div className="flex">
            {shownItems}
            {hiddenItemsSize > 0 && (
                <div className="bg-blue-700 ml-2 border rounded-lg border-blue-700 px-2 text-white leading-tighter">
                    +{hiddenItemsSize} items
                </div>
            )}
        </div>
    );
};

export interface RenderMultiselectProps<T> {
    name: string;
    selectedItems: Option<T>[];
    ref: React.Ref<any>;
    placeholder: string | undefined;
    hasError: boolean;
    isOpen: boolean;
    getToggleButtonProps: (
        options?: UseSelectGetToggleButtonPropsOptions | undefined,
        otherOptions?: GetPropsCommonOptions | undefined,
    ) => any;
    shownItemSize: number;
}

function DefaultRenderMultiselect<T>({
    name,
    selectedItems,
    hasError,
    placeholder,
    isOpen,
    getToggleButtonProps,
    shownItemSize,
}: RenderMultiselectProps<T>) {
    return (
        <div
            className={`multiselect-${name} h-16 py-5 flex items-center justify-between text-gray-700 border-b cursor-pointer ${
                selectedItems.length && 'text-blue-500'
            } ${hasError && 'border-red-700'} ${
                isOpen && 'border-blue-700'
            } focus:outline-none focus:border-blue-700`}
            {...getToggleButtonProps()}
        >
            <div className="mr-3">
                {!isOpen && selectedItems.length ? (
                    <SelectedItems selectedItems={selectedItems} shownSize={shownItemSize} />
                ) : (
                    placeholder
                )}
            </div>
            <Icon
                name="triangle"
                className={`inline-block align-middle text-gray-600 ${isOpen ? '' : 'rotate-180'}`}
                size={2}
            ></Icon>
        </div>
    );
}
