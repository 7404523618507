import React, {useContext, useState, useEffect} from 'react';
import {FieldTemplate, Project, ProjectStatistics} from '../../../../../_common/schema';
import {useHistory} from 'react-router-dom';
import {AppContext, TranslationContext} from 'contexts';
import {DummyList} from '../../../components/DummyList';
import {http} from '../../../utils/http';
import {Icon} from '../../../components/Icon';
import {Avatar} from '../../../components/Avatar';
import {ProjectStateIcon} from '../ProjectStateIcon';

export function ProjectsList({
    projects,
    onRefresh,
    loaded,
    onFavorite,
}: {
    projects: Project[];
    onRefresh: () => void;
    loaded: boolean;
    onFavorite?: (added: boolean) => void;
}) {
    const history = useHistory();
    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);
    const [isFavorite, setIsFavorite] = useState<boolean[]>([]);

    useEffect(() => {
        setIsFavorite(
            projects.map((project) => {
                return project.is_favorite;
            }),
        );
    }, [projects]);

    const gridColumns = config.fields.filter((field: FieldTemplate) => {
        return field.grid;
    });

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th className="w-12"></th>
                        {config.config.has_categories && <th className="p-0 m-0">Cat.</th>}
                        <th>{translate('Name')}</th>
                        <th>{translate('Assignee')}</th>
                        <th>{translate('State')}</th>
                        <th>{translate('Phase')}</th>
                        <th>{translate('Age')}</th>
                        <th>TAT</th>
                        {gridColumns.map((field: FieldTemplate, index: number) => {
                            return <th key={`grid-label-${index}`}>{field.label}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <DummyList rowsCount={10} colsCount={7} />
                    ) : projects.length === 0 ? (
                        <tr>
                            <td colSpan={8} className="text-center">
                                {translate('No projects found')}
                            </td>
                        </tr>
                    ) : (
                        projects.map((project, index) => {
                            return (
                                <tr
                                    key={project.id}
                                    className="hover:bg-gray-000 cursor-pointer rounded"
                                    onClick={(event) => {
                                        if (event.ctrlKey) {
                                            window.open(`/#/projects/${project.id}`, '_blank');
                                            return;
                                        }
                                        history.push(`/projects/${project.id}`);
                                    }}
                                >
                                    <td
                                        data-cy-favorite={isFavorite[index]}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            let newFavorites = [...isFavorite];
                                            newFavorites[index] = !isFavorite[index];
                                            setIsFavorite(newFavorites);
                                            http.put(`/api/projects/${project.id}/favorite`).then(
                                                () => {
                                                    onFavorite && onFavorite(!project.is_favorite);
                                                    onRefresh();
                                                },
                                            );
                                        }}
                                    >
                                        <Icon
                                            name={isFavorite[index] ? 'grade' : 'grade_outline'}
                                            size={6}
                                            className={
                                                isFavorite[index]
                                                    ? 'text-yellow-600 cursor-pointer'
                                                    : 'text-gray-600 cursor-pointer'
                                            }
                                        ></Icon>
                                    </td>
                                    {config.config.has_categories && (
                                        <td className="p-0 m-0">
                                            {project.category?.icon ? (
                                                <div
                                                    style={{
                                                        background:
                                                            project.category.icon.background,
                                                        color: project.category.icon.foreground,
                                                    }}
                                                    className="p-2 bg-gray-100 rounded-full h-10 w-10"
                                                >
                                                    <Icon
                                                        name={project.category.icon.name}
                                                        size={6}
                                                    ></Icon>
                                                </div>
                                            ) : (
                                                <div className="p-2 bg-gray-100 rounded-full h-10 w-10">
                                                    <Icon
                                                        name="category"
                                                        size={6}
                                                        className="text-gray-600"
                                                    ></Icon>
                                                </div>
                                            )}
                                        </td>
                                    )}
                                    <td>{project.title}</td>
                                    <td>
                                        {project.team.assignee ? (
                                            <div className="flex">
                                                <Avatar
                                                    user={project.team.assignee}
                                                    className="w-10 h-10 mr-4"
                                                />
                                                <div
                                                    className="self-center"
                                                    title={project.team.assignee?.name}
                                                >
                                                    {project.team.assignee?.name.split(' ')[0]}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="text-gray-600 ml-2">-</div>
                                        )}
                                    </td>
                                    <td>
                                        <div className="flex">
                                            <ProjectStateIcon state={project.state} />
                                            <div
                                                // style={{
                                                //     color: project.state.extra.icon?.foreground,
                                                //{}}*/}
                                                className={'ml-4 self-center'}
                                            >
                                                {project.state.label}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <ProjectStatistic
                                            statistics={project.statistics}
                                        ></ProjectStatistic>
                                    </td>
                                    <td>{project.extra['aging_human']}</td>
                                    <td>{project.extra['tat_human']}</td>
                                    {gridColumns.map((field: FieldTemplate, index: number) => {
                                        return (
                                            <td key={`grid-value-${index}`}>
                                                {project.fields_human[field.name]}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </>
    );
}

const ProjectStatistic = ({statistics}: {statistics: Array<ProjectStatistics>}) => {
    return (
        <div className="flex">
            {statistics.map((statistics, index) => (
                <div
                    key={index}
                    title={statistics.title}
                    className={`mr-1 w-4 h-2 ${
                        statistics.type !== 'next' ? 'bg-blue-500' : 'bg-gray-200'
                    } rounded-full`}
                ></div>
            ))}
        </div>
    );
};
