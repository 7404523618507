import {Filter, Project, User} from '@contractool/schema';
import {omit} from 'lodash';
import * as React from 'react';
import {Switch, Route} from 'react-router';
import {Link, useHistory, useLocation} from 'react-router-dom';

import {GuardContext, TranslationContext} from 'contexts';

import {Icon} from 'components/Icon';
import {Page} from 'components/Page';
import {Pagination} from 'components/Pagination';
import {Tabs, Tab} from 'components/Tabs';
import {useMemorizedQuery} from 'features/projects';
import {useRequest} from 'utils/hooks/useRequest';
import {useQueryParams} from 'utils/hooks/useParams';
import {EventEmitter} from 'utils/eventEmitter';
import {Guest} from 'views/Guest';

import {FilterNew} from './FilterNew';
import {Categories} from './Categories';
import {ProjectsList} from './Grid/ProjectsList';
import {ProjectsFilters} from './Grid/ProjectsFilters';

export interface SearchParams {
    phrase: string;
    page: number;
    team: {assignee: number[]};
    categories: number[];
    states: string[];
    fields: {country: string[]};
    suppliers: number[];
    filter_id: number | null;
    favorite: number;
}

export function ProjectsPage() {
    const {user} = React.useContext(GuardContext);
    const location = useLocation();

    const [
        params,
        {setParam, setParams, saveParams, setSavedParams},
    ] = useQueryParams<SearchParams>(
        {
            phrase: '',
            page: 1,
            team: {assignee: location.search === '' ? [user.id] : []},
            categories: [],
            states: [],
            fields: {country: []},
            suppliers: [],
            filter_id: null,
            favorite: 1,
        },
        '/projects',
    );
    const [selectedTabName, setSelectedTabName] = React.useState<string>(
        (params.filter_id && params.filter_id.toString()) || 'assignedToYou',
    );
    const [temporaryFilter, setTemporaryFilter] = React.useState<Filter | undefined>();
    const isQueryLoaded = useMemorizedQuery();
    const [isQueryParsed, setQueryAsParsed] = React.useState(false);
    React.useEffect(() => {
        if (isQueryLoaded && !isQueryParsed) {
            const shouldBeSelected = params.filter_id
                ? (
                    temporaryFilter?.id.toString() === params.filter_id.toString()
                        ? 'temp'
                        : params.filter_id.toString()
                )
                : 'assignedToYou';
            if (selectedTabName !== shouldBeSelected) {
                setSelectedTabName(shouldBeSelected);
            }
            setQueryAsParsed(true);
        }
    }, [isQueryLoaded, isQueryParsed, params, selectedTabName, temporaryFilter]);

    const [loaded, setLoaded] = React.useState(false);
    const [projects, {pagination, refresh: refreshProjects}] = useRequest<
        Project[],
        {total: number}
    >('/api/projects', [], {params}, setLoaded);

    const filtersOmittedParams =
        selectedTabName === 'assignedToYou'
            ? {
                  ...omit(
                      {
                          ...params,
                          team: {
                              assignee: [
                                  ...params.team?.assignee.filter((v) => Number(v) !== user.id),
                              ],
                          },
                      },
                      ['page', 'filter_id', 'favorite'],
                  ),
              }
            : {...omit({...params}, ['page', 'filter_id', 'favorite'])};

    const [filters, {refresh: refreshFilters}] = useRequest<Filter[]>('/api/project-filters', [], {
        params: filtersOmittedParams,
    });

    const assignedParams = omit(
        {
            ...params,
            team: {
                assignee: [user.id, ...params.team.assignee],
            },
            favorite: 1,
        },
        ['page', 'filter_id'],
    );

    const [, {meta: assignedMeta}] = useRequest<Project[], {total: number}>('/api/projects', [], {
        params: assignedParams,
    });

    const updateTabs = (name: string) => {
        if (name !== selectedTabName) {
            setLoaded(false);
        }
        setSelectedTabName(name);
        switch (name) {
            case 'assignedToYou': {
                setParams({
                    team: {assignee: user?.id ? [user.id] : []},
                    filter_id: null,
                    favorite: 1,
                    page: 1,
                });
                break;
            }
            case 'active': {
                setParams({team: {assignee: []}, filter_id: null, favorite: 0, page: 1});
                break;
            }
            case 'temp': {
                setParams({
                    team: {assignee: []},
                    filter_id: temporaryFilter?.id,
                    favorite: 0,
                    page: 1,
                });
                break;
            }
            default: {
                setParams({team: {assignee: []}, filter_id: Number(name), favorite: 0, page: 1});
            }
        }
    };

    const getTempFilterProjectCount = (temporaryFilter: Filter, selected: boolean) => {
        return selected ? pagination.totalRecords : temporaryFilter.projects_count;
    };

    const history = useHistory();
    const {translate} = React.useContext(TranslationContext);
    let isGuest = user.groups.filter((g) => g.key === 'guest').length > 0;

    return isGuest ? <Guest /> : (
        <Page heading={translate('Projects')}>
            <ProjectsFilters
                params={params}
                selected={selectedTabName}
                setParams={setParams}
                setParam={setParam}
            />

            <div className="-mx-6 relative">
                <Tabs onSelect={updateTabs} selected={selectedTabName}>
                    <Tab
                        name="assignedToYou"
                        heading={
                            <div>
                                {translate('Yours')}
                                {assignedMeta && (
                                    <span
                                        className={`${
                                            selectedTabName === 'assignedToYou' ? 'bg-blue-100' : 'bg-gray-000'
                                        } text-sm rounded-full px-2 py-1 ml-2`}
                                    >
                                        {selectedTabName === 'assignedToYou' && loaded
                                            ? pagination.totalRecords
                                            : assignedMeta.total}
                                    </span>
                                )}
                            </div>
                        }
                        className="py-8"
                    >
                        {params ? (
                            <SearchResults phrase={params.phrase} projectLenght={projects.length} />
                        ) : null}
                        <ProjectsList
                            projects={projects}
                            onRefresh={refreshProjects}
                            loaded={loaded}
                        />
                    </Tab>
                    {temporaryFilter ? (
                        <Tab
                            name="temp"
                            heading={
                                <div>
                                    {translate('Applied filter')}
                                    <span
                                        className={`${
                                            selectedTabName === 'temp' ? 'bg-blue-100' : 'bg-gray-000'
                                        } text-sm rounded-full px-2 py-1 ml-2`}
                                    >
                                        {getTempFilterProjectCount(
                                            temporaryFilter,
                                            selectedTabName === 'temp',
                                        )}
                                    </span>
                                </div>
                            }
                            className="py-8"
                        >
                            {params ? (
                                <SearchResults
                                    phrase={params.phrase}
                                    projectLenght={projects.length}
                                />
                            ) : null}
                            <ProjectsList
                                projects={projects}
                                onRefresh={refreshProjects}
                                loaded={loaded}
                            />
                        </Tab>
                    ) : (
                        []
                    )}

                    {filters
                        .filter((filter: Filter) => filter.is_visible)
                        .map((filter: Filter, index) => {
                            if (index < 4)
                                return (
                                    <Tab
                                        key={filter.id}
                                        name={filter.id.toString()}
                                        heading={
                                            <div>
                                                {filter.title}
                                                <span
                                                    className={`${
                                                        selectedTabName === filter.id.toString()
                                                            ? 'bg-blue-100'
                                                            : 'bg-gray-000'
                                                    } text-sm rounded-full px-2 py-1 ml-2`}
                                                >
                                                    {selectedTabName === filter.id.toString() && loaded
                                                        ? pagination.totalRecords
                                                        : filter.projects_count}
                                                </span>
                                            </div>
                                        }
                                        className="py-8"
                                    >
                                        {params ? (
                                            <SearchResults
                                                phrase={params.phrase}
                                                projectLenght={projects.length}
                                            />
                                        ) : null}
                                        <ProjectsList
                                            projects={projects}
                                            onRefresh={refreshProjects}
                                            loaded={loaded}
                                        />
                                    </Tab>
                                );
                            return [];
                        })}
                </Tabs>
                <div
                    title={translate('Manage categories')}
                    className="absolute top-0 right-0 mt-5 text-gray-500 cursor-pointer"
                >
                    <Link to="/projects/categories">
                        <Icon name="settings" size={5} />
                    </Link>
                </div>
            </div>

            <div className="flex justify-center pt-16">
                <Pagination
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    totalBlocks={7}
                    onSelect={(page) => {
                        setParam('page', page);
                    }}
                />
            </div>

            <Switch>
                <Route path="/projects/filters/new">
                    <FilterNew
                        onSubmit={(filter: Filter) => {
                            if (filter.title === 'temp') {
                                setTemporaryFilter(filter);
                                setSelectedTabName('temp');
                            } else {
                                setSelectedTabName(filter.id.toString());
                            }
                            refreshFilters();
                            refreshProjects();
                            setParams({team: {assignee: []}, filter_id: filter.id, favorite: 0});
                        }}
                        heading={translate('Advanced filters')}
                        temporary={true}
                    />
                </Route>
                <Route path="/projects/categories">
                    <Categories
                        filters={filters}
                        onRefresh={(id) => {
                            if (selectedTabName === id?.toString()) {
                                setSelectedTabName('assignedToYou');
                                saveParams({
                                    team: {assignee: user?.id ? [user.id] : []},
                                    filter_id: null,
                                    favorite: 1,
                                });
                            }
                            refreshFilters();
                            refreshProjects();
                        }}
                        onClose={() => {
                            setSavedParams();
                        }}
                    >
                        <FilterNew
                            onSubmit={(filter: Filter) => {
                                refreshFilters();
                                setSelectedTabName(filter.id.toString());
                                saveParams({
                                    team: {assignee: []},
                                    filter_id: filter.id,
                                    favorite: 0,
                                });
                                history.goBack();
                            }}
                            heading={translate('Create new category')}
                        />
                    </Categories>
                </Route>
            </Switch>
        </Page>
    );
}

const SearchResults: React.FC<{
    phrase: string;
    projectLenght: number;
    user?: User;
}> = ({phrase, projectLenght, user}) => {
    const [filters, setFilters] = React.useState<{
        states: string[];
        assignee: string[];
        suppliers: string[];
        categories: string[];
    }>({assignee: user ? [user.name] : [], suppliers: [], categories: [], states: []});

    const transformParams = (filters: {
        states: string[];
        assignee: string[];
        suppliers: string[];
        categories: string[];
    }) => {
        if (phrase) {
            const allParams = [
                ...filters.states,
                ...filters.assignee,
                ...filters.suppliers,
                ...filters.categories,
            ];

            return allParams.reduce((acc, val, index) => {
                return index !== 0 ? `${acc}, ${val}` : `${val}`;
            }, '');
        }

        return '';
    };

    EventEmitter.subscribe('search-filter', (data: any) => {
        let newData = {...data};
        if (data.assignee && user) {
            newData = {assignee: [filters.assignee[0], ...data.assignee]};
        }

        setFilters({...filters, ...newData});
    });

    const filtersString = transformParams(filters);

    return phrase.length ? (
        <div className="text-gray-500 mb-2 px-5">
            {projectLenght} {projectLenght === 1 ? 'Result' : 'Results'} for{' '}
            <span className="text-gray-700">{phrase}</span>
            {filtersString ? ' in ' : ''}
            {filtersString ? <span className="text-gray-700">{filtersString}</span> : ''}
        </div>
    ) : null;
};
