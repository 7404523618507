import * as React from 'react';

import {FormContext} from 'components/Form';

const fieldDependsOnOtherThan = (Component: React.ComponentType<any>) =>
    (props: any) => {
        const {field} = props;
        const formContext = React.useContext(FormContext);
        console.log('formContext.values '+field.name, formContext.values, field);

        if (
            field.showOnlyIf &&
            !field.showOnlyIf.value.includes(''+formContext.values[field.showOnlyIf.propertyName])
        ) {
            return null;
        }

        return <Component {...props} />
    };

export default fieldDependsOnOtherThan;
