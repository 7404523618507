import React, {FC, useContext} from 'react';
import {Modal} from '../../components/Modal';
import {TranslationContext} from 'contexts';

const OldMilestonesModal: FC<{
    milestones: any;
    onClose: () => void;
}> = ({milestones, onClose}) => {
    const {translate} = useContext(TranslationContext);

    return (
        <Modal heading="Milestones" onClose={onClose} size="w-full" draggable>
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        <th style={{width: '3%'}}>#</th>
                        <th style={{width: '30%'}}>{translate('Dell Engineering activities')}</th>
                        <th style={{width: '7%'}}>{translate('Year')}</th>
                        <th style={{width: '8%'}}>{translate('Quarter')}</th>
                        <th style={{width: '30%'}}>{translate('Proof of performance')}</th>
                        <th>{translate('Payment amount')}</th>
                    </tr>
                </thead>
                <tbody className="border-gray-000 border-l-4 border-t-4">
                    {milestones &&
                        milestones.map((milestone: any, index: number) => {
                            console.log('rendering milestone', index, milestone);
                            if (milestone.deleted) {
                                return null;
                            }
                            const dellText = {
                                dangerouslySetInnerHTML: {__html: milestone.dell},
                            };
                            const proofText = {
                                dangerouslySetInnerHTML: {__html: milestone.proof},
                            };

                            return (
                                <tr
                                    key={`milestone${index}`}
                                    className="bg-white border-b-4 border-gray-000 hover:milestone-hover"
                                >
                                    <td className="px-4 py-2 rounded-l-lg border-r-4 border-gray-000">
                                        {index + 1}
                                    </td>
                                    <td className="px-4 py-2 text-sm border-r-4 border-gray-000">
                                        <div className="py-5" {...dellText} />
                                    </td>
                                    <td className="px-4 py-2 text-center border-r-4 border-gray-000">
                                        {milestone.year}
                                    </td>
                                    <td className="px-4 py-2 text-center border-r-4 border-gray-000">
                                        {milestone.quarter}
                                    </td>
                                    <td className="px-4 py-2 text-sm border-r-4 border-gray-000">
                                        <div className="py-5" {...proofText} />
                                    </td>
                                    <td className="px-4 py-2 whitespace-no-wrap rounded-r-lg w-9 border-r-4 border-gray-000">
                                        $<span className="py-5">{milestone.amount}</span>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </Modal>
    );
};

const EnhancedOldMilestonesModal = React.memo(OldMilestonesModal);

export default EnhancedOldMilestonesModal;
