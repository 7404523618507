import React from 'react';
import {FieldTemplate} from '@contractool/schema';
import {GuardContext} from 'contexts';
import ProjectContext from '../../../../../../../ProjectContext';
import {CustomField} from 'components';
import {isAllowedForUser} from 'utils/form';

const ProjectFieldList: React.FC<{fields: FieldTemplate[]}> = ({fields}) => {
    const {resource: project} = React.useContext(ProjectContext);
    const {user} = React.useContext(GuardContext);

    const projectFields = fields.filter((field: FieldTemplate) => {
        return isAllowedForUser(field, user) && field.workflow === project.workflow;
    });

    return (
        <>
            {projectFields.map((field: FieldTemplate) => (
                <CustomField
                    key={`${field.name}`}
                    field={field}
                    option=""
                    className="mt-6"
                />)
            )}
        </>
    )
};

const PureProjectFieldList = React.memo(ProjectFieldList);

export default PureProjectFieldList;
