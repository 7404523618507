import {User} from '@contractool/schema';
import {make} from './url';

export function isUserInGroup(user: User, group: string) {
    for(let userGroup of user.groups) {
        if(userGroup.key === group) {
            return true;
        }
    }
    return false;
}

export function sign(url: string): string {
    return make(url, {token: ''});//TODO
}
