import React, {FC, useContext} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {parse} from 'qs';
import {Project, ProjectCategory, ProjectType} from '@contractool/schema';
import {useToasts} from 'components/Toast';
import {Page} from 'components/Page';
import {Form} from 'components/Form';
import {http} from 'utils/http';
import {useRequest} from 'hooks/useRequest';
import {commodities} from 'utils/commodities';
import {AppContext, TranslationContext} from 'contexts';
import {CustomField} from 'components';
import {convertArrayToObject, getOptions, TeamRoleSelect} from 'utils/form';
import SupplierSelect from 'components/form/SupplierSelect';
import {Mutable as ProjectTitle} from 'components/form/ProjectTitle';

export function DellForm({search}: {search: string}) {
    const history = useHistory();

    const searchParams = parse(search.substring(1));
    const [initialProject] = useRequest<Project | undefined>(
        `api/projects/new${search}`,
        undefined,
    );
    let pageHeading = '';
    if ('workflow' in searchParams && searchParams['workflow'] === 'onboarding') {
        pageHeading = 'New Project Request';
    } else if ('fields' in searchParams && typeof searchParams['fields'] === 'object') {
        const fields = searchParams['fields'];
        if ('scope' in fields && 'advisory' in fields) {
            pageHeading = `New ${fields['scope']} ${
                fields['advisory'] === '0' ? 'Project Request' : 'Advisory Project'
            }`;
        } else {
            history.push('/projects/new');
        }
    } else {
        history.push('/projects/new');
    }

    return (
        <Page heading={pageHeading}>
            {initialProject && (
                <FormContainer
                    initialProject={initialProject}
                    onClose={() => history.push('/projects')}
                    path={history.location.search}
                />
            )}
        </Page>
    );
}

const FormContainer: FC<{
    onClose: () => void;
    initialProject: Project;
    path: string;
}> = ({onClose, initialProject, path}) => {
    const {success} = useToasts();
    const {translate} = useContext(TranslationContext);

    return (
        <Form
            initialValues={initialProject}
            onSuccess={() => {
                onClose();
                success(`${translate('Project was created')}.`);
            }}
            onSubmit={(values) => http.post<Project>('/api/projects', values)}
            guard={false}
        >
            <ProjectTitle className="mb-8" />
            {initialProject?.workflow !== 'onboarding' ? (
                <DefaultProjectForm />
            ) : (
                <OnboardingForm path={path} />
            )}
        </Form>
    );
};

const DefaultProjectForm: FC = () => {
    const [categories] = useRequest<ProjectCategory[]>(`/api/project-categories`, []);
    const [types] = useRequest<ProjectType[]>(`/api/project-types`, []);

    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);

    const fieldsObj = convertArrayToObject(config.fields, 'name');
    const rolesObj = convertArrayToObject(config.roles, 'key');

    const categoriesAsOptions = getOptions(categories);
    const typesAsOptions = getOptions(types);

    return (
        <div className="divide-y divide-gray-100">
            <div className="flex flex-wrap divide-x divide-gray-100">
                <div className="py-8 px-5 w-1/2">
                    <Form.Dropdown
                        name="category_id"
                        label={translate('Spend Category')}
                        options={categoriesAsOptions}
                        required={true}
                    />
                    <Form.Multiselect
                        name="types"
                        label={translate('Project Types')}
                        placeholder={translate('Project Types')}
                        options={typesAsOptions}
                        dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
                        className="mt-6"
                        autocomplete
                        changeKeys={{key1: 'id', key2: 'title'}}
                        required={true}
                    />
                    <CustomField
                        field={fieldsObj.region}
                        option="fields."
                        className="mt-6"
                        config={{
                            type: 'GENERATING',
                            target: 'title',
                            dependencies: [
                                'supplier_id',
                                'category_id',
                                'types',
                                'fields.region',
                                'fields.country',
                            ],
                        }}
                    />
                    <CustomField
                        field={fieldsObj.country}
                        option="fields."
                        className="mt-6"
                        config={{
                            type: 'GENERATING',
                            target: 'title',
                            dependencies: [
                                'supplier_id',
                                'category_id',
                                'types',
                                'fields.region',
                                'fields.country',
                            ],
                        }}
                    />
                    <CustomField field={fieldsObj.nda_no_date} option="fields." className="mt-6" />
                    <CustomField
                        field={fieldsObj.contract_amount}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.request_defects}
                        option="fields."
                        className="mt-6"
                    />
                </div>
                <div className="py-8 px-5 w-1/2">
                    <SupplierSelect />
                    <CustomField field={fieldsObj.supplier_poc} option="fields." className="mt-6" />
                    <CustomField
                        field={fieldsObj.supplier_email}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.supplier_address}
                        option="fields."
                        className="mt-6"
                    />
                </div>
            </div>
            <div className="flex flex-wrap divide-x divide-gray-100">
                <div className="py-8 px-5 w-1/2">
                    <CustomField field={fieldsObj.effective_at} option="fields." />
                    <CustomField field={fieldsObj.requested_at} option="fields." className="mt-6" />
                    <CustomField field={fieldsObj.effective_at} option="fields." className="mt-6" />
                </div>
                <div className="py-8 px-5 w-1/2">
                    <TeamRoleSelect role={rolesObj.vetter} />
                    <TeamRoleSelect role={rolesObj.contract_manager} className="mt-6" />
                    <TeamRoleSelect role={rolesObj.requestor} className="mt-6" />
                    <TeamRoleSelect role={rolesObj.global_category_manager} className="mt-6" />
                    <CustomField
                        field={fieldsObj.business_partner}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.business_partner_email}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.business_director}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.business_director_email}
                        option="fields."
                        className="mt-6"
                    />
                </div>
            </div>
            <div className="flex flex-wrap divide-x divide-gray-100">
                <div className="py-8 px-5 w-1/2">
                    <CustomField field={fieldsObj.request_id} option="fields." />
                    <CustomField field={fieldsObj.workspace_id} option="fields." className="mt-6" />
                </div>
                <div className="py-8 px-5 w-1/2">
                    <CustomField
                        field={fieldsObj.signatories_supplier}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.signatories_dell}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField field={fieldsObj.approvers} option="fields." className="mt-6" />
                </div>
            </div>
            <div className="flex flex-wrap divide-x divide-gray-100">
                <div className="py-8 px-5 w-1/2">
                    <CustomField field={fieldsObj.legacy_dell_emc} option="fields." />
                    <CustomField
                        field={fieldsObj.adobe_sign_direct}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.new_onboarding}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField field={fieldsObj.priority} option="fields." className="mt-6" />
                    <CustomField field={fieldsObj.hard_copies} option="fields." className="mt-6" />
                </div>
                <div className="py-8 px-5 w-1/2">
                    <CustomField field={fieldsObj.additional_contracts} option="fields." />
                    <CustomField
                        field={fieldsObj.solution_description}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField field={fieldsObj.note} option="fields." className="mt-6" />
                </div>
            </div>
        </div>
    );
};

const OnboardingForm: FC<{path: string}> = ({path}) => {
    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);

    const fieldsObj = convertArrayToObject(config.fields, 'name');
    const rolesObj = convertArrayToObject(config.roles, 'key');

    return (
        <div className="divide-y divide-gray-100">
            <div className="flex flex-wrap divide-x divide-gray-100">
                <div className="py-8 px-5 w-1/2">
                    <CustomField field={fieldsObj.onboarding__new_request} option="fields." />
                    <CustomField
                        field={fieldsObj.onboarding__sir_number}
                        option="fields."
                        className="mt-6"
                        config={{
                            type: 'GENERATING',
                            target: 'title',
                            dependencies: [
                                'fields.onboarding__sir_number',
                                'fields.onboarding__region',
                            ],
                        }}
                    />
                    <CustomField
                        field={fieldsObj.onboarding__onboarding_scenario}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.onboarding__spend_category_portfolio}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        config={{
                            type: 'DEPENDING',
                            dependencies: [
                                'fields.onboarding__spend_category_portfolio',
                                'fields.onboarding__new_request',
                            ],
                            alt: ['Software & Logistics', 'Indirect Procurement'],
                            options: commodities,
                        }}
                        field={fieldsObj.onboarding__commodity}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.onboarding__regional_onboarding_team}
                        option="fields."
                        className="mt-6"
                    />
                </div>
                <div className="py-8 px-5 w-1/2">
                    <SupplierSelect />
                    <CustomField
                        field={fieldsObj.onboarding__region}
                        option="fields."
                        className="mt-6"
                        config={{
                            type: 'GENERATING',
                            target: 'title',
                            dependencies: [
                                'fields.onboarding__sir_number',
                                'fields.onboarding__region',
                            ],
                        }}
                    />
                    <CustomField
                        field={fieldsObj.onboarding__set_of_book_company_code}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.onboarding__last_assignment_to_obt}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.onboarding__payment_terms}
                        option="fields."
                        className="mt-6"
                    />
                </div>
            </div>
            <div className="flex flex-wrap divide-x divide-gray-100">
                <div className="py-8 px-5 w-1/2">
                    <TeamRoleSelect role={rolesObj.onboarding__team_member} />
                    <TeamRoleSelect role={rolesObj.onboarding__team_member} className="mt-6" />
                    <TeamRoleSelect role={rolesObj.onboarding__vendor_master} className="mt-6" />
                    <TeamRoleSelect
                        role={rolesObj.onboarding__global_category_manager}
                        className="mt-6"
                    />
                </div>
                <div className="py-8 px-5 w-1/2">
                    <Form.Dropdown
                        right={
                            <Link
                                to={{
                                    pathname: `/projects/new/form/new-user`,
                                    search: path,
                                }}
                                className="text-blue-600 hover:text-blue-300"
                            >
                                {translate('New business partner')}
                            </Link>
                        }
                        name="fields.onboarding__realm"
                        label="Realm (Oracle/SAP)"
                        options={fieldsObj.onboarding__realm.values}
                    />
                    <CustomField
                        field={fieldsObj.onboarding__high_priority}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.onboarding__comments}
                        option="fields."
                        className="mt-6"
                    />
                    <CustomField
                        field={fieldsObj.onboarding__request_defects}
                        option="fields."
                        className="mt-6"
                    />
                </div>
            </div>
            <div className="flex flex-wrap divide-x divide-gray-100">
                <div className="py-8 px-5 w-1/2">
                    <CustomField
                        field={fieldsObj.onboarding__spend_amount_validated}
                        option="fields."
                        className="mt-6"
                    />
                </div>
            </div>
        </div>
    );
};
