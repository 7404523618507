import React from 'react';
import {Button} from '../../components/Button';
import {Section, Variation} from './DesignSystem';

export function Buttons() {
    return (
        <>
            <Section title="Color">
                <Variation>
                    <Button>Primary Button</Button>
                </Variation>

                <Variation>
                    <Button color="white">Secondary Button</Button>
                </Variation>

                <Variation>
                    <Button color="yellow">Warning Button</Button>
                </Variation>

                <Variation>
                    <Button color="red">Danger Button</Button>
                </Variation>
            </Section>

            <Section title="Size">
                <Variation>
                    <Button>Regular Button</Button>
                </Variation>

                <Variation>
                    <Button size="small">Small Button</Button>
                </Variation>
            </Section>

            <Section title="Disabled state">
                <Variation hint="Enabled button">
                    <Button disabled={false}>Progress</Button>
                </Variation>
                <Variation hint="Disabled button">
                    <Button disabled={true}>Progress</Button>
                </Variation>
            </Section>

            <Section title="Rounded corners">
                <Variation hint="Default radius">
                    <Button radius="regular">Boring</Button>
                </Variation>
                <Variation hint="rounded-full radius">
                    <Button radius="full">Let's go</Button>
                </Variation>
            </Section>

            <Section title="Icons">
                <Variation hint="Text &amp; Icon">
                    <Button icon="add">Earn money</Button>
                </Variation>

                <Variation hint="Small button with text &amp; Icon">
                    <Button icon="add" size="small">
                        Earn less money
                    </Button>
                </Variation>

                <Variation hint="Button with icon ONLY">
                    <Button color="white" icon="calendar_today"></Button>
                </Variation>

                <Variation hint="Rounded small button with icon ONLY">
                    <Button color="white" size="small" radius="full" icon="dashboard"></Button>
                </Variation>
            </Section>

            <Section title="Links">
                <Variation>
                    <Button.Link to="/projects" color="white">
                        Go to projects
                    </Button.Link>
                </Variation>
            </Section>
        </>
    );
}
