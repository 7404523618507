import {State} from './types';
import {LOG_OUT} from './actionTypes';

const initialState = {
    //
};


export default function(state:State = initialState, action: {type: string, payload: any}) {
    switch (action.type) {
        case LOG_OUT: {
            return state;
        }
        default:
            return state;
    }
}
