import {route} from '../routeListWithPermissionRestrictions';
import {permissionRequest as checkPermission} from '../../../utils/wildcard';

const getMatchingRoute: (pathname: string, routeList: route[], base: string) => route | undefined = (pathname, routeList, base) =>
    routeList.find(
        ({path}) => pathname.startsWith(`${base}/${path}`)
    );

const checkAuthorization:
    (pathname: string, userPermissions: any, routeList: route[], base?: string) => boolean = (pathname, userPermissions, routeList, base = '') => {

    const matchingRoute = getMatchingRoute(pathname, routeList, base);

    if (!matchingRoute) {// when no matching route
        // return false;
        return Boolean(base); // todo: once list has all routes within, this line can be just "return false;"
    }
    if (matchingRoute.permissions === null) {// when permissions === null, this route is accessible with no permissions
        return true;
    }
    if (!(
        matchingRoute.permissions.length > 0 && matchingRoute.permissions.reduce( // when route has permissions and all passes checking
            (authorized, routePermission) => authorized && checkPermission(userPermissions, routePermission), true
        )
    )) {
        return false;
    }
    if (matchingRoute.children) {// if has children
        return checkAuthorization(pathname, userPermissions, matchingRoute.children, matchingRoute.path);
    }

    return true;
};

export default checkAuthorization;
