import React, {useContext} from 'react';
import {User, UserGroup} from '@contractool/schema';
import {Form} from '../../components/Form';
import {http} from '../../utils/http';
import {Modal} from '../../components/Modal';
import {Button} from '../../components/Button';
import {useHistory} from 'react-router-dom';
import {timezones} from '../../utils/timezones';
import {useRequest} from '../../utils/hooks/useRequest';
import {TranslationContext} from 'contexts';

export function TeamNew({onCreate}: {onCreate: () => void}) {
    const history = useHistory();
    const [{user_groups}] = useRequest<{user_groups: UserGroup[]}>('/api/configuration', {
        user_groups: [],
    });

    const {translate} = useContext(TranslationContext);

    const close = () => {
        history.push('/team');
    };

    return (
        <Modal heading={translate('Add user')} onClose={close}>
            <Form
                initialValues={{name: '', email: '', timezone: '', groups: []}}
                onSubmit={(values) => http.post<User>('/api/users', values)}
                onSuccess={() => {
                    close();
                    onCreate();
                }}
            >
                <Form.TextInput
                    name="name"
                    label={translate('Name')}
                    className="mb-6"
                    autoFocus={true}
                />
                <Form.TextInput name="email" label={translate('Email')} className="mb-6" />
                <Form.Dropdown
                    name="timezone"
                    label={translate('Timezone')}
                    options={timezones}
                    className="mb-6"
                    autocomplete
                />
                <Form.Switch.Multiple
                    name="groups"
                    label={translate('Groups')}
                    items={user_groups}
                    toKey={(group) => group.key}
                    className="flex flex-wrap py-4"
                >
                    {(group, Switch) => (
                        <div key={group.key} className="w-1/2 p-2">
                            <Switch>{group.label}</Switch>
                        </div>
                    )}
                </Form.Switch.Multiple>

                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={close}>
                        {translate('Cancel')}
                    </Button>

                    <Form.Submit>{translate('Save Changes')}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
