import {useRef, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {toQueryString, fromQueryString} from '../url';
import {cast} from '../misc';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

export function useParams<T>(
    initial: T,
): [
    T,
    {
        setParam: <K extends keyof T>(key: K, value: T[K]) => void;
        setParams: (values: Partial<T>) => void;
        clearParam: <K extends keyof T>(name: undefined extends T[K] ? K : never) => void;
    },
] {
    const [params, setParams] = useState(initial);

    return [
        params,

        {
            setParams(updates) {
                setParams({...params, ...updates});
            },

            setParam(key, value) {
                setParams({...params, [key]: value});
            },

            clearParam(name) {
                setParams({...params, [name]: undefined});
            },
        },
    ];
}

export function useQueryParams<T>(
    initial: T,
    initPath?: string,
): [
    T,
    {
        setParam: <K extends keyof T>(key: K, value: T[K]) => void;
        setParams: (values: Partial<T>) => void;
        clearParam: <K extends keyof T>(name: undefined extends T[K] ? K : never) => void;
        saveParams: (values: Partial<T>) => void;
        setSavedParams: () => void;
    },
] {
    const history = useHistory();
    const location = useLocation();
    const refValues = useRef<Record<string, any> | null>(null);

    function initialValue(key: string): any {
        return (initial as any)[key];
    }

    const allQueryParams = Object.fromEntries(
        Object.entries(fromQueryString(location.search)).map(([key, value]) => [
            key,
            cast(value, typeof initialValue(key)),
        ]),
    );

    const queryParams = pick(allQueryParams, Object.keys(initial));

    function navigate(input: Record<string, any>) {
        history.push({
            pathname: initPath ? initPath : location.pathname,
            search: toQueryString(
                omitBy(
                    {...allQueryParams, ...input},
                    (value, key) => initialValue(key as string) === value,
                ),
            ),
        });
    }

    return [
        {...initial, ...queryParams},
        {
            setParam(key, value) {
                navigate({[key]: value});
            },
            setParams(values) {
                navigate(values);
            },
            clearParam(key) {
                navigate({[key]: undefined});
            },
            saveParams(values) {
                refValues.current = values;
            },
            setSavedParams() {
                if (refValues.current) {
                    navigate(refValues.current);
                } else {
                    navigate(queryParams);
                }
            },
        },
    ];
}
