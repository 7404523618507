export interface route {
    path: string,
    permissions: string[] | null,
    children?: route[]
}

const routeListWithPermissionRestrictions: route[] = [
    {
        path: 'projects',
        permissions: ['project.view'],
        children: [
            {
                path: 'new',
                permissions: ['project.create']
            }
        ]
    },
    {
        path: 'reports',
        permissions: ['reports.view']
    },
    {
        path: 'repository',
        permissions: ['repository.view']
    },
    {
        path: 'library',
        permissions: ['library.view']
    },
    {
        path: 'clauses',
        permissions: ['clauses.view']
    },
    {
        path: 'calendar',
        permissions: ['calendar.view']
    },
    {
        path: 'suppliers',
        permissions: ['suppliers.view']
    },
    {
        path: 'team',
        permissions: ['team.view']
    },
    {
        path: 'notifications',
        permissions: null
    },
    {
        path: 'suppliers',
        permissions: ['suppliers.view']
    },
];

export default routeListWithPermissionRestrictions;
