import {CustomReport} from '@contractool/schema';
import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {TranslationContext} from 'contexts';
import {Context as ReportTabContext} from 'contexts/reports/tab';
import {Context as ReportContext} from 'contexts/reports/tab/report';
import {Form} from 'components/Form';
import {Modal} from 'components/Modal';
import {Form as ReportForm} from 'components/reports';

const EditOverlay = () => {
    const history = useHistory();
    const {idx: tabIdx} = React.useContext(ReportTabContext);
    const handleClose = React.useCallback(() => {
        history.push(`/reports/${tabIdx}`);
    }, [history, tabIdx]);

    const {update: updateReport} = React.useContext(ReportContext);
    const handleSuccess = React.useCallback(async (config: any) => {
        await updateReport(config);
        handleClose();
    }, [handleClose, updateReport]);

    const {config: initialValues} = React.useContext(ReportContext);
    const {translate} = React.useContext(TranslationContext);

    return (
        <Modal
            heading={translate('Edit report')}
            onClose={handleClose}
            size="small"
        >
            {initialValues.title && (
                <Form
                    initialValues={initialValues}
                    onSubmit={(values: CustomReport) => Promise.resolve({data: values})}
                    onSuccess={handleSuccess}
                >
                    <ReportForm/>
                    <Modal.Footer className="flex justify-center py-16">
                        <Form.Submit>{translate('Edit')}</Form.Submit>
                    </Modal.Footer>
                </Form>
            )}
        </Modal>
    )
};

export default EditOverlay;
