import * as React from 'react';
import {default as CurrencyInputCore} from 'react-currency-input-field';
import {Icons, Icon} from 'components/Icon';

const CurrencyInput: React.FC<{
    name: string;
    value?: string;
    icon?: Icons;
    className?: string;
    defaultValue?: string;
    placeholder?: string;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    maxLength?: number;
    onChange?: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    locale: string;
    currency: string;
}> = ({
    name,
    placeholder,
    value,
    icon,
    className,
    defaultValue,
    onChange,
    required,
    autoFocus,
    disabled = false,
    readOnly = false,
    maxLength,

    onFocus,
    onBlur,
    onKeyDown,
    locale,
    currency,
}) => {
    const handleOnValueChange = (v: any) => {
        onChange && onChange(v);
    };

    const id = React.useMemo(() => (
        Math.random().toString().slice(2, 8)
    ), []);

    return (
        <div className="relative">
            {icon && (
                <Icon name={icon} size={5} className="absolute left-0 top-0 h-full text-gray-600" />
            )}
            <CurrencyInputCore
                id={id}
                name={name}
                className={className}
                placeholder={placeholder}
                value={value || defaultValue || ''}
                required={required}
                autoFocus={autoFocus}
                disabled={disabled}
                readOnly={readOnly}
                style={{backgroundColor: 'transparent'}}
                maxLength={maxLength}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onValueChange={handleOnValueChange}
                intlConfig={{locale, currency}}
            />
        </div>
    );
};

export default CurrencyInput;
