import {createContext} from 'react';

const AppContext = createContext<{
    loaded: Boolean; // todo: use React.Suspense
    config: any;
    refresh: () => void;
}>({
    loaded: false,
    config: null,
    refresh: () => {},
});

export default AppContext;
