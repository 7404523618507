/**
 * Attention!
 *
 * Icons come from: https://material.io/resources/icons/?style=baseline.
 *
 * Please use [SVG OMG](https://jakearchibald.github.io/svgomg/)
 * when importing SVGs. Make sure you configure it to remove
 * explicit width/height attributes (prefer viewBox to w/h) and
 * prettify the markup. Don't forget to crank up "precision"
 * to 100%. Thank you
 */
import React, {ReactElement} from 'react';

import {ReactComponent as AddIcon} from '../assets/icons/add.svg';
import {ReactComponent as AlarmIcon} from '../assets/icons/alarm.svg';
import {ReactComponent as ArrowBackIcon} from '../assets/icons/arrow_back.svg';
import {ReactComponent as ArrowDownwardIcon} from '../assets/icons/arrow_downward.svg';
import {ReactComponent as ArrowForwardIcon} from '../assets/icons/arrow_forward.svg';
import {ReactComponent as AssignmentIcon} from '../assets/icons/assignment.svg';
import {ReactComponent as BarChartIcon} from '../assets/icons/bar_chart.svg';
import {ReactComponent as CameraAltIcon} from '../assets/icons/camera_alt.svg';
import {ReactComponent as CategoryIcon} from '../assets/icons/category.svg';
import {ReactComponent as ChatIcon} from '../assets/icons/chat.svg';
import {ReactComponent as DashboardIcon} from '../assets/icons/dashboard.svg';
import {ReactComponent as DateRangeIcon} from '../assets/icons/date_range.svg';
import {ReactComponent as DehazeIcon} from '../assets/icons/dehaze.svg';
import {ReactComponent as DeleteIcon} from '../assets/icons/delete.svg';
import {ReactComponent as DescriptionIcon} from '../assets/icons/description.svg';
import {ReactComponent as DoneIcon} from '../assets/icons/done.svg';
import {ReactComponent as DuplicateIcon} from '../assets/icons/duplicate.svg';
import {ReactComponent as CalendarTodayIcon} from '../assets/icons/calendar_today.svg';
import {ReactComponent as ChartHorizontal} from '../assets/icons/chart_horizontal.svg';
import {ReactComponent as ChartLinear} from '../assets/icons/chart_linear.svg';
import {ReactComponent as ChartPie} from '../assets/icons/chart_pie.svg';
import {ReactComponent as ChartVertical} from '../assets/icons/chart_vertical.svg';
import {ReactComponent as CloneIcon} from '../assets/icons/clone.svg';
import {ReactComponent as CloseIcon} from '../assets/icons/close.svg';
import {ReactComponent as CloudUpload} from '../assets/icons/cloud_upload.svg';
import {ReactComponent as ContactsIcon} from '../assets/icons/contacts.svg';
import {ReactComponent as CopyIcon} from '../assets/icons/copy.svg';
import {ReactComponent as EditIcon} from '../assets/icons/edit.svg';
import {ReactComponent as ErrorIcon} from '../assets/icons/error.svg';
import {ReactComponent as ExitToAppIcon} from '../assets/icons/exit_to_app.svg';
import {ReactComponent as FileIcon} from '../assets/icons/file.svg';
import {ReactComponent as FileAltIcon} from '../assets/icons/file-alt.svg';
import {ReactComponent as FileArchiveIcon} from '../assets/icons/file-archive.svg';
import {ReactComponent as FileAudioIcon} from '../assets/icons/file-audio.svg';
import {ReactComponent as FileExcelIcon} from '../assets/icons/file-excel.svg';
import {ReactComponent as FileImageIcon} from '../assets/icons/file-image.svg';
import {ReactComponent as FilePdfIcon} from '../assets/icons/file-pdf.svg';
import {ReactComponent as FilePowerpointIcon} from '../assets/icons/file-powerpoint.svg';
import {ReactComponent as FileVideoIcon} from '../assets/icons/file-video.svg';
import {ReactComponent as FileWordIcon} from '../assets/icons/file-word.svg';
import {ReactComponent as GetAppIcon} from '../assets/icons/get_app.svg';
import {ReactComponent as GavelIcon} from '../assets/icons/gavel.svg';
import {ReactComponent as GestureIcon} from '../assets/icons/gesture.svg';
import {ReactComponent as GradeIcon} from '../assets/icons/grade.svg';
import {ReactComponent as GradeOutlineIcon} from '../assets/icons/grade_outline.svg';
import {ReactComponent as KeyboardArrowDownIcon} from '../assets/icons/keyboard_arrow_down.svg';
import {ReactComponent as KeyboardArrowLeftIcon} from '../assets/icons/keyboard_arrow_left.svg';
import {ReactComponent as KeyboardArrowRightIcon} from '../assets/icons/keyboard_arrow_right.svg';
import {ReactComponent as KeyboardArrowUpIcon} from '../assets/icons/keyboard_arrow_up.svg';
import {ReactComponent as Lock} from '../assets/icons/lock.svg';
import {ReactComponent as LockOpen} from '../assets/icons/lock_open.svg';
import {ReactComponent as LockOutlinedIcon} from '../assets/icons/lock_outlined.svg';
import {ReactComponent as Looks4} from '../assets/icons/looks_4.svg';
import {ReactComponent as ListIcon} from '../assets/icons/list.svg';
import {ReactComponent as MenuOpenIcon} from '../assets/icons/menu_open.svg';
import {ReactComponent as MoreHorizIcon} from '../assets/icons/more_horiz.svg';
import {ReactComponent as MoreVertIcon} from '../assets/icons/more_vert.svg';
import {ReactComponent as NotificationsIcon} from '../assets/icons/notifications.svg';
import {ReactComponent as ExternalLinkIcon} from '../assets/icons/open_in_new.svg';
import {ReactComponent as PauseIcon} from '../assets/icons/pause.svg';
import {ReactComponent as PeopleIcon} from '../assets/icons/people.svg';
import {ReactComponent as PersonIcon} from '../assets/icons/person.svg';
import {ReactComponent as RefreshIcon} from '../assets/icons/refresh.svg';
import {ReactComponent as RemoveCircleIcon} from '../assets/icons/remove_circle.svg';
import {ReactComponent as RemoveRedEyeIcon} from '../assets/icons/remove_red_eye.svg';
import {ReactComponent as ReorderIcon} from '../assets/icons/reorder.svg';
import {ReactComponent as SearchIcon} from '../assets/icons/search.svg';
import {ReactComponent as SelectAllIcon} from '../assets/icons/select_all.svg';
import {ReactComponent as SettingsIcon} from '../assets/icons/settings.svg';
import {ReactComponent as SettingsRestoreIcon} from '../assets/icons/settings_restore.svg';
import {ReactComponent as ScheduleIcon} from '../assets/icons/schedule.svg';
import {ReactComponent as TriangleIcon} from '../assets/icons/triangle.svg';
import {ReactComponent as TuneIcon} from '../assets/icons/tune.svg';
import {ReactComponent as PdfIcon} from '../assets/icons/file-pdf.svg';
import {ReactComponent as FilterList} from '../assets/icons/filter_list.svg';
import {ReactComponent as Filter8} from '../assets/icons/filter_8.svg';
import {ReactComponent as VisibilityIcon} from '../assets/icons/visibility.svg';
import {ReactComponent as WordIcon} from '../assets/icons/file-word.svg';
import {ReactComponent as TaskAssignment} from '../assets/icons/task_assignment.svg';
import {ReactComponent as Launch} from '../assets/icons/launch.svg';
import {ReactComponent as DotsCircle} from '../assets/icons/dots_circle.svg';
import {ReactComponent as PersonPlus} from '../assets/icons/person_plus.svg';
import {ReactComponent as Magnifier} from '../assets/icons/magnifier.svg';
import {ReactComponent as Cursor} from '../assets/icons/cursor.svg';
import {ReactComponent as FolderOpen} from '../assets/icons/folder_open.svg';
import {ReactComponent as HelpIcon} from '../assets/icons/help.svg';
import {ReactComponent as AttachMoneyIcon} from '../assets/icons/attach_money.svg';
import {ReactComponent as GroupIcon} from '../assets/icons/group.svg';
import {ReactComponent as LightBulbIcon} from '../assets/icons/lightbulb.svg';
import {ReactComponent as PersonPinIcon} from '../assets/icons/person_pin.svg';
import {ReactComponent as CorporateIcon} from '../assets/icons/corporate.svg';
import {ReactComponent as AddToPhotosIcon} from '../assets/icons/add_to_photos.svg';
import {ReactComponent as DraftsIcon} from '../assets/icons/drafts.svg';
import {ReactComponent as DragHandleIcon} from '../assets/icons/drag_handle.svg';

export const IconNames = [
    'add',
    'add_to_photos',
    'alarm',
    'arrow_back',
    'arrow_downward',
    'arrow_forward',
    'assignment',
    'attach_money',
    'bar_chart',
    'calendar_today',
    'camera_alt',
    'category',
    'chat',
    'chart-horizontal',
    'chart-linear',
    'chart-pie',
    'chart-vertical',
    'clone',
    'close',
    'cloud_upload',
    'contacts',
    'copy',
    'corporate',
    'cursor',
    'dashboard',
    'date_range',
    'dehaze',
    'delete',
    'description',
    'done',
    'dots_circle',
    'drafts',
    'drag_handle',
    'duplicate',
    'edit',
    'error',
    'exit_to_app',
    'external_link',
    'file',
    'file-alt',
    'file-archive',
    'file-audio',
    'file-excel',
    'file-image',
    'file-pdf',
    'file-powerpoint',
    'file-video',
    'file-word',
    'filter_8',
    'filter_list',
    'folder_open',
    'gavel',
    'gesture',
    'get_app',
    'grade',
    'grade_outline',
    'group',
    'help',
    'keyboard_arrow_down',
    'keyboard_arrow_left',
    'keyboard_arrow_right',
    'keyboard_arrow_up',
    'launch',
    'lightbulb',
    'list',
    'lock',
    'lock_open',
    'lock_outlined',
    'looks_4',
    'magnifier',
    'menu_open',
    'more_horiz',
    'more_vert',
    'notifications',
    'pause',
    'pdf',
    'people',
    'person',
    'person_pin',
    'person_plus',
    'refresh',
    'remove_circle',
    'remove_red_eye',
    'reorder',
    'schedule',
    'search',
    'select_all',
    'settings',
    'settings_restore',
    'task_assignment',
    'triangle',
    'tune',
    'visibility',
    'word',
] as const;
export type Icons = typeof IconNames[number];

export type Size = 2 | 4 | 5 | 6 | 7 | 8 | 10 | 12 | 14 | 16;

export function Icon({
    name,
    className = '',
    size,
}: {
    name: Icons;
    className?: string;
    size: Size;
}): ReactElement {
    className = className + ' fill-current ' + sizeStyles(size);

    switch (name) {
        case 'add_to_photos':
            return <AddToPhotosIcon className={className} />;
        case 'add':
            return <AddIcon className={className} />;
        case 'alarm':
            return <AlarmIcon className={className} />;
        case 'arrow_back':
            return <ArrowBackIcon className={className} />;
        case 'arrow_downward':
            return <ArrowDownwardIcon className={className} />;
        case 'arrow_forward':
            return <ArrowForwardIcon className={className} />;
        case 'assignment':
            return <AssignmentIcon className={className} />;
        case 'attach_money':
            return <AttachMoneyIcon className={className} />;
        case 'bar_chart':
            return <BarChartIcon className={className} />;
        case 'calendar_today':
            return <CalendarTodayIcon className={className} />;
        case 'camera_alt':
            return <CameraAltIcon className={className} />;
        case 'category':
            return <CategoryIcon className={className} />;
        case 'chart-horizontal':
            return <ChartHorizontal className={className} />;
        case 'chart-linear':
            return <ChartLinear className={className} />;
        case 'chart-pie':
            return <ChartPie className={className} />;
        case 'chart-vertical':
            return <ChartVertical className={className} />;
        case 'chat':
            return <ChatIcon className={className} />;
        case 'close':
            return <CloseIcon className={className} />;
        case 'clone':
            return <CloneIcon className={className} />;
        case 'cloud_upload':
            return <CloudUpload className={className} />;
        case 'contacts':
            return <ContactsIcon className={className} />;
        case 'corporate':
            return <CorporateIcon className={className} />;
        case 'copy':
            return <CopyIcon className={className} />;
        case 'cursor':
            return <Cursor className={className} />;
        case 'date_range':
            return <DateRangeIcon className={className} />;
        case 'dashboard':
            return <DashboardIcon className={className} />;
        case 'dehaze':
            return <DehazeIcon className={className} />;
        case 'delete':
            return <DeleteIcon className={className} />;
        case 'description':
            return <DescriptionIcon className={className} />;
        case 'done':
            return <DoneIcon className={className} />;
        case 'dots_circle':
            return <DotsCircle className={className} />;
        case 'duplicate':
            return <DuplicateIcon className={className} />;
        case 'drafts':
            return <DraftsIcon className={className} />;
        case 'drag_handle':
            return <DragHandleIcon className={className} />;
        case 'edit':
            return <EditIcon className={className} />;
        case 'error':
            return <ErrorIcon className={className} />;
        case 'exit_to_app':
            return <ExitToAppIcon className={className} />;
        case 'external_link':
            return <ExternalLinkIcon className={className} />;
        case 'file':
            return <FileIcon className={className} />;
        case 'file-alt':
            return <FileAltIcon className={className} />;
        case 'file-archive':
            return <FileArchiveIcon className={className} />;
        case 'file-audio':
            return <FileAudioIcon className={className} />;
        case 'file-excel':
            return <FileExcelIcon className={className} />;
        case 'file-image':
            return <FileImageIcon className={className} />;
        case 'file-pdf':
            return <FilePdfIcon className={className} />;
        case 'file-powerpoint':
            return <FilePowerpointIcon className={className} />;
        case 'file-video':
            return <FileVideoIcon className={className} />;
        case 'file-word':
            return <FileWordIcon className={className} />;
        case 'filter_list':
            return <FilterList className={className} />;
        case 'filter_8':
            return <Filter8 className={className} />;
        case 'folder_open':
            return <FolderOpen className={className} />;
        case 'get_app':
            return <GetAppIcon className={className} />;
        case 'gavel':
            return <GavelIcon className={className} />;
        case 'gesture':
            return <GestureIcon className={className} />;
        case 'grade':
            return <GradeIcon className={className} />;
        case 'grade_outline':
            return <GradeOutlineIcon className={className} />;
        case 'group':
            return <GroupIcon className={className} />;
        case 'help':
            return <HelpIcon className={className} />;
        case 'keyboard_arrow_down':
            return <KeyboardArrowDownIcon className={className} />;
        case 'keyboard_arrow_left':
            return <KeyboardArrowLeftIcon className={className} />;
        case 'keyboard_arrow_right':
            return <KeyboardArrowRightIcon className={className} />;
        case 'keyboard_arrow_up':
            return <KeyboardArrowUpIcon className={className} />;
        case 'launch':
            return <Launch className={className} />;
        case 'lock':
            return <Lock className={className} />;
        case 'lock_open':
            return <LockOpen className={className} />;
        case 'lock_outlined':
            return <LockOutlinedIcon className={className} />;
        case 'looks_4':
            return <Looks4 className={className} />;
        case 'list':
            return <ListIcon className={className} />;
        case 'lightbulb':
            return <LightBulbIcon className={className} />;
        case 'magnifier':
            return <Magnifier className={className} />;
        case 'menu_open':
            return <MenuOpenIcon className={className} />;
        case 'more_horiz':
            return <MoreHorizIcon className={className} />;
        case 'more_vert':
            return <MoreVertIcon className={className} />;
        case 'notifications':
            return <NotificationsIcon className={className} />;
        case 'pause':
            return <PauseIcon className={className} />;
        case 'people':
            return <PeopleIcon className={className} />;
        case 'person':
            return <PersonIcon className={className} />;
        case 'person_pin':
            return <PersonPinIcon className={className} />;
        case 'person_plus':
            return <PersonPlus className={className} />;
        case 'refresh':
            return <RefreshIcon className={className} />;
        case 'remove_circle':
            return <RemoveCircleIcon className={className} />;
        case 'remove_red_eye':
            return <RemoveRedEyeIcon className={className} />;
        case 'reorder':
            return <ReorderIcon className={className} />;
        case 'search':
            return <SearchIcon className={className} />;
        case 'select_all':
            return <SelectAllIcon className={className} />;
        case 'settings':
            return <SettingsIcon className={className} />;
        case 'settings_restore':
            return <SettingsRestoreIcon className={className} />;
        case 'schedule':
            return <ScheduleIcon className={className} />;
        case 'task_assignment':
            return <TaskAssignment className={className} />;
        case 'triangle':
            return <TriangleIcon className={className} />;
        case 'tune':
            return <TuneIcon className={className} />;
        case 'pdf':
            return <PdfIcon className={className} />;
        case 'visibility':
            return <VisibilityIcon className={className} />;
        case 'word':
            return <WordIcon className={className} />;
    }
}

function sizeStyles(s: Size): string {
    return `w-${s} h-${s}`;
}
