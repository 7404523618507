import React, {FC} from 'react';
import {Log, Project} from '@contractool/schema';
import {MilestoneLog} from "../ProjectLogs/MilestoneLog";
import {MilestonesLog} from "../ProjectLogs/MilestonesLog";
import {MilestonesEditLog} from "../ProjectLogs/MilestonesEditLog";

export const MilestonesLogs: FC<{project: Project}> = ({project}) => {
    const milestoneLogs = project.logs_full.filter((log: {meta: {type: string} }) =>
        log.meta.type.match(/MILESTONE/),
    );
    return (
        <div>
            {milestoneLogs.map((log: Log, index: number) => {
                if (log.meta.type === 'MILESTONE_NEW') {
                    return (
                        <MilestonesEditLog key={`milestone-tab-${index}`} log={log}/>
                    );
                }
                if (log.meta.type === 'MILESTONE_DELETED') {
                    return (
                        <MilestonesEditLog key={`milestone-tab-${index}`} log={log}/>
                    );
                }
                if (log.meta.type === 'MILESTONE') {
                    return (
                        <MilestoneLog key={`milestone-tab-${index}`} log={log}/>
                    );
                }
                if (log.meta.type === 'MILESTONES') {
                    return (
                        <MilestonesLog key={`milestone-tab-${index}`} log={log}/>
                    );
                }
                return <MilestoneLog key={`milestone-tab-${index}`} log={log}/>
            })}
        </div>
    );
};
