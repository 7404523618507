export function cast<T>(
    input: T,
    type:
        | 'string'
        | 'number'
        | 'bigint'
        | 'boolean'
        | 'symbol'
        | 'undefined'
        | 'object'
        | 'function',
): string | number | boolean | T {
    if (type === 'string') {
        return String(input);
    }

    if (type === 'number') {
        return Number(input);
    }

    if (type === 'boolean') {
        return Boolean(input);
    }

    return input;
}
