import React, {FC} from 'react';
import {ActivityContainer, ActivityHeader, ActivityBody} from '../ActivityPanel';
import {LogFull} from '@contractool/schema';

export const TeamLog: FC<{
    desc: string;
    log: LogFull;
}> = ({desc, log}) => {
    return (
        <ActivityContainer>
            <ActivityHeader
                causer={log.causer}
                desc={desc}
                created_at={log.created_at}
                timezone={log.causer.timezone}
            />
            <ActivityBody>
                {/* TODO: Add avatar_url to extra */}
                <p>
                    {log.message.extra.name}
                    <span className="text-gray-600">
                        <span className="text-lg mx-2">&middot;</span>
                        {log.message.extra.role}
                    </span>
                </p>
            </ActivityBody>
        </ActivityContainer>
    );
};
