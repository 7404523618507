import React, {FC, ReactElement, MouseEvent as ReactMouseEvent} from 'react';
import {Icons, Icon} from './Icon';
import {Popper} from './Popper';
import {Placement} from 'popper.js';

export const Menu: FC<{
    placement?: Placement;
    autoClose?: boolean;
    handle: Icons | ((props: {toggleMenu: () => void}) => ReactElement);
}> = ({placement = 'bottom-start', handle, children, autoClose = false}) => {
    return (
        <Popper
            handle={({ref, onClick}) => (
                <div ref={ref} data-testid="menu-handle">
                    {typeof handle === 'string' ? (
                        <button onClick={onClick}>
                            <Icon name={handle} className="text-gray-600" size={6} />
                        </button>
                    ) : (
                        handle({toggleMenu: onClick})
                    )}
                </div>
            )}
            placement={placement}
        >
            {({ref, style, close}) => (
                <div
                    ref={ref}
                    style={style}
                    className="bg-white mt-4 shadow rounded-xl z-10"
                    data-testid="menu-content"
                    onClick={() => autoClose && close()}
                >
                    {children}
                </div>
            )}
        </Popper>
    );
};

export const MenuItem: FC<{
    icon: Icons;
    onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({icon, onClick, children}) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="w-full flex items-center leading-tighter transition rounded-lg p-5 bg-white hover:bg-gray-000 hover:border-gray-000 focus:outline-none focus:shadow-outline"
        >
            <Icon name={icon} size={5} className="text-gray-600 mr-3"></Icon>

            <span className="select-none text-gray-700">{children}</span>
        </button>
    );
};
