import Axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import qs from 'qs';
import {useToasts} from "../components/Toast";
import {useBigLoader, useSmallLoader} from "components/Loader";

const axios = Axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export function registerErrorResponseInterceptor(callback: (response: AxiosResponse) => void) {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            callback(error.response);
            return Promise.reject(error);
        },
    );
}

export interface Response<T> {
    data: T;
    meta?: any;
}

interface Params {
    [name: string]: any;
}

export const http = {
    get<T>(
        url: string,
        params: Params = {},
        options: AxiosRequestConfig = {},
    ): Promise<Response<T>> {
        axios.interceptors.request.use((config) => {
            config.paramsSerializer = (params) => {
                return qs.stringify(params, {
                    arrayFormat: 'brackets',
                    encode: false,
                });
            };
            return config;
        });
        return axios
            .get<Response<T>>(url, {...options, params})
            .then((response) => response.data);
    },

    head<T>(url: string, data?: any): Promise<Response<T>> {
        return axios.head<Response<T>>(url, data,).then((response) => response.data);
    },

    patch<T>(url: string, data?: any): Promise<Response<T>> {
        return axios.patch<Response<T>>(url, data).then((response) => response.data);
    },

    post<T>(url: string, data?: any): Promise<Response<T>> {
        return axios.post<Response<T>>(url, data).then((response) => response.data);
    },

    put<T>(url: string, data?: any): Promise<Response<T>> {
        return axios.put<Response<T>>(url, data).then((response) => response.data);
    },

    delete<T>(url: string): Promise<Response<T>> {
        return axios.delete<Response<T>>(url, {withCredentials: true}).then((response) => response.data);
    },
};

axios.interceptors.response.use(
    (response) => {
        if (process.env.NODE_ENV !== 'production') {
            if (
                response.status === 200 &&
                typeof response.data === 'string' &&
                response.data?.includes('window.Sfdump')
            ) {
                renderDebugView(response.data);
            }
        }
        return response;
    },
    (httpError) => {

        if (process.env.NODE_ENV !== 'production') {
            if (httpError.response?.status === 500 && httpError.response?.data?.html) {
                renderDebugView(httpError.response.data.html);
            }
        }

        if (parseInt(httpError.response?.status) === 423) {
            const smallLoader = useSmallLoader();
            const bigLoader = useBigLoader();
            const {error} = useToasts();
            error(httpError.response?.data?.message);
            smallLoader.stop();
            bigLoader.stop();
        }

        return Promise.reject(httpError);
    },
);

function renderDebugView(html: string): void {
    const doc = document.open('text/html', 'replace');
    doc.write(html);
    doc.close();
}
