import React, {useContext, useState} from 'react';
import {Button} from '../../Button';
import {Icon} from '../../Icon';
import {Modal} from '../../Modal';
import {http} from '../../../utils/http';
import {Checkbox} from '../../Checkbox';
import Search from '..';
import {TranslationContext} from 'contexts';

const titleColumnStyle = {
    maxWidth: '12rem',
};

function CruncherListModal({phrase, onClose}: {phrase: string; onClose: () => void}) {
    const [exact, setExact] = useState(false);
    const [result, setResult] = useState<any[]>([]);
    const [cruncherPhrase, setCruncherPhrase] = useState<string>(phrase);
    const {translate} = useContext(TranslationContext);

    const cruncher = () => {
        http.get('api/cruncher-search?fuzzy=' + (exact ? 0 : 1), {phrase: cruncherPhrase}).then(
            (result: any) => {
                console.log('cruncher result', result);
                // alert(result);
                setResult(result.data);
            },
        );
    };

    return (
        <Modal
            heading={
                <div className="flex w-full">
                    <Search
                        placeholder={translate('Search in files')}
                        value={cruncherPhrase}
                        onChange={setCruncherPhrase}
                    />
                    <div className="w-340 flex items-center mx-6">
                        <Checkbox
                            name="exact"
                            className="mr-3"
                            value="1"
                            selectedValues={exact ? ['1'] : []}
                            onChange={(v: string[]) => {
                                setExact(v.length === 1 && v[0] === '1');
                            }}
                        />
                        {translate('Exact match')}
                    </div>
                    <Button onClick={cruncher}>{translate('Search in files')}</Button>
                </div>
            }
            isOpen={true}
            onClose={onClose}
            size="w-10/12"
            compact={true}
        >
            <table className="table">
                <tbody>
                    {result.map((row: any, idx: number) => {
                        let result = row.occurrences[0];
                        // let index = result.search('%%%');
                        // let count = 0;
                        // while (index !== -1) {
                        //     if (count % 2 === 0) {
                        //         result = result.split('');
                        //         result.splice(index, 3, ...'<span style="background-color: yellow">'.split(''));
                        //         result = result.join('');
                        //     } else {
                        //         result = result.split('');
                        //         result.splice(index, 3, ...'</span>'.split(''));
                        //         result = result.join('');
                        //     }
                        //     count++;
                        //     index = result.search('%%%');
                        // }

                        return (
                            <>
                                <tr key={idx + '-1'} style={{borderBottom: 'none'}}>
                                    <td colSpan={4}>
                                        <div className="font-bold text-lg overflow-hidden">
                                            {row.title}
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    key={idx + '-2'}
                                    className="flex items-center pb-7 border-b text-gray-700"
                                >
                                    <td>
                                        <Icon name={row.icon} className="text-gray-500" size={12} />
                                    </td>
                                    <td style={titleColumnStyle}>
                                        <div className="text-gray-700 text-sm">
                                            {row.category?.title}
                                        </div>
                                        <div className="text-gray-700 text-sm">
                                            {row.supplier?.title}
                                        </div>
                                    </td>
                                    <td className="flex-1">
                                        <div dangerouslySetInnerHTML={{__html: result}} />
                                    </td>
                                    <td>
                                        <a
                                            href={row.document_url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <Icon
                                                name="external_link"
                                                className="text-gray-500 m-auto"
                                                size={8}
                                            />
                                            {translate('Show more')}
                                        </a>
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
            <Modal.Footer className="flex justify-between">
                <Button color="white" onClick={onClose}>
                    {translate('Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const MemorizedCruncherListModal = React.memo(CruncherListModal);

export default MemorizedCruncherListModal;
