import React, {useContext} from 'react';
import {Switch, Route, useHistory} from 'react-router';
import {useQueryParams} from '../../utils/hooks/useParams';
import {useRequest} from '../../utils/hooks/useRequest';
import {Page} from '../../components/Page';
import {Icon} from '../../components/Icon';
import {Button} from '../../components/Button';
import {Supplier} from '@contractool/schema';
import {SupplierEdit} from './SuppliersEdit';
import {SupplierNew} from './SuppliersNew';
import {Pagination} from '../../components/Pagination';
import Search from '../../components/Search';
import {TranslationContext} from 'contexts';

export function SuppliersPage() {
    const [params, {setParam, setParams}] = useQueryParams({phrase: '', page: 1, per_page: 30});
    const [suppliers, {refresh, pagination}] = useRequest<Supplier[]>('/api/suppliers', [], {
        params,
    });
    const {translate} = useContext(TranslationContext);

    return (
        <Page
            heading={translate('Suppliers')}
            right={
                <Button.Link to="/suppliers/new" color="blue" icon="add">
                    {translate('Add supplier')}
                </Button.Link>
            }
        >
            <div className="mb-8">
                <Search
                    value={params.phrase}
                    onChange={(phrase) => {
                        setParams({phrase, page: 1});
                    }}
                    onClear={() => setParam('phrase', '')}
                    placeholder={`${translate('Search suppliers')}...`}
                />
            </div>

            <div className="-mx-6">
                <SuppliersList suppliers={suppliers} />
            </div>

            <div className="flex justify-center pt-16">
                <Pagination
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    totalBlocks={7}
                    onSelect={(page) => setParam('page', page)}
                />
            </div>

            <Switch>
                <Route path="/suppliers/new">
                    <SupplierNew onCreate={() => refresh()} closeUrl="/suppliers" />
                </Route>

                <Route path="/suppliers/:id/edit">
                    <SupplierEdit onUpdate={() => refresh()} />
                </Route>
            </Switch>
        </Page>
    );
}

function SuppliersList({suppliers}: {suppliers: Supplier[]}) {
    const history = useHistory();
    const {translate} = useContext(TranslationContext);

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>{translate('Supplier')}</th>
                        <th>{translate('Country')}</th>
                        <th>{translate('Contacts')}</th>
                        <th>{translate('Projects')}</th>
                        <th className="w-16"></th>
                    </tr>
                </thead>
                <tbody>
                    {suppliers.map((supplier) => (
                        <tr
                            key={supplier.id}
                            className="hover:bg-gray-000 cursor-pointer rounded"
                            onClick={() => history.push(`/suppliers/${supplier.id}/edit`)}
                        >
                            <td>{supplier.title}</td>
                            <td className="text-gray-600">{supplier.address_state}</td>
                            <td className="text-gray-600">{supplier.contacts_count}</td>
                            <td>{supplier.projects_count}</td>
                            <td>
                                {/* TODO: do we need this? */}
                                <Icon name="more_horiz" className="text-gray-600" size={6} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
