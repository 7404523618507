import React, {useContext, useState} from 'react';
import {Page} from '../components/Page';
import {Tabs, Tab} from '../components/Tabs';
import {useRequest} from '../utils/hooks/useRequest';
import {DocumentCategory, Document} from '../../../_common/schema';
import {DocumentComponent} from '../components/documents/Document';
import {Route, Switch, useHistory} from 'react-router';
import {useParams} from 'react-router-dom';
import {DocumentDetail} from '../components/documents/DocumentDetail';
import {http} from '../utils/http';
import {upload} from '../utils/upload';
import {useDropzone} from 'react-dropzone';
import {useUploadingReducer} from '../components/Avatar';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import {Icon} from '../components/Icon';
import {Dropdown} from '../components/Dropdown';
import {getOptions} from '../utils/form';
import {MainPanel} from './projects/ProjectDetail';
import {sign} from '../utils/auth';
import {AppContext, TranslationContext} from 'contexts';

export function Library() {
    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);

    const [categories] = useRequest<DocumentCategory[]>('/api/library/categories', []);
    const [documents, {refresh}] = useRequest<Document[]>('/api/library', []);
    const [{uploading, progress}, dispatch] = useUploadingReducer();
    const [newCategory, setNewCategory] = useState(0);
    console.log('categories', categories);
    console.log('documents', documents);

    const startUpload = (file: File) => {
        console.log('START UPLOAD', file);
        dispatch({type: 'start_uploading'});
        upload(file, {
            onUploadProgress: (progress) => {
                dispatch({type: 'progress_uploading', progress});
            },
        }).then((response) => {
            console.log('Upload finished', response);
            http.post<Document>('/api/library', {
                file: response,
                category_id: newCategory,
            }).then(() => {
                dispatch({type: 'finish_uploading'});
                refresh();
            });
        });
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (files) => {
            files.forEach((file) => startUpload(file));
        },
    });

    const optCtgs = getOptions(categories);
    optCtgs.unshift({label: translate('Uncategorized'), value: 0});

    return (
        <Page heading={translate('Library')}>
            <MainPanel heading={translate('Upload new document')} className=" rounded-lg p-5">
                {uploading && (
                    <div className="rounded-full w-full h-full bg-gray-000 flex items-center justify-center">
                        <CircularProgressbar
                            value={progress}
                            className="w-6 h-6"
                            strokeWidth={5}
                            styles={buildStyles({
                                pathTransitionDuration: 0.5,
                                pathColor: `#2979ff`,
                                trailColor: '#e9ecef',
                            })}
                        />
                    </div>
                )}
                <Dropdown
                    name="category_id"
                    value={0}
                    options={optCtgs}
                    onChange={(value: any) => setNewCategory(value)}
                />
                {config.config.office365 && (
                    <div>
                        <a
                            href={sign(`/document/new-library/${newCategory}`)}
                            className="flex items-center mt-5 text-blue-700"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Icon name="add" size={5} />
                            <span className="ml-2">{translate('Create new template online')}</span>
                        </a>

                        <div className="my-5">{translate('Or')}</div>
                    </div>
                )}
                <div className={`focus:outline-none mt-5`} {...getRootProps()} onClick={() => {}}>
                    <input type="file" className="hidden" {...getInputProps()} />

                    <div className="bg-gray-000 text-gray-600 p-7 rounded-xl flex items-center justify-center">
                        <Icon name="cloud_upload" size={6} />
                        <div className="pl-3">
                            {translate('Drop file here or')}{' '}
                            <span
                                className="text-blue-700 cursor-pointer focus:outline-none"
                                {...getRootProps({props: 'onClick'})}
                            >
                                {translate('browse it')}
                            </span>
                        </div>
                    </div>
                </div>
            </MainPanel>
            <Tabs selected="uncategorized" className="bg-white px-5">
                <Tab
                    name="uncategorized"
                    heading={translate('Uncategorized')}
                    className="pt-4 bg-white p-5"
                >
                    {documents
                        .filter((document) => !document.category_id)
                        .map((document, index) => {
                            return (
                                <DocumentComponent
                                    document={document}
                                    key={index}
                                    modalUrl="library"
                                />
                            );
                        })}
                </Tab>
                {categories.map((category, index) => {
                    return (
                        <Tab
                            key={'document-category' + index}
                            name={'category' + index}
                            heading={translate(category.title)}
                            className="pt-4 bg-white p-5"
                        >
                            {documents
                                .filter((document) => document.category_id === category.id)
                                .map((document, index) => {
                                    return (
                                        <DocumentComponent
                                            document={document}
                                            key={index}
                                            modalUrl="library"
                                        />
                                    );
                                })}
                        </Tab>
                    );
                })}
            </Tabs>
            <Switch>
                <Route path={`/library/documents/:documentId`}>
                    <LibraryDocumentDetail documents={documents} onUpdate={refresh} />
                </Route>
            </Switch>
        </Page>
    );
}

export function LibraryDocumentDetail({
    documents,
    onUpdate,
}: {
    documents: Document[];
    onUpdate: () => void;
}) {
    const {documentId} = useParams();
    const history = useHistory();
    const document = documents.find((t) => documentId && t.id === parseInt(documentId));
    const close = () => {
        history.push(`/library`);
    };

    if (!document) return null;
    return <DocumentDetail document={document} onUpdate={onUpdate} onClose={close} />;
}
