import React, {FC, useContext, useState} from 'react';
import {DocumentCategory, Project, Document} from '@contractool/schema';
import {http} from '../../utils/http';
import {Modal} from '../../components/Modal';
import {Button} from '../../components/Button';
import {ContractUpload} from './ContractPanel';
import {DocumentComponent} from '../../components/documents/Document';
import {Tabs, Tab} from '../../components/Tabs';
import {useRequest} from '../../utils/hooks/useRequest';
import {useToasts} from '../../components/Toast';
import {Checkbox} from '../../components/Checkbox';
import {permissionRequest} from '../../utils/wildcard';
import {TranslationContext} from 'contexts';

export function AddFiles({
    project,
    onUpdate,
    onClose,
    type,
}: {
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
    type: string | null;
}) {
    const {translate} = useContext(TranslationContext);

    const onSend = (file: {path: string; name: string}) => {
        return http.post<Document>(project.attachments_url, {file, type});
    };

    return (
        <>
            <Modal heading={translate('Add files')} onClose={onClose}>
                <label htmlFor="new-files" className="block leading-none select-none text-gray-500">
                    {translate('Upload new files')}
                </label>
                <div id="new-files" className="pt-6 pb-8 mb-12 border-gray-200 border-b">
                    <ContractUpload onSend={onSend} onUpload={onUpdate} singleFile={false} />
                </div>
                {permissionRequest(project.can, 'library.view') && (
                    <Library project={project} onUpdate={onUpdate} onClose={onClose} type={type} />
                )}
            </Modal>
        </>
    );
}

const CheckboxDocument: FC<{
    project: Project;
    document: Document;
    selectedFiles: string[];
    onChange: (values: string[]) => void;
}> = ({project, document, selectedFiles, onChange}) => {
    return (
        <Checkbox
            name={`${document.title}`}
            value={document.id.toString()}
            selectedValues={selectedFiles}
            onChange={(values) => onChange(values)}
            childsClassName="w-full"
        >
            <DocumentComponent
                document={document}
                change={false}
                deletable={false}
                download={false}
                modalUrl={`projects/${project.id}`}
            />
        </Checkbox>
    );
};

const Library: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
    type: string | null;
}> = ({project, onUpdate, onClose, type}) => {
    const [categories] = useRequest<DocumentCategory[]>('/api/library/categories', []);
    const [documents] = useRequest<Document[]>('/api/library', []);

    const {translate} = useContext(TranslationContext);

    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const {success} = useToasts();
    const addFiles = () => {
        const arrLength = selectedFiles.length;

        if (!arrLength) return;

        selectedFiles.forEach((id, index) => {
            http.post<Document>(project.library_attachments_url, {
                document_id: parseInt(id),
                type: type,
            }).then(() => {
                success(
                    `${translate('Document :file was successfully added to project', {
                        file: `${documents.find((doc) => doc.id === parseInt(id))?.title}`,
                    })}.`,
                );
                if (index === arrLength - 1) {
                    onUpdate();
                }
            });
        });
    };
    return (
        <form>
            <label className="block leading-none select-none text-gray-500 pb-6">
                {translate('Add from library')}
            </label>
            <Tabs selected="uncategorized" className="bg-white px-5">
                <Tab
                    name="uncategorized"
                    heading={translate('Uncategorized')}
                    className="pt-4 bg-white p-5"
                >
                    {documents
                        .filter((document) => !document.category_id)
                        .map((document, index) => {
                            return (
                                <CheckboxDocument
                                    key={`${document.title}${index}`}
                                    project={project}
                                    document={document}
                                    onChange={setSelectedFiles}
                                    selectedFiles={selectedFiles}
                                />
                            );
                        })}
                </Tab>
                {categories.map((category, index) => {
                    return (
                        <Tab
                            key={'document-category' + index}
                            name={'category' + index}
                            heading={category.title}
                            className="pt-4 bg-white p-5"
                        >
                            {documents
                                .filter((document) => document.category_id === category.id)
                                .map((document, index) => {
                                    return (
                                        <CheckboxDocument
                                            key={`${document.title}${index}`}
                                            project={project}
                                            document={document}
                                            onChange={setSelectedFiles}
                                            selectedFiles={selectedFiles}
                                        />
                                    );
                                })}
                        </Tab>
                    );
                })}
            </Tabs>

            <Modal.Footer className="flex justify-between">
                <Button color="white" onClick={onClose}>
                    {translate('Cancel')}
                </Button>

                <Button color="blue" onClick={addFiles}>
                    {translate('Add files')}
                </Button>
            </Modal.Footer>
        </form>
    );
};
