import {User} from "../../../_common/schema";

const extractWildcards = (permission: string) => {
    let arrayOfPermissions: string[] = [];

    const dividedPermissions = permission.split('.');

    dividedPermissions.forEach((val, index) => {
        let value;
        if (!arrayOfPermissions[index - 1]) {
            value = `${val}.*`;
        } else {
            if (index !== dividedPermissions.length - 1) {
                value = `${arrayOfPermissions[index - 1].substring(
                    0,
                    arrayOfPermissions[index - 1].length - 2,
                )}.${val}.*`;
            } else {
                value = `${arrayOfPermissions[index - 1].substring(
                    0,
                    arrayOfPermissions[index - 1].length - 2,
                )}.${val}`;
            }
        }
        arrayOfPermissions.push(value);
    });

    return arrayOfPermissions;
};

export const permissionRequest = (can: any, permission: string): boolean => {
    let permissions: string[] = extractWildcards(permission);
    // if(permission === 'project.widget.private_conversation.view') console.log('extractWildcards', permission, can, permissions);

    if (typeof can[permission] !== 'undefined') {
        // if(permission === 'project.widget.private_conversation.view')     console.log('exact', can[permission]);
        return can[permission];
    }

    permissions = permissions.sort((a: any, b: any) => {
        let aCount = (a.split(".").length - 1);
        let bCount = (b.split(".").length - 1);

        if (aCount === bCount) {
            return 0;
        }

        return aCount < bCount ? 1 : -1;
    });
    // if(permission === 'project.widget.private_conversation.view') console.log('sorted permissions', permissions);

    for (let p of permissions) {
        if (typeof can[p] !== 'undefined') {
            // if(permission === 'project.widget.private_conversation.view') console.log('in permissions', p, can[p]);
            return can[p];
        }
    }

    let has = permissions.some((val) => Object.keys(can).includes(val));
    // if(permission === 'project.widget.private_conversation.view') console.log('general', Object.keys(can), has);
    return has;
};

export const userPermissionRequest = (user: User, permission: string): boolean => {
    return permissionRequest(user.permissions, permission);
};