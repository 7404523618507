import * as React from 'react';

import {Dropdown} from 'components/Dropdown';
import {http} from 'utils/http';

const GcmUserDropdown = (props: any) => {
    const api = 'api/users';
    const apiParams = {
        'group': 'gcm'
    };

    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        http
            .get<any>(api, {
                ...apiParams,
                dropdown: '1',
            })
            .then((response) => {
                setOptions(
                    response.data.map((item: any) => ({
                        label: item.email,
                        value: item.email,
                    })),
                );
            });
        // eslint-disable-next-line
    }, []);

    return (
        <Dropdown
            {...props}
            options={options}
            autocomplete={true}
        />
    )
};

export default GcmUserDropdown;
