import {FilterTemplate, ReportTab} from '@contractool/schema';
import {createContext} from 'react';

export const Context = createContext<{
    filterTemplates: FilterTemplate[],
    groupedFilterTemplates: {
        [key: string]: any
    },
    tabs: ReportTab[],
    createTab: (payload: ReportTab) => Promise<ReportTab>,
    updateTab: (idx: number, payload: ReportTab) => Promise<ReportTab>,
    removeTab: (idx: number) => Promise<ReportTab>,
    orderTabs: (order: number[]) => Promise<ReportTab[]>,
    refreshTabs: () => void,
}>({
    filterTemplates: null as unknown as FilterTemplate[],
    groupedFilterTemplates: null as unknown as any,
    tabs: null as unknown as any,
    createTab: () => Promise.resolve(null as unknown as ReportTab),
    updateTab: () => Promise.resolve(null as unknown as ReportTab),
    removeTab: () => Promise.resolve(null as unknown as ReportTab),
    orderTabs: () => Promise.resolve(null as unknown as ReportTab[]),
    refreshTabs: () => {},
});
