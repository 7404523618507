import {createContext} from 'react';
import {Project} from '@contractool/schema';

const ProjectContext = createContext<{
    resource: Project,
    refresh: () => void// todo: remove refresh, add CRUD here
}>({
    resource: null as unknown as Project,
    refresh: () => {}
});

export default ProjectContext;
