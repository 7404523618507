import {FilterTemplate} from '@contractool/schema';
import React, {useContext, useState} from 'react';

import Search from 'components/Search';
import {
    MultiselectDropdown,
    RenderMultiselectProps,
    SelectedItems,
} from 'components/MultiselectDropdown';
import FetchMultiSelect from 'components/select/FetchMultiSelect';
import {Option} from 'components/Dropdown';
import {Icon} from 'components/Icon';
import {Link, useLocation} from 'react-router-dom';
import {GuardContext, TranslationContext} from 'contexts';
import {useRequest} from 'hooks/useRequest';

import {Cruncher} from './Cruncher';
import {EventEmitter} from 'utils/eventEmitter';

export function ProjectsFilters({
    params,
    setParams,
    setParam,
    selected,
}: {
    params: any;
    setParams: any;
    setParam: any;
    selected: any;
}) {
    const basic = [
        'workflows',
        'state',
        'category_id',
        'fields->country',
        'supplier_id',
        'teamMemberships(user_id)[role=assignee]',
    ];
    const [templates] = useRequest<FilterTemplate[]>('/api/project-filter-templates', []);
    const [phrase, setPhrase] = useState('');

    const {user} = useContext(GuardContext);
    const {translate} = useContext(TranslationContext);
    const location = useLocation();

    const states = templates.find((item: FilterTemplate) => item.name === 'state');
    const categories = templates.find((item: FilterTemplate) => item.name === 'category_id');
    const assignees = templates.find(
        (item: FilterTemplate) => item.name === 'teamMemberships(user_id)[role=assignee]',
    );
    const countries = templates.find((item: FilterTemplate) => item.name === 'fields->country');
    const suppliers = templates.find((item: FilterTemplate) => item.name === 'supplier_id');

    const castValuesNumber = (values: any[]) => {
        return values.map((v) => Number(v));
    };

    return (
        <div className="mb-8">
            <div className="flex w-full">
                <div className="flex-1">
                    <Search
                        value={params.phrase}
                        onChange={(phrase) => {
                            setPhrase(phrase);
                            location.search === ''
                                ? setParams({phrase, page: 1, team: {assignee: [user.id]}})
                                : setParams({phrase, page: 1});
                        }}
                        onClear={() => setParam('phrase', '')}
                        placeholder={`${translate('Search Projects')}...`}
                    />
                </div>
                <Cruncher phrase={phrase} />
            </div>
            <div className="flex justify-between">
                <div className="flex space-x-10 mt-4">
                    {categories && categories.panel && (
                        <MultiselectDropdown
                            values={castValuesNumber(params.categories)}
                            name="category"
                            options={categories.values as Option<number>[]}
                            placeholder={translate('Category')}
                            onChange={(res) => {
                                setParam(
                                    'categories',
                                    res.map((item) => item.value),
                                );
                                EventEmitter.dispatch('search-filter', {
                                    categories: res.map((val) => val.label),
                                });
                            }}
                            useSearch={true}
                            searchPlaceholder={`${translate('Search categories')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {states && states.panel && (
                        <MultiselectDropdown
                            values={params.states}
                            name="state"
                            options={states.values}
                            placeholder={translate('State')}
                            onChange={(res) => {
                                location.search === ''
                                    ? setParams({
                                          states: res.map((item) => item.value),
                                          team: {assignee: [user.id]},
                                      })
                                    : setParam(
                                          'states',
                                          res.map((item) => item.value),
                                      );
                                EventEmitter.dispatch('search-filter', {
                                    states: res.map((val) => val.label),
                                });
                            }}
                            useSearch={true}
                            searchPlaceholder={`${translate('Search states')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {countries && countries.panel && (
                        <MultiselectDropdown
                            values={params.fields.country}
                            name="country"
                            options={countries.values as Option<string>[]}
                            placeholder={translate('Country')}
                            onChange={(res) => {
                                location.search === ''
                                    ? setParams({
                                          fields: {country: res.map((item) => item.value)},
                                          team: {assignee: [user.id]},
                                      })
                                    : setParam('fields', {
                                          country: res.map((item) => item.value),
                                      });
                            }}
                            useSearch={true}
                            searchPlaceholder={`${translate('Search countries')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {assignees && (
                        <MultiselectDropdown
                            values={castValuesNumber(
                                params.team?.assignee ? params.team.assignee : [],
                            )}
                            name="assignee"
                            options={assignees.values as Option<number>[]}
                            placeholder={translate('Assignee')}
                            onChange={(res) => {
                                if (selected === 'assignedToYou') {
                                    setParam('team', {
                                        assignee: user?.id
                                            ? [
                                                  user?.id,
                                                  ...res
                                                      .filter((item) => item.value !== user.id)
                                                      .map((item) => item.value),
                                              ]
                                            : [...res.map((item) => item.value)],
                                    });
                                    EventEmitter.dispatch('search-filter', {
                                        assignee: user?.id
                                            ? [
                                                  ...res
                                                      .filter((item) => item.value !== user.id)
                                                      .map((item) => item.label),
                                              ]
                                            : [...res.map((item) => item.label)],
                                    });
                                } else {
                                    setParam('team', {
                                        assignee: res.map((item) => item.value),
                                    });
                                    EventEmitter.dispatch('search-filter', {
                                        assignee: res.map((val) => val.label),
                                    });
                                }
                            }}
                            useSearch={true}
                            searchPlaceholder={`${translate('Search assignees')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {suppliers && (
                        <FetchMultiSelect
                            api="/api/suppliers"
                            values={castValuesNumber(params.suppliers)}
                            name="supplier"
                            placeholder={translate('Supplier')}
                            onChange={(res: any) => {
                                location.search === ''
                                    ? setParams({
                                          suppliers: res.map((item: any) => item.value),
                                          team: {assignee: [user.id]},
                                      })
                                    : setParam(
                                          'suppliers',
                                          res.map((item: any) => item.value),
                                      );
                                EventEmitter.dispatch('search-filter', {
                                    suppliers: res.map((val: any) => val.label),
                                });
                            }}
                            searchPlaceholder={`${translate('Search suppliers')}...`}
                            renderInput={RenderMultiselect}
                        />
                    )}
                    {templates
                        .filter((f) => f.panel && basic.indexOf(f.name) === -1)
                        .map((filter: FilterTemplate) => {
                            return (
                                <MultiselectDropdown
                                    key={`filter-panel-${filter.name}`}
                                    values={params[filter.name] ? params[filter.name] : []}
                                    name="supplier"
                                    options={filter.values}
                                    placeholder={filter.label}
                                    onChange={(res) => {
                                        location.search === ''
                                            ? setParams({
                                                  [filter.name]: res.map((item: any) => item.value),
                                                  team: {assignee: [user.id]},
                                              })
                                            : setParam(
                                                  filter.name,
                                                  res.map((item) => item.value),
                                              );
                                    }}
                                    useSearch={true}
                                    searchPlaceholder={`${translate('Search')}...`}
                                    renderInput={RenderMultiselect}
                                />
                            );
                        })}
                </div>
                <div className="justify-self-end mt-4">
                    <Link
                        to="/projects/filters/new"
                        className="flex items-center text-gray-600"
                    >
                        <Icon name="tune" size={5} className="mr-3"/>
                        {translate('Advanced filters')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

function RenderMultiselect<T>({
    name,
    selectedItems,
    hasError,
    placeholder,
    isOpen,
    getToggleButtonProps,
    shownItemSize,
}: RenderMultiselectProps<T>) {
    return (
        <div
            className={`multiselect-${name} flex items-center justify-between text-gray-500 cursor-pointer focus:outline-none ${
                selectedItems.length && 'text-blue-500'
            } ${hasError && 'border-red-700'}`}
            {...getToggleButtonProps()}
        >
            <div className="mr-3">
                {!isOpen && selectedItems.length ? (
                    <SelectedItems selectedItems={selectedItems} shownSize={shownItemSize} />
                ) : (
                    placeholder
                )}
            </div>
            <Icon
                name="triangle"
                className={`inline-block align-middle text-gray-600 ${isOpen ? '' : 'rotate-180'}`}
                size={2}
            />
        </div>
    );
}
