import React, {ReactNode, FC, useState, useContext} from 'react';
import {Button} from './Button';
import {Modal} from './Modal';
import {Message} from './Message';
import {TranslationContext} from 'contexts';

export const Confirmation: FC<{
    onConfirm: () => void;
    trigger: (params: {onClick: () => void}) => void;
    children: ReactNode;
    heading: string;
    buttonText: string;
    color: 'yellow' | 'red';
}> = ({onConfirm, trigger, children, heading, color, buttonText}) => {
    const [show, setShow] = useState(false);
    const {translate} = useContext(TranslationContext);

    return (
        <>
            {trigger({onClick: () => setShow(true)})}

            <Modal isOpen={show} onClose={() => {}} heading={heading} size="small">
                {color === 'yellow' && <Message.Warning>{children}</Message.Warning>}
                {color === 'red' && <Message.Error>{children}</Message.Error>}

                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={() => setShow(false)}>
                        {translate('Cancel')}
                    </Button>

                    <Button
                        color={color}
                        onClick={() => {
                            setShow(false);
                            onConfirm();
                        }}
                    >
                        {buttonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
