import React, {FC, useContext} from 'react';
import {Project} from '@contractool/schema';
import {SidePanel} from './ProjectDetail';
import {http} from '../../utils/http';
import {Icon, Icons} from '../../components/Icon';
import {Link} from 'react-router-dom';
import {Route, Switch} from 'react-router';
import {AddFiles} from './AddFiles';
import {sign} from '../../utils/auth';
import {AppContext, TranslationContext} from 'contexts';
import {Document as AttachmentDocument} from '../../../../_common/schema/Document';
import {useToasts} from '../../components/Toast';
import {permissionRequest} from '../../utils/wildcard';
import {Confirmation} from 'components/Confirmation';

export const AttachmentsPanel: FC<{
    heading: string;
    fileAddLabel: string;
    type?: string | null;
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}> = ({heading, fileAddLabel, type = null, project, onUpdate, onClose}) => {
    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);

    const refresh = () => {
        onClose();
        onUpdate();
    };

    if (!config.config) {
        return null;
    }

    let permissions = project.can;

    return (
        <SidePanel
            heading={heading}
            right={
                <div className="flex">
                    {config.config.office365 && (
                        <a
                            href={project.new_document_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center text-blue-700 mr-4"
                        >
                            <Icon name="word" size={5} />
                            <span className="ml-2">{translate('Create online')}</span>
                        </a>
                    )}
                    {permissionRequest(permissions, 'project.widget.documents.edit') && (
                        <Link
                            to={`/projects/${project.id}/documents/browse/${type || ''}`}
                            className="flex items-center text-blue-700"
                        >
                            <Icon name="add" size={5} />
                            <span className="ml-2">{fileAddLabel}</span>
                        </Link>
                    )}
                </div>
            }
        >
            <ContractList type={type} project={project} onUpdate={onUpdate} />

            {permissionRequest(permissions, 'project.widget.documents.edit') && (
                <Switch>
                    <Route exact path={`/projects/${project.id}/documents/browse/${type || ''}`}>
                        <AddFiles
                            type={type}
                            onUpdate={refresh}
                            onClose={onClose}
                            project={project}
                        />
                    </Route>
                </Switch>
            )}
        </SidePanel>
    );
};

const ContractList: FC<{project: Project; onUpdate: () => void; type: string | null}> = ({
    project,
    onUpdate,
    type,
}) => {
    const {success, error} = useToasts();
    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);

    if (project.attachments_url.length === 0) return null;
    let permissions = project.can;
    return (
        <>
            {project.attachments
                .filter((value) => project.document_id !== value.id)
                .filter((value) => value.type === type)
                .map((value, index) => {
                    let icon: Icons;

                    switch (value.icon) {
                        case 'file-alt':
                        case 'file-archive':
                        case 'file-audio':
                        case 'file-excel':
                        case 'file-image':
                        case 'file-pdf':
                        case 'file-powerpoint':
                        case 'file-video':
                        case 'file-word':
                            icon = value.icon;
                            break;
                        default:
                            icon = 'description';
                    }
                    const deleteAttachment = (document: AttachmentDocument) => {
                        http.delete(document.url)
                            .then(() => {
                                onUpdate();
                                success(
                                    `${translate(
                                            'Document :file was successfully removed from project',
                                            {file: document.title}
                                        )}.`,
                                );
                            })
                            .catch((_err) => {
                                error(`${translate('Error with removing document from project')}.`);
                            });
                    };
                    return (
                        <div className="border-b border-gray-100 py-7 flex" key={index}>
                            <Link
                                to={`/projects/${project.id}/documents/${value.id}`}
                                className="flex"
                            >
                                <Icon name={icon} size={6} />
                                <div className="leading-tighter pl-4 text-gray-700">
                                    {value.title}
                                </div>
                            </Link>
                            <div className="flex-grow flex justify-end">
                                <a
                                    href={sign(value.preview_url)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon
                                        name="remove_red_eye"
                                        size={6}
                                        className="ml-3 text-gray-500"
                                    />
                                </a>
                                {config.config.office365 &&
                                    permissionRequest(
                                        permissions,
                                        'project.widget.documents.edit',
                                    ) && (
                                        <a
                                            href={sign(value.edit_url)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon
                                                name="edit"
                                                size={6}
                                                className="ml-3 text-gray-500"
                                            />
                                        </a>
                                    )}
                                <a href={sign(value.download_url)} download>
                                    <Icon name="get_app" size={6} className="ml-3 text-gray-500" />
                                </a>
                                <Confirmation
                                    onConfirm={() => {
                                        deleteAttachment(value);
                                    }}
                                    trigger={({onClick}) => (
                                        <span
                                            onClick={onClick}
                                            className="cursor-pointer focus:outline-none"
                                        >
                                            <Icon
                                                name="delete"
                                                size={6}
                                                className="ml-3 text-gray-500"
                                            />
                                        </span>
                                    )}
                                    heading={translate('Remove an attachemnt')}
                                    buttonText={translate('Yes, remove')}
                                    color="yellow"
                                >
                                    {translate('Are you sure you want to remove an attachment?')}
                                </Confirmation>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};
