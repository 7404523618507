import {FieldTemplate, Project, Role} from '@contractool/schema';
import React, {FC, useContext, useState} from 'react';

import {AppContext} from 'contexts';
import {Form as CommonForm} from 'components/Form';
import {CustomField} from 'components';
import SupplierSelect from 'components/form/SupplierSelect';
import {TeamRoleSelect} from 'utils/form';
import ProjectFormContext from 'views/project/ProjectFormContext';
import {ProjectTitle} from './components';

const Form: FC<{project: Project}> = ({project}) => {
    const {create} = React.useContext(ProjectFormContext);
    const {config} = useContext(AppContext);
    const [businessSegment, setBusinessSegment] = useState<string>(
        project.fields.business_segment + '',
    );
    const [lob, setLob] = useState<string>(project.fields.lob + '');

    const lobs = {
        csg: ['Consumer', 'Commercial'],
        isg: [
            'Dell Technologies Cloud',
            'Integrated Products and Solutions',
            'OCTO',
            'SIS',
            'Storage',
            'Telco',
        ],
    };

    const productGroups = {
        Consumer: ['Alienware', 'Inspiron', 'Innovation POC', 'Vostro', 'XPS', 'XLOB'],
        Commercial: ['Innovation POC', 'Latitude', 'Precision', 'Optiplex', 'XLOB'],
        Dell: ['DTCP', 'DTCP Other'],
        Integrated: ['CTO', 'HPC', 'Networking', 'Solutions', 'VxRail'],
        OCTO: ['CTO'],
        SIS: ['CET', 'CTO', 'OEM', 'POC', 'PowerEdge', 'XE'],
        Storage: ['Mid Range', 'PowerMax', 'VxFlex'],
        Telco: ['Edge', 'Telco'],
    };

    const setupProductGroupOptions = (v: string) => {
        switch (v) {
            case 'Consumer':
                return productGroups.Consumer;
            case 'Commercial':
                return productGroups.Commercial;
            case 'Dell Technologies Cloud':
                return productGroups.Dell;
            case 'Integrated Products and Solutions':
                return productGroups.Integrated;
            case 'OCTO':
                return productGroups.OCTO;
            case 'SIS':
                return productGroups.SIS;
            case 'Storage':
                return productGroups.Storage;
            case 'Telco':
                return productGroups.Telco;
        }
        return [];
    };

    return (
        <>
            <ProjectTitle className="mb-8" />

            <SupplierSelect />
            {config.fields.map((field: FieldTemplate, index: number) => {
                if (field.hidden) {
                    return null;
                }
                switch (field.name) {
                    case 'date_of_removing_hold':
                    case 'date_placed_on_hold':
                    case 'date_of_cancellation':
                    case 'reason_for_hold':
                    case 'reason_for_cancel':
                    case 'dell_signed_date':
                    case 'vendor_signed_date':
                    case 'esign_approach':
                    case 'term_in_months':
                    case 'contract_amount':
                    case 'start_fiscal_year':
                    case 'start_fiscal_quarter':
                    case 'end_fiscal_year':
                    case 'end_fiscal_quarter':
                    case 'agreement_type':
                        return null;
                }

                if (field.name === 'business_segment') {
                    return (
                        <CommonForm.Dropdown
                            key={`business_segment`}
                            name={`fields.business_segment`}
                            label={field.label}
                            options={field.values}
                            className="mb-6"
                            onChange={(v: any) => {
                                setBusinessSegment(v);
                            }}
                            required={true}
                            clearDependencies={['fields.lob', 'fields.product_group']}
                        />
                    );
                }

                if (field.name === 'lob') {
                    return (
                        <Lob
                            key={field.name + index}
                            lobOptions={businessSegment === 'CSG' ? lobs.csg : lobs.isg}
                            onChange={(v: any) => setLob(v)}
                        />
                    );
                }

                if (field.name === 'product_group') {
                    return (
                        <ProductGroup
                            key={field.name + index}
                            productGroupOptions={setupProductGroupOptions(lob)}
                        />
                    );
                }
                if (field.name === 'iep' && businessSegment === 'ISG') {
                    return null;
                }
                if (field.name === 'amendment_number' && project.fields.agreement_type === 'Agreement') {
                    return null;
                }
                return (
                    <CustomField
                        key={field.name + index}
                        field={field}
                        option="fields."
                        className="mb-6"
                    />
                );
            })}
            {create &&
                config.roles.map(
                    (role: Role) =>
                        role.key !== 'admin' &&
                        role.key !== 'requestor' &&
                        role.key !== 'supplier' &&
                        role.key !== 'assignee' && (
                            <TeamRoleSelect
                                key={role.key}
                                role={role}
                                className="mb-6"
                                groups={role.groups}
                            />
                        ),
                )}
        </>
    );
};

const ProductGroup: FC<{productGroupOptions: string[]}> = ({productGroupOptions}) => {
    let options = productGroupOptions.map((item: string) => {
        return {
            value: item,
            label: item,
        };
    });
    return (
        <CommonForm.Dropdown
            key={`product_group`}
            name={`fields.product_group`}
            label={`Product group`}
            options={options}
            className="mb-6"
            required={true}
        />
    );
};

const Lob: FC<{lobOptions: string[]; onChange: (value: any) => void}> = ({
    lobOptions,
    onChange,
}) => {
    let options = lobOptions.map((item: string) => {
        return {
            value: item,
            label: item,
        };
    });
    return (
        <CommonForm.Dropdown
            key={`lob`}
            name={`fields.lob`}
            label={`LOB`}
            options={options}
            className="mb-6"
            onChange={onChange}
            clearDependencies={['fields.product_group']}
            required={true}
        />
    );
};

export default Form;
