import React, {FC} from 'react';
import {Icon, Icons} from './Icon';
import {Color, IconCircle} from './IconCircle';

export const BigRadioBox: FC<{
    icon?: Icons;
    iconColor?: string;
    iconBgColor?: string;
    title: string;
    description: string;
    span?: number;
    selected?: boolean;
    onClick: () => void;
}> = ({icon, title, description, span = 1, onClick, selected = false}) => {
    return (
        <button
            type="button"
            className={`group cursor-pointer border rounded-lg p-8 col-span-${span} flex flex-col justify-between h-full hover:text-blue-700 hover:border-blue-700 focus:outline-none focus:text-blue-700 focus:border-blue-700 ${
                selected ? 'text-blue-700 border-blue-700' : ''
            }`}
            onClick={onClick}
        >
            <div>
                {icon && (
                    <IconCircle
                        name={icon}
                        color="lightgray"
                        className={`mb-8 group-hover:text-blue-700 group-hover:bg-blue-000 group-focus:text-blue-700 group-focus:bg-blue-000 ${
                            selected ? 'text-blue-700 bg-blue-000' : ''
                        }`}
                        circleSize={16}
                        iconSize={8}
                    />
                )}
                <div className="text-xl mb-6 text-left">{title}</div>
                <div className="text-gray-500 h-30 text-left">{description}</div>
            </div>
            <Icon
                name="arrow_forward"
                size={6}
                className={`text-gray-500 group-hover:text-blue-700 group-focus:text-blue-700 ${
                    selected ? 'text-blue-700' : ''
                }`}
            />
        </button>
    );
};

export const SmallRadioBox: FC<{
    icon: Icons;
    color: Color;
    title: string;
    selected?: boolean;
    onClick: () => void;
}> = ({icon, color, title, selected = false, onClick}) => {
    return (
        <button
            type="button"
            className={`group cursor-pointer border rounded-lg p-5 flex flex justify-between items-center w-full hover:text-blue-700 hover:border-blue-700 focus:outline-none focus:text-blue-700 focus:border-blue-700 ${
                selected ? 'text-blue-700 border-blue-700' : ''
            }`}
            onClick={onClick}
        >
            <div className="flex items-center">
                {icon && (
                    <IconCircle
                        name={icon}
                        color={color}
                        className={`mr-4`}
                        circleSize={10}
                        iconSize={5}
                    />
                )}
                <div className="text-lg">{title}</div>
            </div>
            <Icon
                name="arrow_forward"
                size={6}
                className={`mr-2 text-gray-500 group-hover:text-blue-700 group-focus:text-blue-700 ${
                    selected ? 'text-blue-700' : ''
                }`}
            />
        </button>
    );
};

export const RadioBoxContainer: FC<{
    type: 'big' | 'small';
    label?: string;
    colSize?: number;
    rowSize?: number;
    className?: string;
}> = ({children, type, label, colSize, rowSize, className}) => {
    return type === 'big' ? (
        <div className={`w-full ${className}`}>
            {label && <div className="mb-6 text-xl text-gray-500">{label}</div>}
            <div className={`grid grid-cols-${colSize} gap-4`}>{children}</div>
        </div>
    ) : (
        <div className={`w-full ${className}`}>
            {label && <div className="text-gray-500 text-xl mb-8">{label}</div>}
            <div className={`grid gap-4 grid-cols-${colSize} grid-rows-${rowSize}`}>{children}</div>
        </div>
    );
};
