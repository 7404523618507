import React from 'react';

export interface ToggleType {
    on: () => void,
    off: () => void,
    invert: () => void,
}

const useToggle = (initialState = false) => {
    const [state, setState] = React.useState(initialState);

    const toggle: ToggleType = React.useMemo(
        () => (
            {
                on: () => {
                    setState(true);
                },
                off: () => {
                    setState(false);
                },
                invert: () => {
                    setState(prevState => !prevState);
                },
            }),
        []
    );

    return [state, toggle] as const;
};

export default useToggle;
