import {TranslationContext} from 'contexts';
import React, {FC, useContext} from 'react';
import {Project} from '../../../../../_common/schema';
import {Confirmation} from '../../../components/Confirmation';
import {MenuItem} from '../../../components/Menu';
import {http} from '../../../utils/http';

export const Reset: FC<{project: Project; onRefresh: () => void}> = ({project, onRefresh}) => {
    const {translate} = useContext(TranslationContext);

    return (
        <Confirmation
            onConfirm={() => {
                http.post(`api/projects/${project.id}/reset`).then((data: any) => {
                    console.log('data.data', data);
                    onRefresh();
                });
            }}
            trigger={({onClick}) => (
                <MenuItem
                    icon="arrow_back"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                    }}
                >
                    {translate('Reset')}
                </MenuItem>
            )}
            heading={`${translate('Reset')}?`}
            buttonText={translate('Yes, reset')}
            color="red"
        >
            {translate('Are you sure you want to reset this project?')}
        </Confirmation>
    );
};
