import React, {FC, useContext} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Kind} from '@contractool/schema';
import {Page} from '../../components/Page';
import {toQueryString} from '../../utils/url';
import {AppContext} from 'contexts';

export function NewProject() {
    const {config} = useContext(AppContext);

    let kinds = config.kinds;
    if (kinds.length === 1 || kinds.length === 0) {
        return <Redirect to="/projects/new/form"/>
    }
    console.log('kinds2', kinds);

    return (
        <Page heading="New Projects">
            <div className="flex justify-around align-center">
                {kinds.map((kind: Kind, index: number) => {
                    return (
                        <Card
                            key={'card' + index}
                            kind={kind}
                        />
                    );
                })}
            </div>
        </Page>
    );
}

const Card: FC<{kind: Kind}> = ({kind}) => {
    const {config} = useContext(AppContext);
    return (
        <div className="w-1/3 xl:w-1/4 mx-2 h-80 rounded border shadow">
            <div className="p-8 flex flex-col justify-between text-center h-full text-gray-800">
                <div className="text-lg font-medium">{kind.title}</div>
                {kind.description && <div className="text-gray-600">{kind.description}</div>}
                {kind.buttons.map((btn, index) => {
                    let searchParams = '';
                    if (config.integration === 'dell') {
                        searchParams =
                            kind.title.toLowerCase() === 'onboarding'
                                ? toQueryString({workflow: kind.title.toLowerCase()})
                                : toQueryString({
                                    fields: {
                                        scope: kind.title,
                                        advisory: btn.type === 'primary' ? 0 : 1,
                                    },
                                });
                    }

                    return (
                        <Link
                            key={`link${index}`}
                            className={`cursor-pointer px-4 py-3 rounded leading-tight border truncate ${
                                btn.type === 'secondary'
                                    ? `border-blue-600 bg-white hover:bg-gray-100 text-blue-600`
                                    : `border-blue-400 bg-blue-600 hover:bg-blue-700 text-white`
                                }`}
                            to={{
                                pathname: '/projects/new/form',
                                search: `?${searchParams}`,
                                state: btn.extra.workflow
                            }}
                        >
                            {btn.label}
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};
