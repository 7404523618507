import {FieldTemplate} from '@contractool/schema';
import React, {FC, useContext, useCallback} from 'react';

import {AppContext, TranslationContext} from 'contexts';
import {CustomField} from 'components';
import {Form, FormContext} from 'components/Form';
import {Icon} from 'components/Icon';
import {SupplierContactModal} from 'components/form/SupplierContactModal';
import {useToggle} from 'hooks';

import GcmUserDropdown from 'integrations/dell_swp/views/suppliers/GcmUserDropdown';

const initialContact = {
    name: '',
    email: '',
    phone: '',
    id: 0,
    supplier_id: 0,
    url: '',
};

const SupplierForm: FC<{create?: boolean}> = ({create}) => {
    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);

    const [contactModalVisible, contactModalVisController] = useToggle(false);

    const form = useContext(FormContext);

    const onSubmit = useCallback(
        (supplierContactValues) =>
            new Promise((resolve, reject) => {
                resolve({data: supplierContactValues});
                if (!supplierContactValues) {
                    reject();
                }
            }),
        [],
    );

    const onSuccess = useCallback(
        (successValues) => {
            form.addField('contacts', {
                name: successValues.name,
                email: successValues.email,
                phone: successValues.phone,
            });
            contactModalVisController.off();
        },
        [contactModalVisController, form],
    );

    return (
        <>
            <Form.TextInput
                name="title"
                label={translate('Supplier name')}
                className="mb-6"
                autoFocus={true}
            />
            <Form.TextInput name="address_street" label={translate('Address')} className="mb-6" />
            <div className="flex mb-6 -mx-2">
                <div className="w-1/2 mx-2">
                    <Form.TextInput name="address_city" label={translate('City')} />
                </div>
                <div className="w-1/2 mx-2">
                    <Form.TextInput name="address_zip" label={translate('Zip Code')} />
                </div>
            </div>
            <Form.TextInput
                name="address_state"
                label={translate('State :address', {address: ''})}
                className="mb-6"
            />
            {config.supplier_fields.map((field: FieldTemplate, index: number) => {
                return config.integration !== 'dell_swp' || field.name !== 'gcm' ? (
                    <CustomField
                        key={field.name + index}
                        field={field}
                        option="fields."
                        className="mb-6"
                    />
                ) : (
                    <Form.Field
                        key={field.name + index}
                        className="mb-6"
                        name={'fields.' + field.name}
                        label={field.label}
                        component={GcmUserDropdown}
                    />
                );
            })}
            {create && form.values.contacts?.length > 0 && (
                <div className="mb-6">
                    <div className="block leading-none select-none text-gray-600">Contacts</div>
                    <div className="mt-4 mb-2">
                        {form.values.contacts.map((_item: any, index: number) => {
                            return (
                                <div
                                    className="flex items-center justify-between mt-2"
                                    key={`contacts${index}`}
                                >
                                    <Form.TextInput name={`contacts|${index}|name`} label="Name" />
                                    <Form.TextInput
                                        name={`contacts|${index}|email`}
                                        label={translate('Email')}
                                        type="email"
                                    />
                                    <Form.TextInput
                                        name={`contacts|${index}|phone`}
                                        label={translate('Phone')}
                                        type="tel"
                                    />
                                    <span onClick={() => form.removeField('contacts', index)}>
                                        <Icon name="delete" size={6} />
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {create && (
                <div
                    className="cursor-pointer flex items-center mb-6"
                    onClick={contactModalVisController.on}
                >
                    <Icon name="add" size={5} />
                    {translate('Add new contact')}
                </div>
            )}
            {create && (
                <SupplierContactModal
                    label={translate('Add contact')}
                    isOpen={contactModalVisible}
                    contact={initialContact}
                    onSubmit={onSubmit}
                    onCancel={contactModalVisController.off}
                    onSuccess={onSuccess}
                />
            )}
        </>
    );
};

export default SupplierForm;
