import {FieldTemplate, Report, User} from '@contractool/schema';
import React, {FC, useState} from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import {useRequest} from 'hooks/useRequest';
import {EventEmitter} from 'utils/eventEmitter';
import {http} from 'utils/http';
import {ReportFilter} from '../ReportFilter';
import {Option} from 'components/Dropdown';
import ReportFilterHOC from '../ReportFilterHOC';

export const ProjectsPerPerson: FC<{
    report: Report;
    selectedFilters: any;
    setSelectedFilters: any;
    handleFilterUpdate: (customFilter: any) => void;
    filterSettings: FieldTemplate[];
    usersAsOptions: Option<any>[];
    suppliersAsOptions: Option<any>[];
}> = ({
    report,
    selectedFilters,
    setSelectedFilters,
    handleFilterUpdate,
    filterSettings,
    usersAsOptions,
    suppliersAsOptions,
}) => {
    const [subscribed, setSubscribed] = useState(false);
    const [data] = useRequest<any>(`/api/reports/${report.slug}`, undefined, {
        params: selectedFilters,
    });
    if (!data) {
        return null;
    }

    if (!subscribed) {
        EventEmitter.subscribe('report-date-filter', (filter: any) => {
            setSelectedFilters(filter);
        });

        setSubscribed(true);
    }

    data.plotOptions.series.events = {
        click: async function (event: any) {
            let userData = await http.get<User>('/api/users/find', {name: event.point.category});

            let state = '';
            switch (this.name) {
                case 'JI Proposal':
                    state = 'proposal';
                    break;
                case 'Internal Alignment':
                    state = 'alignment';
                    break;
                case 'Negotiation':
                    state = 'negotiation';
                    break;
                case 'Field Team':
                    state = 'field';
                    break;
                case 'Director Review':
                    state = 'director';
                    break;
                case 'Internal Review':
                    state = 'internal';
                    break;
                case 'Vendor Signed':
                    state = 'vendor';
                    break;
                case 'Executed':
                    state = 'executed';
                    break;
            }
            EventEmitter.dispatch('report-projects', {
                ...handleFilterUpdate,
                report: {
                    slug: report.slug,
                },
                state,
                userId: userData.data.id,
            });
        },
    };

    data.plotOptions.bar = {
        pointWidth: 8,
    };

    return (
        <div className="rounded-xl bg-white mb-3">
            <div className="flex justify-between">
                <h1 className="p-5 border-b border-gray-100">{report.title}</h1>
                <ReportFilter
                    selectedFilters={selectedFilters}
                    filterSettings={filterSettings}
                    reportSlug={report.slug}
                    onFilter={setSelectedFilters}
                    usersAsOptions={usersAsOptions}
                    suppliersAsOptions={suppliersAsOptions}
                />
            </div>
            <div className="p-5 ">
                <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={data} />
            </div>
        </div>
    );
};

const EnhancedProjectsPerPerson = ReportFilterHOC(ProjectsPerPerson);

export default EnhancedProjectsPerPerson;
