import React, {FC, useContext} from 'react';
import {Route, Switch} from 'react-router';
import {Project} from '@contractool/schema';
import {Icon} from '../../components/Icon';
import {Tabs, Tab} from '../../components/Tabs';
import {sign} from '../../utils/auth';
import {Link, useParams} from 'react-router-dom';
import {Modal} from '../../components/Modal';
import {Form} from '../../components/Form';
import {http} from '../../utils/http';
import {Button} from '../../components/Button';
import {SidePanel} from './ProjectDetail';
import {TaskNew} from './TaskNew';
import {TaskDetail} from './TaskDetail';
import {TaskEdit} from './TaskEdit';
import {format, parseISO} from 'date-fns';
import {useToasts} from '../../components/Toast';
import {TranslationContext} from 'contexts';

export const TaskPanel: FC<{project: Project; onUpdate: () => void; onClose: () => void}> = ({
    project,
    onUpdate,
    onClose,
}) => {
    const {translate} = useContext(TranslationContext);

    return (
        <SidePanel
            heading={translate('Tasks')}
            right={
                <Link
                    to={`/projects/${project.id}/tasks/new`}
                    className="flex items-center text-blue-700"
                >
                    <Icon name="add" size={5} />
                    <span className="ml-2">{translate('Create new')}</span>
                </Link>
            }
        >
            {project.tasks.length ? (
                <Tabs>
                    <Tab name="open" heading={translate('Open')} className="pt-4">
                        <TaskTab project={project} state="OPEN" />
                    </Tab>
                    <Tab name="completed" heading={translate('Completed')} className="pt-4">
                        <TaskTab project={project} state="RESOLVED" />
                    </Tab>
                </Tabs>
            ) : (
                <div className="py-4">
                    <div className="w-12 h-12 flex items-center justify-center border border-gray-000 text-gray-600 bg-gray-000 rounded-full m-auto">
                        <Icon name="list" size={5} />
                    </div>

                    <div className="text-gray-600 text-center pt-6">{`${translate(
                        'There are no tasks yet',
                    )}...`}</div>
                </div>
            )}
            <Switch>
                <Route path={`/projects/${project.id}/tasks/new`}>
                    <TaskNew onCreate={onUpdate} project={project} />
                </Route>

                <Route exact path={`/projects/${project.id}/tasks/:taskId/resolve`}>
                    <ResolveTaskModal
                        onCancel={onClose}
                        onSuccess={() => {
                            onUpdate();
                            onClose();
                        }}
                        project={project}
                    />
                </Route>
                <Route exact path={`/projects/${project.id}/tasks/:taskId`}>
                    <TaskDetail onClose={onClose} project={project} />
                </Route>
                <Route exact path={`/projects/${project.id}/tasks/:taskId/edit`}>
                    <TaskEdit
                        onClose={onClose}
                        onUpdate={() => {
                            onUpdate();
                            onClose();
                        }}
                        project={project}
                    />
                </Route>
            </Switch>
        </SidePanel>
    );
};

const TaskTab: FC<{
    project: Project;
    state: 'OPEN' | 'RESOLVED';
}> = ({project, state}) => {
    let tasks = project.tasks.filter((task) => task.state === state);
    const {translate} = useContext(TranslationContext);

    if (tasks.length === 0) {
        return (
            <div className="pt-6 pb-4">
                <div className="w-12 h-12 flex items-center justify-center border border-gray-000 text-gray-600 bg-gray-000 rounded-full m-auto">
                    <Icon name="list" size={5} />
                </div>

                <div className="text-gray-600 text-center pt-6">
                    {`${
                        (translate('There are no :state tasks'),
                        {
                            state: state.toLowerCase(),
                        })
                    }...`}
                </div>
            </div>
        );
    }

    return (
        <table className="w-full">
            <tbody className="">
                {tasks.map((task) => (
                    <tr key={task.id}>
                        <td className="w-14 py-4 pr-4">
                            <Link to={`/projects/${project.id}/tasks/${task.id}/resolve`}>
                                {task.state === 'OPEN' ? (
                                    <div
                                        className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 bg-white rounded-full"
                                        title={translate('Complete task')}
                                    >
                                        <Icon name="done" size={5} />
                                    </div>
                                ) : (
                                    <div
                                        className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full"
                                        title={translate('Re-open task')}
                                    >
                                        <Icon name="done" size={5} />
                                    </div>
                                )}
                            </Link>
                        </td>
                        <td className="py-4">
                            <Link to={`/projects/${project.id}/tasks/${task.id}`}>
                                {task.title}
                            </Link>
                        </td>
                        <td className="w-31 py-4 pl-10 pr-13">
                            <img
                                src={sign(task.assignee.avatar_url)}
                                alt={task.assignee.name}
                                className="w-8 h-8 rounded-full"
                            />
                        </td>
                        <td className="w-24 py-4">
                            <Icon
                                name="date_range"
                                size={6}
                                className="mr-3 inline text-gray-500"
                            />
                            <span className="w-15 text-gray-600">
                                {formatDeadline(task.deadline)}
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const ResolveTaskModal: FC<{
    project: Project;
    onCancel: () => void;
    onSuccess: () => void;
}> = ({project, onSuccess, onCancel}) => {
    const {taskId} = useParams();
    const task = project.tasks.find((t) => taskId && t.id === parseInt(taskId));
    const {translate} = useContext(TranslationContext);

    const {error} = useToasts();
    if (!task) return null;
    const isResolved = task.state === 'RESOLVED';
    return (
        <Modal
            heading={isResolved ? translate('Re-open task') : translate('Complete task')}
            size="small"
            onClose={onCancel}
        >
            <Form
                initialValues={{state: isResolved ? 'OPEN' : 'RESOLVED', comment: task.comment}}
                onSubmit={(values) => http.put(task.state_url, values)}
                onSuccess={onSuccess}
                onError={(errors: any) => {
                    error(translate(errors.state[0]));
                }}
                allowPristineSubmission={true}
            >
                <p className="pb-12">
                    {translate('Are you sure you want to :action following task?', {
                        action: isResolved ? translate('re-open') : translate('complete'),
                    })}
                    {translate('It will disappear from')}
                    <span className="italic capitalize"> {task.state.toLowerCase()} </span>
                    {`${translate('tasks and move to')} `}
                    <span className="italic">
                        {isResolved ? translate('Open') : translate('Completed')}
                    </span>
                    .
                </p>
                <div className="w-full bg-gray-000 p-4 items-center justify-center flex">
                    <div className="flex-initial">
                        {task.state === 'OPEN' ? (
                            <div className="w-10 h-10 flex items-center justify-center border border-gray-200 text-gray-500 rounded-full">
                                <Icon name="done" size={5} />
                            </div>
                        ) : (
                            <div className="w-10 h-10 flex items-center justify-center border border-teal-000 text-teal-600 bg-teal-000 rounded-full">
                                <Icon name="done" size={5} />
                            </div>
                        )}
                    </div>
                    <div className="flex-1 ml-4">{task.title}</div>
                    <div className="flex-none ml-4 mr-15">
                        <div className="flex items-center justify-center">
                            <img
                                src={sign(task.assignee.avatar_url)}
                                alt={task.assignee.name}
                                className="w-8 h-8 rounded-full"
                            />
                        </div>
                    </div>
                    <div className="flex-initial text-gray-600 whitespace-no-wrap flex items-center">
                        <Icon name="date_range" size={6} className="text-gray-600 inline mr-3" />
                        {formatDeadline(task.deadline)}
                    </div>
                </div>
                <Form.TextInput
                    name="comment"
                    className="w-full mt-8"
                    label="Note"
                    placeholder="Add comment here..."
                />
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={onCancel}>
                        {translate('Cancel')}
                    </Button>

                    <Form.Submit>
                        {translate('Yes, :value', {
                            value: isResolved ? translate('re-open') : translate('complete'),
                        })}
                    </Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export const formatDeadline = (date: string) => {
    return format(parseISO(date), 'dd MMM');
};
