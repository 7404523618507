import React, {useContext, useState} from 'react';
import {Switch, Route, useHistory} from 'react-router';
import {Page} from '../../components/Page';
import {DummyList} from '../../components/DummyList';
import {Icon} from '../../components/Icon';
import {Button} from '../../components/Button';
import {User, UserGroup} from '@contractool/schema';
import {Link} from 'react-router-dom';
import {TeamNew} from './TeamNew';
import {TeamEdit} from './TeamEdit';
import {useQueryParams} from '../../utils/hooks/useParams';
import {useRequest} from '../../utils/hooks/useRequest';
import Search from '../../components/Search';
import {Pagination} from '../../components/Pagination';
import {UserStateLabel} from './UserStateLabel';
import {Avatar} from '../../components/Avatar';
import {Dropdown} from '../../components/Dropdown';
import {AppContext, TranslationContext} from 'contexts';
import {Licence} from '../../../../_common/schema/Licence';
import {MultiselectDropdown} from '../../components/MultiselectDropdown';

export function TeamPage() {
    const [loaded, setLoaded] = useState(false);
    const [params, {setParam, setParams}] = useQueryParams<{
        phrase: string;
        or_email: string;
        licence: string;
        page: number;
        per_page: number;
        groups_in: string[];
    }>({
        phrase: '',
        or_email: '',
        licence: '',
        page: 1,
        per_page: 30,
        groups_in: [],
    });

    const [users, {refresh, pagination}] = useRequest<User[]>(
        '/api/users',
        [],
        {
            params,
        },
        setLoaded,
    );
    const {config} = useContext(AppContext);
    const {translate} = useContext(TranslationContext);

    const filterLicences = (licenceKey: string) => {
        setParam('licence', licenceKey);
    };
    const filterGroups = (groups: string[]) => {
        setParam('groups_in', groups);
    };

    const licencesOptions = config.licences.map((licence: Licence) => {
        return {
            label: licence.label,
            value: licence.key,
        };
    });
    const groupOptions = config.user_groups.map((group: UserGroup) => {
        return {
            label: group.label,
            value: group.key,
        };
    });

    licencesOptions.unshift({
        label: 'All',
        value: '',
    });

    return (
        <Page
            heading="Team"
            right={
                <Button.Link to="/team/new" color="blue" icon="add">
                    {translate('Add user')}
                </Button.Link>
            }
        >
            <div className="mb-8">
                <Search
                    value={params.phrase}
                    onChange={(phrase) => {
                        setParams({phrase, or_email: phrase, page: 1});
                    }}
                    onClear={() => setParam('phrase', '')}
                    placeholder={`${translate('Search people')}...`}
                />
            </div>
            <div className="flex">
                <div className={`w-25`}>
                    <Dropdown
                        name="licence"
                        value={params.licence}
                        onChange={filterLicences}
                        options={licencesOptions}
                        placeholder={translate('Licence')}
                    />
                </div>
                <div
                    className={`w-${
                        params.groups_in.length ? (params.groups_in.length > 2 ? 130 : 110) : 50
                    }`}
                >
                    <MultiselectDropdown
                        values={params.groups_in}
                        name="user_groups"
                        onChange={(groups: any) => {
                            filterGroups(groups.map((g: any) => g.value));
                        }}
                        options={groupOptions}
                        placeholder={translate(`User groups`)}
                    />
                </div>
            </div>
            <div className="-mx-6">
                <UsersList users={users} loaded={loaded} />
            </div>

            <div className="flex justify-center pt-16">
                <Pagination
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    totalBlocks={7}
                    onSelect={(page) => setParam('page', page)}
                />
            </div>

            <Switch>
                <Route path="/team/new">
                    <TeamNew onCreate={() => refresh()} />
                </Route>

                <Route path="/team/:id/edit">
                    <TeamEdit onUpdate={() => refresh()} />
                </Route>
            </Switch>
        </Page>
    );
}

function UsersList({users, loaded}: {users: User[]; loaded: boolean}) {
    const GROUP_GROUPS_OVER = 3;
    const history = useHistory();
    const {translate} = useContext(TranslationContext);

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th className="w-12">{translate('Avatar')}</th>
                        <th>{translate('Name')}</th>
                        <th>{translate('Email')}</th>
                        <th>{translate('Group')}</th>
                        <th>{translate('Licence')}</th>
                        <th className="w-16"></th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <DummyList rowsCount={10} colsCount={4} />
                    ) : users.length === 0 ? (
                        <tr>
                            <td colSpan={5} className="text-center">
                                {translate('No users found')}
                            </td>
                        </tr>
                    ) : (
                        users.map((user) => (
                            <tr
                                key={`users-list-${user.id}`}
                                className="hover:bg-gray-000 cursor-pointer rounded"
                                onClick={() =>
                                    history.push({
                                        pathname: `/team/${user.id}/edit`,
                                        state: {
                                            title: translate('Edit user :name', {name: user.name}),
                                        },
                                    })
                                }
                            >
                                <td className="w-12">
                                    <Avatar user={user} className="w-12 h-12" />
                                </td>
                                <td>
                                    <div className="flex items-center">
                                        <div
                                            className={
                                                `${user.state}` === 'deactivated'
                                                    ? 'text-gray-600'
                                                    : ''
                                            }
                                        >
                                            {user.name}
                                        </div>
                                        <UserStateLabel className="ml-4" user={user} />
                                    </div>
                                </td>
                                <td className="text-gray-600">{user.email}</td>
                                <td className="flex flex-cols flex-wrap">
                                    {user.groups.slice(0, GROUP_GROUPS_OVER).map((group) => (
                                        <div
                                            key={`role-${group.key}`}
                                            className={`px-3 py-2 rounded-full text-sm leading-none select-none ${groupColor(
                                                group,
                                            )} inline m-2`}
                                        >
                                            {group.label}
                                        </div>
                                    ))}

                                    {user.groups.length > GROUP_GROUPS_OVER && (
                                        <div className="py-2 flex items-center text-sm text-gray-600 select-none">
                                            + {user.groups.length - GROUP_GROUPS_OVER}{' '}
                                            {translate('more')}
                                        </div>
                                    )}
                                </td>
                                <td className="text-gray-600">{user.licence.toLowerCase()}</td>
                                <td className="w-16">
                                    <Link
                                        to={`/team/${user.id}/edit`}
                                        className="block"
                                        title={`Edit user ${user.name}`}
                                    >
                                        <Icon
                                            name="more_horiz"
                                            className="text-gray-600"
                                            size={6}
                                        />
                                    </Link>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </>
    );
}

function groupColor(group: UserGroup): string {
    const colors: any = {
        red: 'text-red-700 bg-red-100',
        pink: 'text-pink-700 bg-pink-100',
        grape: 'text-grape-700 bg-grape-100',
        violet: 'text-violet-700 bg-violet-100',
        indigo: 'text-indigo-700 bg-indigo-100',
        blue: 'text-blue-700 bg-blue-100',
        cyan: 'text-cyan-700 bg-cyan-100',
        teal: 'text-teal-700 bg-teal-100',
        green: 'text-green-700 bg-green-100',
        lime: 'text-lime-700 bg-lime-100',
        yellow: 'text-yellow-700 bg-yellow-100',
        orange: 'text-orange-700 bg-orange-100',
        gray: 'text-gray-700 bg-gray-100',
    };

    return colors[group.color] ? colors[group.color] : colors.gray;
}
