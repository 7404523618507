import {Role, User, Project} from '@contractool/schema';
import React, {FC, useContext, useCallback} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';

import {Form} from 'components/Form';
import {Modal} from 'components/Modal';
import {Button} from 'components/Button';
import {useToasts} from 'components/Toast';
import {http} from 'utils/http';
import {timezones} from 'utils/timezones';
import {AppContext, TranslationContext} from 'contexts';
import {useRequest} from 'hooks/useRequest';
import {Page} from 'components/Page';
import {fromQueryString} from 'utils/url';
import {Form as DellSWPForm} from 'integrations/dell_swp/views/project/components';
import {Form as ScantraxxForm} from 'integrations/scantraxx/components/views/project/components';
import {Form as DellJIForm} from 'integrations/dell_ji/components/views/project/components';
import {DellForm} from './DellForm';
import {DefaultForm} from './DefaultForm';
import ProjectFormProvider from 'views/project/ProjectFormProvider';

export function NewProjectForm() {
    const history = useHistory();
    const {translate} = useContext(TranslationContext);

    let workflow = history.location.state;
    if (!workflow) {
        workflow = 'default';
    }
    const close = useCallback(() => {
        history.push(`/projects/new/form${history.location.search}`);
    }, [history]);
    const {success} = useToasts();

    const searchParams = fromQueryString(history.location.search);

    let url = `api/projects/new?workflow=${workflow}`;
    if (searchParams && searchParams.clone !== undefined) {
        url = `api/projects/${searchParams.clone}`;
    }
    // if (history.location.state) {
    //     workflow = history.location.state;
    // }
    const [initialProject] = useRequest<Project | undefined>(url, undefined);

    const handleSubmit = useCallback((values) => http.post<Project>('/api/projects', values), []);

    return (
        <>
            <Switch>
                <Route
                    path={`/projects/new/form/new-user`}
                    render={() => <AddUserModal onClose={close} />}
                />
            </Switch>
            <Page heading={translate('New Project Request')}>
                {initialProject ? (
                    <Form
                        name="new-project"
                        initialValues={{
                            ...initialProject,
                        }}
                        onSuccess={(data) => {
                            success(`${translate('Project was created')}.`);
                            history.push('/projects/' + data.id);
                        }}
                        onSubmit={handleSubmit}
                        guard={false}
                        loader={'big'}
                    >
                        <FormContainer initialProject={initialProject} />
                        <Form.Submit>{translate('Create Project')}</Form.Submit>
                    </Form>
                ) : null}
            </Page>
        </>
    );
}

const FormContainer: FC<{initialProject: Project}> = ({initialProject}) => {
    const history = useHistory();
    const {config} = useContext(AppContext);

    return config.integration === 'dell' ? (
        <ProjectFormProvider>
            <DellForm search={history.location.search} />
        </ProjectFormProvider>
    ) : config.integration === 'dell_ji' ? (
        <ProjectFormProvider create={true}>
            <DellJIForm project={initialProject} />
        </ProjectFormProvider>
    ) : config.integration === 'dell_swp' ? (
        <ProjectFormProvider create={true}>
            <DellSWPForm project={initialProject} />
        </ProjectFormProvider>
    ) : config.integration === 'scantraxx' ? (
        <ProjectFormProvider create={true}>
            <ScantraxxForm project={initialProject} />
        </ProjectFormProvider>
    ) : (
        <ProjectFormProvider create={true}>
            <DefaultForm project={initialProject} />
        </ProjectFormProvider>
    );
};

interface UserForm {
    name: string;
    email: string;
    timezone: string;
    type: string;
    workflow: string;
    roles: Role[];
}

export const AddUserModal: FC<{onClose: () => void}> = ({onClose}) => {
    const {success} = useToasts();
    const {translate} = useContext(TranslationContext);

    const initialValues: UserForm = {
        name: '',
        email: '',
        timezone: '',
        type: 'USER',
        workflow: 'onboarding',
        roles: [
            {
                color: 'grape',
                full_key: 'team.singular.onboarding__viewer',
                hidden: false,
                key: 'onboarding__viewer',
                label: 'Business Partner',
                singular: true,
                workflow: 'onboarding',
                required: false,
                groups: []
            },
        ],
    };

    return (
        <Modal heading={translate('Add new user')} onClose={onClose}>
            <Form
                initialValues={initialValues}
                onSubmit={(values) => http.post<User>('api/users', values)}
                onSuccess={() => {
                    success(translate('User was succesfully created'));
                    onClose();
                }}
            >
                <Form.TextInput
                    name="name"
                    label={translate('Name')}
                    placeholder={translate('Name')}
                />
                <Form.TextInput name="email" label="Email" placeholder="Email" className="mt-6" />
                <Form.Dropdown
                    name="timezone"
                    label={translate('Timezone')}
                    options={timezones}
                    className="my-6"
                />
                <Modal.Footer className="flex justify-between">
                    <Button color="white" onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Form.Submit>{translate('Save Changes')}</Form.Submit>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
