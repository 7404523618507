import {FilterTemplate} from '@contractool/schema';
import * as React from 'react';

import {AppContext, TranslationContext} from 'contexts';
import {Form as CommonForm} from 'components/Form';
import {useFormInteractions} from 'features/hooks/reports/Form';
import {Context as ReportsContext} from 'contexts/reports';

import ChartTypeSelect from './ChartTypeSelect';

const Form = () => {
    const {config} = React.useContext(AppContext);

    const metricValues = config.metrics;
    const metricOptions = metricValues.map((value: string) => ({
        label: value,
        value
    }));

    const {translate} = React.useContext(TranslationContext);

    const {filterTemplates} = React.useContext(ReportsContext);

    const groupByOptions = filterTemplates
        .map(
            (filter: FilterTemplate) => ({
                label: filter.label,
                value: filter.name
            })
        );

    const {groupByFieldVisible} = useFormInteractions();

    return (
        <>
            <CommonForm.TextInput
                name="title"
                label={translate('Widget name')}
                autoFocus={true}
            />
            <CommonForm.Dropdown
                name="metric"
                label={translate('Metric')}
                options={metricOptions}
                className="mt-6"
            />
            {groupByFieldVisible && (
                <CommonForm.Dropdown
                    name="groupBy"
                    label={translate('Group By')}
                    options={groupByOptions}
                    className="mt-6"
                    autocomplete={true}
                />
            )}
            <CommonForm.Field
                component={ChartTypeSelect}
                injectedClassName="mt-4"
                name="chartType"
                label={translate('Chart Type')}
                className="mt-6"
            />
        </>
    )
};

export default Form;
