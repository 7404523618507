import React from 'react';
import {ProjectState} from '@contractool/schema';
import {Icon, Icons} from '../../components/Icon';

export function ProjectStateIcon({state}: {state: ProjectState}) {
    return state.extra.icon ? (
        <div
            style={{background: state.extra.icon.background, color: state.extra.icon.foreground}}
            className="rounded-full w-10 h-10 p-2 flex items-center justify-center"
        >
            <Icon name={state.extra.icon?.name as Icons} size={6}></Icon>
        </div>
    ) : (
        <Icon
            name="dehaze"
            size={6}
            className={
                'rounded-full w-10 h-10 p-2 flex items-center justify-center bg-gray-100 text-gray-600'
            }
        ></Icon>
    );
}
