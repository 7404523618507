import React, {FC} from 'react';
import {Icon, Icons} from './Icon';

type Type = 'SUCCESS' | 'ERROR' | 'WARNING';

export const Message: {Success: FC; Warning: FC; Error: FC} = {
    Success: ({children}) => <MessageBox type="SUCCESS">{children}</MessageBox>,
    Warning: ({children}) => <MessageBox type="WARNING">{children}</MessageBox>,
    Error: ({children}) => <MessageBox type="ERROR">{children}</MessageBox>,
};

const MessageBox: FC<{type: Type}> = ({type, children}) => {
    let theme = initTheme(type);

    return (
        <div
            className={`${theme.textColor} ${theme.bgColor} rounded-xl p-5 flex items-center`}
            role="alert"
        >
            <div className={`w-10 h-10 p-2 rounded-full ${theme.circleColor}`}>
                <Icon name={theme.icon} size={6} />
            </div>

            <span className="ml-5 select-none">{children}</span>
        </div>
    );
};

function initTheme(
    type: Type,
): {
    textColor: string;
    bgColor: string;
    circleColor: string;
    icon: Icons;
} {
    switch (type) {
        case 'SUCCESS':
            return {
                textColor: 'text-teal-700',
                bgColor: 'bg-teal-000',
                circleColor: 'bg-teal-100',
                icon: 'done',
            };

        case 'WARNING':
            return {
                textColor: 'text-orange-600',
                bgColor: 'bg-orange-000',
                circleColor: 'bg-orange-100',
                icon: 'error',
            };

        case 'ERROR':
            return {
                textColor: 'text-red-700',
                bgColor: 'bg-red-000',
                circleColor: 'bg-red-100',
                icon: 'close',
            };
    }
}
