import React, {useContext} from 'react';
import {User} from '@contractool/schema';
import {TranslationContext} from 'contexts';

export function UserStateLabel({user, className}: {user: User; className?: string}) {
    const {translate} = useContext(TranslationContext);

    if (user.state !== 'deactivated') {
        return null;
    }
    return (
        <div
            className={`px-3 py-2 rounded text-sm leading-none select-none text-gray-600 bg-gray-100 inline ${className}`}
        >
            {translate('Deactivated')}
        </div>
    );
}
