import React from 'react';
import {RouteTabs, Tabs, Tab} from '../../components/Tabs';
import {Section, Variation} from './DesignSystem';

export function TabsSection() {
    return (
        <>
            <Section title="Basic tabs">
                <Variation>
                    <Tabs className="w-72">
                        <Tab name="general" heading="General" className="py-8">
                            General content
                        </Tab>

                        <Tab name="specific" heading="Specific" className="py-8">
                            Specific content
                        </Tab>
                    </Tabs>
                </Variation>
            </Section>

            <Section title="Route tabs">
                <Variation hint="Watch the URL">
                    <RouteTabs match="/ui/tabs/:tab" className="w-72">
                        <Tab name="general" heading="General" className="py-8">
                            General content
                        </Tab>

                        <Tab name="specific" heading="Specific" className="py-8">
                            Specific content
                        </Tab>
                    </RouteTabs>
                </Variation>
            </Section>
        </>
    );
}
