import {useState} from 'react';
import {http} from '../http';
import {PaginationProps} from '../../components/Pagination';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Axios from 'axios';
import omitBy from 'lodash/omitBy';

type Pagination = PaginationProps & {morePages: boolean};

export function useRequest<T, M = unknown>(
    url: string,
    defaultValue: T,
    {
        params = {},
    }: {
        params?: Record<string, any>;
    } = {},
    setLoaded?: (value: boolean) => void,
    setResponse?: (value: any) => void,
): [
    T,
    {
        meta: M | undefined;
        pagination: Pagination;

        refresh: () => void;
    }
    ] {
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: 1,
        totalPages: 1,
        morePages: false,
    });

    const [state, setState] = useState(defaultValue);
    const [meta, setMeta] = useState<M | undefined>(undefined);
    const [refreshToken, setRefreshToken] = useState(false);

    useDeepCompareEffect(() => {
        const source = Axios.CancelToken.source();

        http.get<T>(
            url,
            omitBy(params || {}, (v) => v === undefined || v === null || v === ''),
            {cancelToken: source.token},
        )
            .then((response) => {
                if (response.meta) {
                    setMeta(response.meta);

                    setPagination({
                        currentPage: response.meta.current_page,
                        totalPages: response.meta.last_page,
                        morePages: response.meta.current_page < response.meta.last_page,
                        totalRecords: response.meta.total
                    });
                }
                setState(response.data);
                if (setLoaded) {
                    setLoaded(true);
                }
            })
            .catch((e) => {
                if (Axios.isCancel(e)) {
                    return;
                }

                if (e?.response?.status === 401) {
                    if (setResponse) {
                        setResponse(e.response.data);
                    }
                    return e;
                }

                throw e;
            });

        return () => source.cancel();
    }, [url, params, refreshToken]);

    return [
        state,

        {
            meta,

            pagination,

            refresh() {
                setRefreshToken(!refreshToken);
            },
        },
    ];
}
