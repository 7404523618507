export const timezones = [
    {value: 'Pacific/Midway', label: '(UTC -11:00) Pacific/Midway'},
    {value: 'Pacific/Niue', label: '(UTC -11:00) Pacific/Niue'},
    {value: 'Pacific/Pago_Pago', label: '(UTC -11:00) Pacific/Pago_Pago'},
    {value: 'America/Adak', label: '(UTC -10:00) America/Adak'},
    {value: 'Pacific/Honolulu', label: '(UTC -10:00) Pacific/Honolulu'},
    {value: 'Pacific/Rarotonga', label: '(UTC -10:00) Pacific/Rarotonga'},
    {value: 'Pacific/Tahiti', label: '(UTC -10:00) Pacific/Tahiti'},
    {value: 'Pacific/Marquesas', label: '(UTC -09:30) Pacific/Marquesas'},
    {value: 'America/Anchorage', label: '(UTC -09:00) America/Anchorage'},
    {value: 'America/Juneau', label: '(UTC -09:00) America/Juneau'},
    {value: 'America/Metlakatla', label: '(UTC -09:00) America/Metlakatla'},
    {value: 'America/Nome', label: '(UTC -09:00) America/Nome'},
    {value: 'America/Sitka', label: '(UTC -09:00) America/Sitka'},
    {value: 'America/Yakutat', label: '(UTC -09:00) America/Yakutat'},
    {value: 'Pacific/Gambier', label: '(UTC -09:00) Pacific/Gambier'},
    {value: 'America/Dawson', label: '(UTC -08:00) America/Dawson'},
    {value: 'America/Los_Angeles', label: '(UTC -08:00) America/Los_Angeles'},
    {value: 'America/Tijuana', label: '(UTC -08:00) America/Tijuana'},
    {value: 'America/Vancouver', label: '(UTC -08:00) America/Vancouver'},
    {value: 'America/Whitehorse', label: '(UTC -08:00) America/Whitehorse'},
    {value: 'Pacific/Pitcairn', label: '(UTC -08:00) Pacific/Pitcairn'},
    {value: 'America/Boise', label: '(UTC -07:00) America/Boise'},
    {value: 'America/Cambridge_Bay', label: '(UTC -07:00) America/Cambridge_Bay'},
    {value: 'America/Chihuahua', label: '(UTC -07:00) America/Chihuahua'},
    {value: 'America/Creston', label: '(UTC -07:00) America/Creston'},
    {value: 'America/Dawson_Creek', label: '(UTC -07:00) America/Dawson_Creek'},
    {value: 'America/Denver', label: '(UTC -07:00) America/Denver'},
    {value: 'America/Edmonton', label: '(UTC -07:00) America/Edmonton'},
    {value: 'America/Fort_Nelson', label: '(UTC -07:00) America/Fort_Nelson'},
    {value: 'America/Hermosillo', label: '(UTC -07:00) America/Hermosillo'},
    {value: 'America/Inuvik', label: '(UTC -07:00) America/Inuvik'},
    {value: 'America/Mazatlan', label: '(UTC -07:00) America/Mazatlan'},
    {value: 'America/Ojinaga', label: '(UTC -07:00) America/Ojinaga'},
    {value: 'America/Phoenix', label: '(UTC -07:00) America/Phoenix'},
    {value: 'America/Yellowknife', label: '(UTC -07:00) America/Yellowknife'},
    {value: 'America/Bahia_Banderas', label: '(UTC -06:00) America/Bahia_Banderas'},
    {value: 'America/Belize', label: '(UTC -06:00) America/Belize'},
    {value: 'America/Chicago', label: '(UTC -06:00) America/Chicago'},
    {value: 'America/Costa_Rica', label: '(UTC -06:00) America/Costa_Rica'},
    {value: 'America/El_Salvador', label: '(UTC -06:00) America/El_Salvador'},
    {value: 'America/Guatemala', label: '(UTC -06:00) America/Guatemala'},
    {value: 'America/Indiana/Knox', label: '(UTC -06:00) America/Indiana/Knox'},
    {value: 'America/Indiana/Tell_City', label: '(UTC -06:00) America/Indiana/Tell_City'},
    {value: 'America/Managua', label: '(UTC -06:00) America/Managua'},
    {value: 'America/Matamoros', label: '(UTC -06:00) America/Matamoros'},
    {value: 'America/Menominee', label: '(UTC -06:00) America/Menominee'},
    {value: 'America/Merida', label: '(UTC -06:00) America/Merida'},
    {value: 'America/Mexico_City', label: '(UTC -06:00) America/Mexico_City'},
    {value: 'America/Monterrey', label: '(UTC -06:00) America/Monterrey'},
    {value: 'America/North_Dakota/Beulah', label: '(UTC -06:00) America/North_Dakota/Beulah'},
    {value: 'America/North_Dakota/Center', label: '(UTC -06:00) America/North_Dakota/Center'},
    {
        value: 'America/North_Dakota/New_Salem',
        label: '(UTC -06:00) America/North_Dakota/New_Salem',
    },
    {value: 'America/Rainy_River', label: '(UTC -06:00) America/Rainy_River'},
    {value: 'America/Rankin_Inlet', label: '(UTC -06:00) America/Rankin_Inlet'},
    {value: 'America/Regina', label: '(UTC -06:00) America/Regina'},
    {value: 'America/Resolute', label: '(UTC -06:00) America/Resolute'},
    {value: 'America/Swift_Current', label: '(UTC -06:00) America/Swift_Current'},
    {value: 'America/Tegucigalpa', label: '(UTC -06:00) America/Tegucigalpa'},
    {value: 'America/Winnipeg', label: '(UTC -06:00) America/Winnipeg'},
    {value: 'Pacific/Galapagos', label: '(UTC -06:00) Pacific/Galapagos'},
    {value: 'America/Atikokan', label: '(UTC -05:00) America/Atikokan'},
    {value: 'America/Bogota', label: '(UTC -05:00) America/Bogota'},
    {value: 'America/Cancun', label: '(UTC -05:00) America/Cancun'},
    {value: 'America/Cayman', label: '(UTC -05:00) America/Cayman'},
    {value: 'America/Detroit', label: '(UTC -05:00) America/Detroit'},
    {value: 'America/Eirunepe', label: '(UTC -05:00) America/Eirunepe'},
    {value: 'America/Grand_Turk', label: '(UTC -05:00) America/Grand_Turk'},
    {value: 'America/Guayaquil', label: '(UTC -05:00) America/Guayaquil'},
    {value: 'America/Havana', label: '(UTC -05:00) America/Havana'},
    {value: 'America/Indiana/Indianapolis', label: '(UTC -05:00) America/Indiana/Indianapolis'},
    {value: 'America/Indiana/Marengo', label: '(UTC -05:00) America/Indiana/Marengo'},
    {value: 'America/Indiana/Petersburg', label: '(UTC -05:00) America/Indiana/Petersburg'},
    {value: 'America/Indiana/Vevay', label: '(UTC -05:00) America/Indiana/Vevay'},
    {value: 'America/Indiana/Vincennes', label: '(UTC -05:00) America/Indiana/Vincennes'},
    {value: 'America/Indiana/Winamac', label: '(UTC -05:00) America/Indiana/Winamac'},
    {value: 'America/Iqaluit', label: '(UTC -05:00) America/Iqaluit'},
    {value: 'America/Jamaica', label: '(UTC -05:00) America/Jamaica'},
    {value: 'America/Kentucky/Louisville', label: '(UTC -05:00) America/Kentucky/Louisville'},
    {value: 'America/Kentucky/Monticello', label: '(UTC -05:00) America/Kentucky/Monticello'},
    {value: 'America/Lima', label: '(UTC -05:00) America/Lima'},
    {value: 'America/Nassau', label: '(UTC -05:00) America/Nassau'},
    {value: 'America/New_York', label: '(UTC -05:00) America/New_York'},
    {value: 'America/Nipigon', label: '(UTC -05:00) America/Nipigon'},
    {value: 'America/Panama', label: '(UTC -05:00) America/Panama'},
    {value: 'America/Pangnirtung', label: '(UTC -05:00) America/Pangnirtung'},
    {value: 'America/Port-au-Prince', label: '(UTC -05:00) America/Port-au-Prince'},
    {value: 'America/Rio_Branco', label: '(UTC -05:00) America/Rio_Branco'},
    {value: 'America/Thunder_Bay', label: '(UTC -05:00) America/Thunder_Bay'},
    {value: 'America/Toronto', label: '(UTC -05:00) America/Toronto'},
    {value: 'Pacific/Easter', label: '(UTC -05:00) Pacific/Easter'},
    {value: 'America/Anguilla', label: '(UTC -04:00) America/Anguilla'},
    {value: 'America/Antigua', label: '(UTC -04:00) America/Antigua'},
    {value: 'America/Aruba', label: '(UTC -04:00) America/Aruba'},
    {value: 'America/Barbados', label: '(UTC -04:00) America/Barbados'},
    {value: 'America/Blanc-Sablon', label: '(UTC -04:00) America/Blanc-Sablon'},
    {value: 'America/Boa_Vista', label: '(UTC -04:00) America/Boa_Vista'},
    {value: 'America/Caracas', label: '(UTC -04:00) America/Caracas'},
    {value: 'America/Curacao', label: '(UTC -04:00) America/Curacao'},
    {value: 'America/Dominica', label: '(UTC -04:00) America/Dominica'},
    {value: 'America/Glace_Bay', label: '(UTC -04:00) America/Glace_Bay'},
    {value: 'America/Goose_Bay', label: '(UTC -04:00) America/Goose_Bay'},
    {value: 'America/Grenada', label: '(UTC -04:00) America/Grenada'},
    {value: 'America/Guadeloupe', label: '(UTC -04:00) America/Guadeloupe'},
    {value: 'America/Guyana', label: '(UTC -04:00) America/Guyana'},
    {value: 'America/Halifax', label: '(UTC -04:00) America/Halifax'},
    {value: 'America/Kralendijk', label: '(UTC -04:00) America/Kralendijk'},
    {value: 'America/La_Paz', label: '(UTC -04:00) America/La_Paz'},
    {value: 'America/Lower_Princes', label: '(UTC -04:00) America/Lower_Princes'},
    {value: 'America/Manaus', label: '(UTC -04:00) America/Manaus'},
    {value: 'America/Marigot', label: '(UTC -04:00) America/Marigot'},
    {value: 'America/Martinique', label: '(UTC -04:00) America/Martinique'},
    {value: 'America/Moncton', label: '(UTC -04:00) America/Moncton'},
    {value: 'America/Montserrat', label: '(UTC -04:00) America/Montserrat'},
    {value: 'America/Port_of_Spain', label: '(UTC -04:00) America/Port_of_Spain'},
    {value: 'America/Porto_Velho', label: '(UTC -04:00) America/Porto_Velho'},
    {value: 'America/Puerto_Rico', label: '(UTC -04:00) America/Puerto_Rico'},
    {value: 'America/Santo_Domingo', label: '(UTC -04:00) America/Santo_Domingo'},
    {value: 'America/St_Barthelemy', label: '(UTC -04:00) America/St_Barthelemy'},
    {value: 'America/St_Kitts', label: '(UTC -04:00) America/St_Kitts'},
    {value: 'America/St_Lucia', label: '(UTC -04:00) America/St_Lucia'},
    {value: 'America/St_Thomas', label: '(UTC -04:00) America/St_Thomas'},
    {value: 'America/St_Vincent', label: '(UTC -04:00) America/St_Vincent'},
    {value: 'America/Thule', label: '(UTC -04:00) America/Thule'},
    {value: 'America/Tortola', label: '(UTC -04:00) America/Tortola'},
    {value: 'Atlantic/Bermuda', label: '(UTC -04:00) Atlantic/Bermuda'},
    {value: 'America/St_Johns', label: '(UTC -03:30) America/St_Johns'},
    {value: 'America/Araguaina', label: '(UTC -03:00) America/Araguaina'},
    {
        value: 'America/Argentina/Buenos_Aires',
        label: '(UTC -03:00) America/Argentina/Buenos_Aires',
    },
    {value: 'America/Argentina/Catamarca', label: '(UTC -03:00) America/Argentina/Catamarca'},
    {value: 'America/Argentina/Cordoba', label: '(UTC -03:00) America/Argentina/Cordoba'},
    {value: 'America/Argentina/Jujuy', label: '(UTC -03:00) America/Argentina/Jujuy'},
    {value: 'America/Argentina/La_Rioja', label: '(UTC -03:00) America/Argentina/La_Rioja'},
    {value: 'America/Argentina/Mendoza', label: '(UTC -03:00) America/Argentina/Mendoza'},
    {
        value: 'America/Argentina/Rio_Gallegos',
        label: '(UTC -03:00) America/Argentina/Rio_Gallegos',
    },
    {value: 'America/Argentina/Salta', label: '(UTC -03:00) America/Argentina/Salta'},
    {value: 'America/Argentina/San_Juan', label: '(UTC -03:00) America/Argentina/San_Juan'},
    {value: 'America/Argentina/San_Luis', label: '(UTC -03:00) America/Argentina/San_Luis'},
    {value: 'America/Argentina/Tucuman', label: '(UTC -03:00) America/Argentina/Tucuman'},
    {value: 'America/Argentina/Ushuaia', label: '(UTC -03:00) America/Argentina/Ushuaia'},
    {value: 'America/Asuncion', label: '(UTC -03:00) America/Asuncion'},
    {value: 'America/Bahia', label: '(UTC -03:00) America/Bahia'},
    {value: 'America/Belem', label: '(UTC -03:00) America/Belem'},
    {value: 'America/Campo_Grande', label: '(UTC -03:00) America/Campo_Grande'},
    {value: 'America/Cayenne', label: '(UTC -03:00) America/Cayenne'},
    {value: 'America/Cuiaba', label: '(UTC -03:00) America/Cuiaba'},
    {value: 'America/Fortaleza', label: '(UTC -03:00) America/Fortaleza'},
    {value: 'America/Godthab', label: '(UTC -03:00) America/Godthab'},
    {value: 'America/Maceio', label: '(UTC -03:00) America/Maceio'},
    {value: 'America/Miquelon', label: '(UTC -03:00) America/Miquelon'},
    {value: 'America/Montevideo', label: '(UTC -03:00) America/Montevideo'},
    {value: 'America/Paramaribo', label: '(UTC -03:00) America/Paramaribo'},
    {value: 'America/Punta_Arenas', label: '(UTC -03:00) America/Punta_Arenas'},
    {value: 'America/Recife', label: '(UTC -03:00) America/Recife'},
    {value: 'America/Santarem', label: '(UTC -03:00) America/Santarem'},
    {value: 'America/Santiago', label: '(UTC -03:00) America/Santiago'},
    {value: 'Antarctica/Palmer', label: '(UTC -03:00) Antarctica/Palmer'},
    {value: 'Antarctica/Rothera', label: '(UTC -03:00) Antarctica/Rothera'},
    {value: 'Atlantic/Stanley', label: '(UTC -03:00) Atlantic/Stanley'},
    {value: 'America/Noronha', label: '(UTC -02:00) America/Noronha'},
    {value: 'America/Sao_Paulo', label: '(UTC -02:00) America/Sao_Paulo'},
    {value: 'Atlantic/South_Georgia', label: '(UTC -02:00) Atlantic/South_Georgia'},
    {value: 'America/Scoresbysund', label: '(UTC -01:00) America/Scoresbysund'},
    {value: 'Atlantic/Azores', label: '(UTC -01:00) Atlantic/Azores'},
    {value: 'Atlantic/Cape_Verde', label: '(UTC -01:00) Atlantic/Cape_Verde'},
    {value: 'Africa/Abidjan', label: '(UTC +00:00) Africa/Abidjan'},
    {value: 'Africa/Accra', label: '(UTC +00:00) Africa/Accra'},
    {value: 'Africa/Bamako', label: '(UTC +00:00) Africa/Bamako'},
    {value: 'Africa/Banjul', label: '(UTC +00:00) Africa/Banjul'},
    {value: 'Africa/Bissau', label: '(UTC +00:00) Africa/Bissau'},
    {value: 'Africa/Conakry', label: '(UTC +00:00) Africa/Conakry'},
    {value: 'Africa/Dakar', label: '(UTC +00:00) Africa/Dakar'},
    {value: 'Africa/Freetown', label: '(UTC +00:00) Africa/Freetown'},
    {value: 'Africa/Lome', label: '(UTC +00:00) Africa/Lome'},
    {value: 'Africa/Monrovia', label: '(UTC +00:00) Africa/Monrovia'},
    {value: 'Africa/Nouakchott', label: '(UTC +00:00) Africa/Nouakchott'},
    {value: 'Africa/Ouagadougou', label: '(UTC +00:00) Africa/Ouagadougou'},
    {value: 'Africa/Sao_Tome', label: '(UTC +00:00) Africa/Sao_Tome'},
    {value: 'America/Danmarkshavn', label: '(UTC +00:00) America/Danmarkshavn'},
    {value: 'Antarctica/Troll', label: '(UTC +00:00) Antarctica/Troll'},
    {value: 'Atlantic/Canary', label: '(UTC +00:00) Atlantic/Canary'},
    {value: 'Atlantic/Faroe', label: '(UTC +00:00) Atlantic/Faroe'},
    {value: 'Atlantic/Madeira', label: '(UTC +00:00) Atlantic/Madeira'},
    {value: 'Atlantic/Reykjavik', label: '(UTC +00:00) Atlantic/Reykjavik'},
    {value: 'Atlantic/St_Helena', label: '(UTC +00:00) Atlantic/St_Helena'},
    {value: 'Europe/Dublin', label: '(UTC +00:00) Europe/Dublin'},
    {value: 'Europe/Guernsey', label: '(UTC +00:00) Europe/Guernsey'},
    {value: 'Europe/Isle_of_Man', label: '(UTC +00:00) Europe/Isle_of_Man'},
    {value: 'Europe/Jersey', label: '(UTC +00:00) Europe/Jersey'},
    {value: 'Europe/Lisbon', label: '(UTC +00:00) Europe/Lisbon'},
    {value: 'Europe/London', label: '(UTC +00:00) Europe/London'},
    {value: 'UTC', label: '(UTC +00:00) UTC'},
    {value: 'Africa/Algiers', label: '(UTC +01:00) Africa/Algiers'},
    {value: 'Africa/Bangui', label: '(UTC +01:00) Africa/Bangui'},
    {value: 'Africa/Brazzaville', label: '(UTC +01:00) Africa/Brazzaville'},
    {value: 'Africa/Casablanca', label: '(UTC +01:00) Africa/Casablanca'},
    {value: 'Africa/Ceuta', label: '(UTC +01:00) Africa/Ceuta'},
    {value: 'Africa/Douala', label: '(UTC +01:00) Africa/Douala'},
    {value: 'Africa/El_Aaiun', label: '(UTC +01:00) Africa/El_Aaiun'},
    {value: 'Africa/Kinshasa', label: '(UTC +01:00) Africa/Kinshasa'},
    {value: 'Africa/Lagos', label: '(UTC +01:00) Africa/Lagos'},
    {value: 'Africa/Libreville', label: '(UTC +01:00) Africa/Libreville'},
    {value: 'Africa/Luanda', label: '(UTC +01:00) Africa/Luanda'},
    {value: 'Africa/Malabo', label: '(UTC +01:00) Africa/Malabo'},
    {value: 'Africa/Ndjamena', label: '(UTC +01:00) Africa/Ndjamena'},
    {value: 'Africa/Niamey', label: '(UTC +01:00) Africa/Niamey'},
    {value: 'Africa/Porto-Novo', label: '(UTC +01:00) Africa/Porto-Novo'},
    {value: 'Africa/Tunis', label: '(UTC +01:00) Africa/Tunis'},
    {value: 'Arctic/Longyearbyen', label: '(UTC +01:00) Arctic/Longyearbyen'},
    {value: 'Europe/Amsterdam', label: '(UTC +01:00) Europe/Amsterdam'},
    {value: 'Europe/Andorra', label: '(UTC +01:00) Europe/Andorra'},
    {value: 'Europe/Belgrade', label: '(UTC +01:00) Europe/Belgrade'},
    {value: 'Europe/Berlin', label: '(UTC +01:00) Europe/Berlin'},
    {value: 'Europe/Bratislava', label: '(UTC +01:00) Europe/Bratislava'},
    {value: 'Europe/Brussels', label: '(UTC +01:00) Europe/Brussels'},
    {value: 'Europe/Budapest', label: '(UTC +01:00) Europe/Budapest'},
    {value: 'Europe/Busingen', label: '(UTC +01:00) Europe/Busingen'},
    {value: 'Europe/Copenhagen', label: '(UTC +01:00) Europe/Copenhagen'},
    {value: 'Europe/Gibraltar', label: '(UTC +01:00) Europe/Gibraltar'},
    {value: 'Europe/Ljubljana', label: '(UTC +01:00) Europe/Ljubljana'},
    {value: 'Europe/Luxembourg', label: '(UTC +01:00) Europe/Luxembourg'},
    {value: 'Europe/Madrid', label: '(UTC +01:00) Europe/Madrid'},
    {value: 'Europe/Malta', label: '(UTC +01:00) Europe/Malta'},
    {value: 'Europe/Monaco', label: '(UTC +01:00) Europe/Monaco'},
    {value: 'Europe/Oslo', label: '(UTC +01:00) Europe/Oslo'},
    {value: 'Europe/Paris', label: '(UTC +01:00) Europe/Paris'},
    {value: 'Europe/Podgorica', label: '(UTC +01:00) Europe/Podgorica'},
    {value: 'Europe/Prague', label: '(UTC +01:00) Europe/Prague'},
    {value: 'Europe/Rome', label: '(UTC +01:00) Europe/Rome'},
    {value: 'Europe/San_Marino', label: '(UTC +01:00) Europe/San_Marino'},
    {value: 'Europe/Sarajevo', label: '(UTC +01:00) Europe/Sarajevo'},
    {value: 'Europe/Skopje', label: '(UTC +01:00) Europe/Skopje'},
    {value: 'Europe/Stockholm', label: '(UTC +01:00) Europe/Stockholm'},
    {value: 'Europe/Tirane', label: '(UTC +01:00) Europe/Tirane'},
    {value: 'Europe/Vaduz', label: '(UTC +01:00) Europe/Vaduz'},
    {value: 'Europe/Vatican', label: '(UTC +01:00) Europe/Vatican'},
    {value: 'Europe/Vienna', label: '(UTC +01:00) Europe/Vienna'},
    {value: 'Europe/Warsaw', label: '(UTC +01:00) Europe/Warsaw'},
    {value: 'Europe/Zagreb', label: '(UTC +01:00) Europe/Zagreb'},
    {value: 'Europe/Zurich', label: '(UTC +01:00) Europe/Zurich'},
    {value: 'Africa/Blantyre', label: '(UTC +02:00) Africa/Blantyre'},
    {value: 'Africa/Bujumbura', label: '(UTC +02:00) Africa/Bujumbura'},
    {value: 'Africa/Cairo', label: '(UTC +02:00) Africa/Cairo'},
    {value: 'Africa/Gaborone', label: '(UTC +02:00) Africa/Gaborone'},
    {value: 'Africa/Harare', label: '(UTC +02:00) Africa/Harare'},
    {value: 'Africa/Johannesburg', label: '(UTC +02:00) Africa/Johannesburg'},
    {value: 'Africa/Khartoum', label: '(UTC +02:00) Africa/Khartoum'},
    {value: 'Africa/Kigali', label: '(UTC +02:00) Africa/Kigali'},
    {value: 'Africa/Lubumbashi', label: '(UTC +02:00) Africa/Lubumbashi'},
    {value: 'Africa/Lusaka', label: '(UTC +02:00) Africa/Lusaka'},
    {value: 'Africa/Maputo', label: '(UTC +02:00) Africa/Maputo'},
    {value: 'Africa/Maseru', label: '(UTC +02:00) Africa/Maseru'},
    {value: 'Africa/Mbabane', label: '(UTC +02:00) Africa/Mbabane'},
    {value: 'Africa/Tripoli', label: '(UTC +02:00) Africa/Tripoli'},
    {value: 'Africa/Windhoek', label: '(UTC +02:00) Africa/Windhoek'},
    {value: 'Asia/Amman', label: '(UTC +02:00) Asia/Amman'},
    {value: 'Asia/Beirut', label: '(UTC +02:00) Asia/Beirut'},
    {value: 'Asia/Damascus', label: '(UTC +02:00) Asia/Damascus'},
    {value: 'Asia/Famagusta', label: '(UTC +02:00) Asia/Famagusta'},
    {value: 'Asia/Gaza', label: '(UTC +02:00) Asia/Gaza'},
    {value: 'Asia/Hebron', label: '(UTC +02:00) Asia/Hebron'},
    {value: 'Asia/Jerusalem', label: '(UTC +02:00) Asia/Jerusalem'},
    {value: 'Asia/Nicosia', label: '(UTC +02:00) Asia/Nicosia'},
    {value: 'Europe/Athens', label: '(UTC +02:00) Europe/Athens'},
    {value: 'Europe/Bucharest', label: '(UTC +02:00) Europe/Bucharest'},
    {value: 'Europe/Chisinau', label: '(UTC +02:00) Europe/Chisinau'},
    {value: 'Europe/Helsinki', label: '(UTC +02:00) Europe/Helsinki'},
    {value: 'Europe/Kaliningrad', label: '(UTC +02:00) Europe/Kaliningrad'},
    {value: 'Europe/Kiev', label: '(UTC +02:00) Europe/Kiev'},
    {value: 'Europe/Mariehamn', label: '(UTC +02:00) Europe/Mariehamn'},
    {value: 'Europe/Riga', label: '(UTC +02:00) Europe/Riga'},
    {value: 'Europe/Sofia', label: '(UTC +02:00) Europe/Sofia'},
    {value: 'Europe/Tallinn', label: '(UTC +02:00) Europe/Tallinn'},
    {value: 'Europe/Uzhgorod', label: '(UTC +02:00) Europe/Uzhgorod'},
    {value: 'Europe/Vilnius', label: '(UTC +02:00) Europe/Vilnius'},
    {value: 'Europe/Zaporozhye', label: '(UTC +02:00) Europe/Zaporozhye'},
    {value: 'Africa/Addis_Ababa', label: '(UTC +03:00) Africa/Addis_Ababa'},
    {value: 'Africa/Asmara', label: '(UTC +03:00) Africa/Asmara'},
    {value: 'Africa/Dar_es_Salaam', label: '(UTC +03:00) Africa/Dar_es_Salaam'},
    {value: 'Africa/Djibouti', label: '(UTC +03:00) Africa/Djibouti'},
    {value: 'Africa/Juba', label: '(UTC +03:00) Africa/Juba'},
    {value: 'Africa/Kampala', label: '(UTC +03:00) Africa/Kampala'},
    {value: 'Africa/Mogadishu', label: '(UTC +03:00) Africa/Mogadishu'},
    {value: 'Africa/Nairobi', label: '(UTC +03:00) Africa/Nairobi'},
    {value: 'Antarctica/Syowa', label: '(UTC +03:00) Antarctica/Syowa'},
    {value: 'Asia/Aden', label: '(UTC +03:00) Asia/Aden'},
    {value: 'Asia/Baghdad', label: '(UTC +03:00) Asia/Baghdad'},
    {value: 'Asia/Bahrain', label: '(UTC +03:00) Asia/Bahrain'},
    {value: 'Asia/Kuwait', label: '(UTC +03:00) Asia/Kuwait'},
    {value: 'Asia/Qatar', label: '(UTC +03:00) Asia/Qatar'},
    {value: 'Asia/Riyadh', label: '(UTC +03:00) Asia/Riyadh'},
    {value: 'Europe/Istanbul', label: '(UTC +03:00) Europe/Istanbul'},
    {value: 'Europe/Kirov', label: '(UTC +03:00) Europe/Kirov'},
    {value: 'Europe/Minsk', label: '(UTC +03:00) Europe/Minsk'},
    {value: 'Europe/Moscow', label: '(UTC +03:00) Europe/Moscow'},
    {value: 'Europe/Simferopol', label: '(UTC +03:00) Europe/Simferopol'},
    {value: 'Indian/Antananarivo', label: '(UTC +03:00) Indian/Antananarivo'},
    {value: 'Indian/Comoro', label: '(UTC +03:00) Indian/Comoro'},
    {value: 'Indian/Mayotte', label: '(UTC +03:00) Indian/Mayotte'},
    {value: 'Asia/Tehran', label: '(UTC +03:30) Asia/Tehran'},
    {value: 'Asia/Baku', label: '(UTC +04:00) Asia/Baku'},
    {value: 'Asia/Dubai', label: '(UTC +04:00) Asia/Dubai'},
    {value: 'Asia/Muscat', label: '(UTC +04:00) Asia/Muscat'},
    {value: 'Asia/Tbilisi', label: '(UTC +04:00) Asia/Tbilisi'},
    {value: 'Asia/Yerevan', label: '(UTC +04:00) Asia/Yerevan'},
    {value: 'Europe/Astrakhan', label: '(UTC +04:00) Europe/Astrakhan'},
    {value: 'Europe/Samara', label: '(UTC +04:00) Europe/Samara'},
    {value: 'Europe/Saratov', label: '(UTC +04:00) Europe/Saratov'},
    {value: 'Europe/Ulyanovsk', label: '(UTC +04:00) Europe/Ulyanovsk'},
    {value: 'Europe/Volgograd', label: '(UTC +04:00) Europe/Volgograd'},
    {value: 'Indian/Mahe', label: '(UTC +04:00) Indian/Mahe'},
    {value: 'Indian/Mauritius', label: '(UTC +04:00) Indian/Mauritius'},
    {value: 'Indian/Reunion', label: '(UTC +04:00) Indian/Reunion'},
    {value: 'Asia/Kabul', label: '(UTC +04:30) Asia/Kabul'},
    {value: 'Antarctica/Mawson', label: '(UTC +05:00) Antarctica/Mawson'},
    {value: 'Asia/Aqtau', label: '(UTC +05:00) Asia/Aqtau'},
    {value: 'Asia/Aqtobe', label: '(UTC +05:00) Asia/Aqtobe'},
    {value: 'Asia/Ashgabat', label: '(UTC +05:00) Asia/Ashgabat'},
    {value: 'Asia/Atyrau', label: '(UTC +05:00) Asia/Atyrau'},
    {value: 'Asia/Dushanbe', label: '(UTC +05:00) Asia/Dushanbe'},
    {value: 'Asia/Karachi', label: '(UTC +05:00) Asia/Karachi'},
    {value: 'Asia/Oral', label: '(UTC +05:00) Asia/Oral'},
    {value: 'Asia/Qyzylorda', label: '(UTC +05:00) Asia/Qyzylorda'},
    {value: 'Asia/Samarkand', label: '(UTC +05:00) Asia/Samarkand'},
    {value: 'Asia/Tashkent', label: '(UTC +05:00) Asia/Tashkent'},
    {value: 'Asia/Yekaterinburg', label: '(UTC +05:00) Asia/Yekaterinburg'},
    {value: 'Indian/Kerguelen', label: '(UTC +05:00) Indian/Kerguelen'},
    {value: 'Indian/Maldives', label: '(UTC +05:00) Indian/Maldives'},
    {value: 'Asia/Colombo', label: '(UTC +05:30) Asia/Colombo'},
    {value: 'Asia/Kolkata', label: '(UTC +05:30) Asia/Kolkata'},
    {value: 'Asia/Kathmandu', label: '(UTC +05:45) Asia/Kathmandu'},
    {value: 'Antarctica/Vostok', label: '(UTC +06:00) Antarctica/Vostok'},
    {value: 'Asia/Almaty', label: '(UTC +06:00) Asia/Almaty'},
    {value: 'Asia/Bishkek', label: '(UTC +06:00) Asia/Bishkek'},
    {value: 'Asia/Dhaka', label: '(UTC +06:00) Asia/Dhaka'},
    {value: 'Asia/Omsk', label: '(UTC +06:00) Asia/Omsk'},
    {value: 'Asia/Qostanay', label: '(UTC +06:00) Asia/Qostanay'},
    {value: 'Asia/Thimphu', label: '(UTC +06:00) Asia/Thimphu'},
    {value: 'Asia/Urumqi', label: '(UTC +06:00) Asia/Urumqi'},
    {value: 'Indian/Chagos', label: '(UTC +06:00) Indian/Chagos'},
    {value: 'Asia/Yangon', label: '(UTC +06:30) Asia/Yangon'},
    {value: 'Indian/Cocos', label: '(UTC +06:30) Indian/Cocos'},
    {value: 'Antarctica/Davis', label: '(UTC +07:00) Antarctica/Davis'},
    {value: 'Asia/Bangkok', label: '(UTC +07:00) Asia/Bangkok'},
    {value: 'Asia/Barnaul', label: '(UTC +07:00) Asia/Barnaul'},
    {value: 'Asia/Ho_Chi_Minh', label: '(UTC +07:00) Asia/Ho_Chi_Minh'},
    {value: 'Asia/Hovd', label: '(UTC +07:00) Asia/Hovd'},
    {value: 'Asia/Jakarta', label: '(UTC +07:00) Asia/Jakarta'},
    {value: 'Asia/Krasnoyarsk', label: '(UTC +07:00) Asia/Krasnoyarsk'},
    {value: 'Asia/Novokuznetsk', label: '(UTC +07:00) Asia/Novokuznetsk'},
    {value: 'Asia/Novosibirsk', label: '(UTC +07:00) Asia/Novosibirsk'},
    {value: 'Asia/Phnom_Penh', label: '(UTC +07:00) Asia/Phnom_Penh'},
    {value: 'Asia/Pontianak', label: '(UTC +07:00) Asia/Pontianak'},
    {value: 'Asia/Tomsk', label: '(UTC +07:00) Asia/Tomsk'},
    {value: 'Asia/Vientiane', label: '(UTC +07:00) Asia/Vientiane'},
    {value: 'Indian/Christmas', label: '(UTC +07:00) Indian/Christmas'},
    {value: 'Antarctica/Casey', label: '(UTC +08:00) Antarctica/Casey'},
    {value: 'Asia/Brunei', label: '(UTC +08:00) Asia/Brunei'},
    {value: 'Asia/Choibalsan', label: '(UTC +08:00) Asia/Choibalsan'},
    {value: 'Asia/Hong_Kong', label: '(UTC +08:00) Asia/Hong_Kong'},
    {value: 'Asia/Irkutsk', label: '(UTC +08:00) Asia/Irkutsk'},
    {value: 'Asia/Kuala_Lumpur', label: '(UTC +08:00) Asia/Kuala_Lumpur'},
    {value: 'Asia/Kuching', label: '(UTC +08:00) Asia/Kuching'},
    {value: 'Asia/Macau', label: '(UTC +08:00) Asia/Macau'},
    {value: 'Asia/Makassar', label: '(UTC +08:00) Asia/Makassar'},
    {value: 'Asia/Manila', label: '(UTC +08:00) Asia/Manila'},
    {value: 'Asia/Shanghai', label: '(UTC +08:00) Asia/Shanghai'},
    {value: 'Asia/Singapore', label: '(UTC +08:00) Asia/Singapore'},
    {value: 'Asia/Taipei', label: '(UTC +08:00) Asia/Taipei'},
    {value: 'Asia/Ulaanbaatar', label: '(UTC +08:00) Asia/Ulaanbaatar'},
    {value: 'Australia/Perth', label: '(UTC +08:00) Australia/Perth'},
    {value: 'Australia/Eucla', label: '(UTC +08:45) Australia/Eucla'},
    {value: 'Asia/Chita', label: '(UTC +09:00) Asia/Chita'},
    {value: 'Asia/Dili', label: '(UTC +09:00) Asia/Dili'},
    {value: 'Asia/Jayapura', label: '(UTC +09:00) Asia/Jayapura'},
    {value: 'Asia/Khandyga', label: '(UTC +09:00) Asia/Khandyga'},
    {value: 'Asia/Pyongyang', label: '(UTC +09:00) Asia/Pyongyang'},
    {value: 'Asia/Seoul', label: '(UTC +09:00) Asia/Seoul'},
    {value: 'Asia/Tokyo', label: '(UTC +09:00) Asia/Tokyo'},
    {value: 'Asia/Yakutsk', label: '(UTC +09:00) Asia/Yakutsk'},
    {value: 'Pacific/Palau', label: '(UTC +09:00) Pacific/Palau'},
    {value: 'Australia/Darwin', label: '(UTC +09:30) Australia/Darwin'},
    {value: 'Antarctica/DumontDUrville', label: '(UTC +10:00) Antarctica/DumontDUrville'},
    {value: 'Asia/Ust-Nera', label: '(UTC +10:00) Asia/Ust-Nera'},
    {value: 'Asia/Vladivostok', label: '(UTC +10:00) Asia/Vladivostok'},
    {value: 'Australia/Brisbane', label: '(UTC +10:00) Australia/Brisbane'},
    {value: 'Australia/Lindeman', label: '(UTC +10:00) Australia/Lindeman'},
    {value: 'Pacific/Chuuk', label: '(UTC +10:00) Pacific/Chuuk'},
    {value: 'Pacific/Guam', label: '(UTC +10:00) Pacific/Guam'},
    {value: 'Pacific/Port_Moresby', label: '(UTC +10:00) Pacific/Port_Moresby'},
    {value: 'Pacific/Saipan', label: '(UTC +10:00) Pacific/Saipan'},
    {value: 'Australia/Adelaide', label: '(UTC +10:30) Australia/Adelaide'},
    {value: 'Australia/Broken_Hill', label: '(UTC +10:30) Australia/Broken_Hill'},
    {value: 'Antarctica/Macquarie', label: '(UTC +11:00) Antarctica/Macquarie'},
    {value: 'Asia/Magadan', label: '(UTC +11:00) Asia/Magadan'},
    {value: 'Asia/Sakhalin', label: '(UTC +11:00) Asia/Sakhalin'},
    {value: 'Asia/Srednekolymsk', label: '(UTC +11:00) Asia/Srednekolymsk'},
    {value: 'Australia/Currie', label: '(UTC +11:00) Australia/Currie'},
    {value: 'Australia/Hobart', label: '(UTC +11:00) Australia/Hobart'},
    {value: 'Australia/Lord_Howe', label: '(UTC +11:00) Australia/Lord_Howe'},
    {value: 'Australia/Melbourne', label: '(UTC +11:00) Australia/Melbourne'},
    {value: 'Australia/Sydney', label: '(UTC +11:00) Australia/Sydney'},
    {value: 'Pacific/Bougainville', label: '(UTC +11:00) Pacific/Bougainville'},
    {value: 'Pacific/Efate', label: '(UTC +11:00) Pacific/Efate'},
    {value: 'Pacific/Guadalcanal', label: '(UTC +11:00) Pacific/Guadalcanal'},
    {value: 'Pacific/Kosrae', label: '(UTC +11:00) Pacific/Kosrae'},
    {value: 'Pacific/Norfolk', label: '(UTC +11:00) Pacific/Norfolk'},
    {value: 'Pacific/Noumea', label: '(UTC +11:00) Pacific/Noumea'},
    {value: 'Pacific/Pohnpei', label: '(UTC +11:00) Pacific/Pohnpei'},
    {value: 'Asia/Anadyr', label: '(UTC +12:00) Asia/Anadyr'},
    {value: 'Asia/Kamchatka', label: '(UTC +12:00) Asia/Kamchatka'},
    {value: 'Pacific/Funafuti', label: '(UTC +12:00) Pacific/Funafuti'},
    {value: 'Pacific/Kwajalein', label: '(UTC +12:00) Pacific/Kwajalein'},
    {value: 'Pacific/Majuro', label: '(UTC +12:00) Pacific/Majuro'},
    {value: 'Pacific/Nauru', label: '(UTC +12:00) Pacific/Nauru'},
    {value: 'Pacific/Tarawa', label: '(UTC +12:00) Pacific/Tarawa'},
    {value: 'Pacific/Wake', label: '(UTC +12:00) Pacific/Wake'},
    {value: 'Pacific/Wallis', label: '(UTC +12:00) Pacific/Wallis'},
    {value: 'Antarctica/McMurdo', label: '(UTC +13:00) Antarctica/McMurdo'},
    {value: 'Pacific/Auckland', label: '(UTC +13:00) Pacific/Auckland'},
    {value: 'Pacific/Enderbury', label: '(UTC +13:00) Pacific/Enderbury'},
    {value: 'Pacific/Fakaofo', label: '(UTC +13:00) Pacific/Fakaofo'},
    {value: 'Pacific/Fiji', label: '(UTC +13:00) Pacific/Fiji'},
    {value: 'Pacific/Tongatapu', label: '(UTC +13:00) Pacific/Tongatapu'},
    {value: 'Pacific/Chatham', label: '(UTC +13:45) Pacific/Chatham'},
    {value: 'Pacific/Apia', label: '(UTC +14:00) Pacific/Apia'},
    {value: 'Pacific/Kiritimati', label: '(UTC +14:00) Pacific/Kiritimati'},
];
