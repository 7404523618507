import {FieldTemplate} from '@contractool/schema';
import * as React from 'react';

import {Form} from 'components/Form';
import {TextInput} from 'components/TextInput';
import {fieldDependsOnOtherThan} from 'features/form/field';
import {inputAcceptsOnlyNumbersOf} from 'features/form/input';

const NumberInput = inputAcceptsOnlyNumbersOf(TextInput);

const NdaInputField: React.FC<{
    field: FieldTemplate;
    option?: string;
    className?: string;
}> = ({className, field, option}) => {
    return (
        <Form.Field
            key={field.name}
            name={option + field.name}
            label={field.label}
            className={className}
            component={NumberInput}
            right={
                <a
                    href="https://legal.one.dell.com/sites/Legal4Clients/NDAs/Pages/default.aspx"
                    className="text-blue-700"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    NDA Tool
                </a>
            }
            {...field.props}
        />
    );
};

const DependentNdaInputField = fieldDependsOnOtherThan(NdaInputField);

export default DependentNdaInputField;
