import React, {FC, FormEvent, useState} from 'react';
import {ActivityBody, ActivityContainer, ActivityHeader} from '../ActivityPanel';
import {CommentForm} from '../../../components/CommentForm';
import {useToasts} from '../../../components/Toast';
import {http} from '../../../utils/http';
import {LogFull, Comment} from '@contractool/schema';
import {byOldestUp} from 'utils/sort';

export const ConversationLog: FC<{
    log: LogFull;
    onUpdate: () => void;
}> = ({log, onUpdate}) => {
    return (
        <ActivityContainer>
            <>
                <ActivityHeader
                    causer={log.causer}
                    created_at={log.created_at}
                    timezone={log.causer.timezone}
                />
                <ActivityBody className="mb-8 mr-14">
                    <>{log.message.body && bodyParser(log.message.body)}</>
                </ActivityBody>

                {log.comments &&
                    log.comments
                        .sort((a: Comment, b: Comment) => byOldestUp(a.created_at, b.created_at))
                        .map((comment, index) => (
                            <ReplyComment
                                key={'reply-messages' + log.id + index}
                                comment={comment}
                            />
                        ))}

                <ReplyMessage log={log} onUpdate={onUpdate} />
            </>
        </ActivityContainer>
    );
};

const ReplyComment: FC<{
    comment: Comment;
}> = ({comment}) => {
    return (
        <div className="ml-12">
            <ActivityHeader
                causer={comment.commenter}
                created_at={comment.created_at}
                timezone={comment.commenter.timezone}
            />
            <ActivityBody className="mb-8 pr-3">
                <>{bodyParser(comment.body)}</>
            </ActivityBody>
        </div>
    );
};

const ReplyMessage: FC<{
    log: LogFull;
    fake_log?: {id: number};
    onUpdate: () => void;
}> = ({log, onUpdate}) => {
    const [comment, setComment] = useState('');
    const {success} = useToasts();

    const submitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        http.post<Comment>(log.comments_url, {body: comment, type: log.meta.type}).then(
            (response) => {
                onUpdate();
                setComment('');
                success(`Comment was successfully added by ${response.data.commenter.name}.`);
            },
        );
    };

    return <CommentForm submitHandler={submitHandler} comment={comment} setComment={setComment} />;
};

//TODO: Fix when people append some bullsh*t text after mentioning a person
const bodyParser = (body: string) => {
    const result = body.replace(/@[a-zA-Z0-9_.]+(?<![_.])/g, (username) => {
        return `<span class="font-medium text-blue-500">${username}</span>`;
    });
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: result,
            }}
        />
    );
};
