import React, {FC} from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import {Icon} from '../../../components/Icon';
import {ActivityContainer, ActivityHeader, ActivityBody} from '../ActivityPanel';
import {Log} from '@contractool/schema';
import './../Widgets/Milestones.css';
export const MilestoneLog: FC<{log: Log}> = ({log}) => {
    const newStyles: any = {
        variables: {
            light: {
                diffViewerBackground: '#F1EFEF',
                diffViewerColor: '#24292e',
                addedBackground: '#F1EFEF',
                addedColor: '#24292e',
                removedBackground: '#ffffff',
                removedColor: '#24292e',
                wordAddedBackground: '#BFD8FE',
                wordRemovedBackground: '#BFD8FE',
            },
        },
        line: {
            padding: '10px 2px',
        },
    };

    let milestone = log.message.extra.milestone;
    return (
        <ActivityContainer >
            <ActivityHeader
                causer={log.causer}
                desc={log.message.title}
                created_at={log.created_at}
                timezone="Europe/Bratislava"
            />
            <ActivityBody className="mb-8 mr-14">
                <div>
                    <div className="mb-4 text-gray-600">
                        {milestone.key === 'dell' ? 'Dell Engineering activities' : ''}
                        {milestone.key === 'proof' ? 'Proof of performance' : ''}
                        {milestone.key === 'amount' ? 'Payment amount' : ''}
                    </div>
                        <div className="diff-viewer">
                    {log.message.extra.milestone.key === 'amount' ? (
                        <div className="flex">
                            $ {milestone.from.value}
                            <Icon
                                name="arrow_forward"
                                size={6}
                                className="text-gray-600 mx-8"
                            />
                            <span className="text-blue-700">$ {milestone.to.value}</span>
                        </div>
                    ) : (
                        <ReactDiffViewer

                            styles={newStyles}
                            oldValue={milestone.from.value.replace('&nbsp;', ' ')}
                            newValue={milestone.to.value.replace('&nbsp;', ' ')}
                            splitView={true}
                            hideLineNumbers={true}
                        />
                    )}
                        </div>
                </div>
            </ActivityBody>
        </ActivityContainer>
    );
};
