import React, {FC, useState, useContext} from 'react';
import {Page} from '../components/Page';
import {useRequest} from '../utils/hooks/useRequest';
import {http} from '../utils/http';
import {MainPanel} from './projects/ProjectDetail';
import {Editor} from '@tinymce/tinymce-react';
import {Button} from '../components/Button';
import {useToasts} from '../components/Toast';
import Search from '../components/Search';
import {Icon} from '../components/Icon';
import {useEffect} from 'react';
import {TranslationContext} from 'contexts';

export function ClauseLibrary() {
    const [clauses, {refresh}] = useRequest<string[]>('/api/library/clauses', []);
    const [newClause, setNewClause] = useState<string>('');
    const [filterKey, setFilterKey] = useState<string>('');
    const {translate} = useContext(TranslationContext);

    const {success, info} = useToasts();
    const init = {
        content_style: '.mce-content-body {padding : 7px}',
        height: 200,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
            'undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help',
    };

    const saveNew = () => {
        console.log('New', newClause);
        info(`${translate('Adding clause')}...`);
        http.put('/api/library/clauses', {clause: newClause}).then(() => {
            setNewClause('');
            refresh();
            success(`${translate('Clause was successfully added')}.`);
        });
    };
    console.log('clauses', clauses);
    let filteredClause = clauses
        ? filterKey !== ''
            ? clauses
                  .filter((clause) => clause.toLowerCase().indexOf(filterKey.toLowerCase()) !== -1)
                  .map((clause: string, index: number) => {
                      return (
                          <Clause key={`clause-${index}`} editorInit={init} initValue={clause} />
                      );
                  })
            : clauses.map((clause: string, index: number) => {
                  return <Clause key={`clause-${index}`} editorInit={init} initValue={clause} />;
              })
        : [];

    return (
        <Page heading={translate('Clause library')}>
            <Search
                value={filterKey}
                onChange={(phrase) => {
                    setFilterKey(phrase);
                }}
                onClear={() => setFilterKey('')}
                placeholder={`${translate('Search clause')}...`}
            />
            <MainPanel heading={translate('Add new clause')} className=" rounded-lg py-5">
                <Editor
                    initialValue={newClause}
                    init={init}
                    apiKey="rth1kxg3nn0dla1xo4fpv0wzgpi8j09n56838c4akj5k3hc0"
                    onEditorChange={(data) => {
                        console.log('onEditorChange', data);
                        setNewClause(data);
                    }}
                />
                <Button onClick={saveNew} className="mt-5">
                    {translate('Add')}
                </Button>
            </MainPanel>
            <div>{filteredClause.length ? filteredClause : null}</div>
        </Page>
    );
}

const Clause: FC<{
    editorInit: any;
    initValue: string;
}> = ({editorInit, initValue}) => {
    const [show, setShow] = useState<boolean>(false);
    const [clause, setClause] = useState<string>(initValue);

    const deleteClause = () => {
        console.log('deleting clause with this text:', clause);
    };
    const saveClause = () => {
        console.log('saving clause with this text:', clause);
    };

    useEffect(() => {
        setClause(initValue);
    }, [initValue]);

    let stripped = clause.replace(/(<([^>]+)>)/gi, '');

    return (
        <>
            <div
                className="w-full flex justify-between items-center border-b border-gray-200 py-6"
                onClick={() => setShow((prevState) => !prevState)}
            >
                <div
                    className="font-semibold text-lg text-gray-700"
                    dangerouslySetInnerHTML={{
                        __html:
                            stripped.length > 190 ? `${stripped.substring(0, 190)}...` : stripped,
                    }}
                />
                <Icon name="arrow_downward" size={5} className={show ? 'rotate-180' : ''} />
            </div>
            {show ? (
                <div className="mt-3 flex">
                    <div>
                        <Editor
                            init={editorInit}
                            initialValue={clause}
                            apiKey="rth1kxg3nn0dla1xo4fpv0wzgpi8j09n56838c4akj5k3hc0"
                            onEditorChange={(data) => setClause(data)}
                        />
                    </div>
                    <div className="ml-5">
                        <Button onClick={deleteClause} color="white" icon="delete" />
                        <br />
                        <Button
                            onClick={saveClause}
                            className="mt-5"
                            color="white"
                            icon="arrow_downward"
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};
