import React from 'react';
import {Section, Variation} from './DesignSystem';
import {Pagination} from '../../components/Pagination';
import {useQueryParams} from '../../utils/hooks/useParams';

export function PaginationControls() {
    const [params, {setParam}] = useQueryParams({page: 1});

    return (
        <>
            <Section title="Basic pagination">
                <Variation>
                    <Pagination
                        currentPage={params.page}
                        totalPages={23}
                        totalBlocks={7}
                        onSelect={(page) => setParam('page', page)}
                    />
                </Variation>
            </Section>
        </>
    );
}
