import {Report, UserGroup} from '@contractool/schema';
import React, {FC, useContext} from 'react';
import moment from 'moment';

import OpenByState from './partial/OpenByState';
import Trend from './partial/Trend';
import ProjectsPerPerson from './partial/ProjectsPerPerson';
import SupplierPoc from './partial/SupplierPoc';
import AverageStateTat from './partial/AverageStateTat';
import {Tab, Tabs} from 'components/Tabs';
import Funding from './partial/Funding';
import Quartals from './partial/Quartals';
import {Option} from 'components/Dropdown';
import {GuardContext} from 'contexts';

const Reports: FC<{
    dateFilters: any;
    reports: Report[];
    usersAsOptions: Option<any>[];
    suppliersAsOptions: Option<any>[];
}> = ({dateFilters, reports, usersAsOptions, suppliersAsOptions}) => {
    const {user} = useContext(GuardContext);

    return (
        <>
            <Tabs selected="open" className="">
                <Tab name="open" heading="Open projects" className="pt-4">
                    <div className="flex w-full">
                        <div className="flex-1 rounded-xl bg-white mb-3">
                            <OpenByState
                                report={reports[0]}
                                dateFilters={dateFilters}
                                usersAsOptions={usersAsOptions}
                                suppliersAsOptions={suppliersAsOptions}
                            />
                            <AverageStateTat report={reports[1]}/>
                        </div>
                        <div style={{width: '300px'}} className="ml-3">
                            <Trend
                                report={reports[2]}
                                projectFilter={{state: 'executed'}}
                                dateFilters={dateFilters}
                                usersAsOptions={usersAsOptions}
                                suppliersAsOptions={suppliersAsOptions}
                            />
                            <Trend
                                report={reports[3]}
                                projectFilter={{states: ['field', 'director', 'internal']}}
                                dateFilters={dateFilters}
                                usersAsOptions={usersAsOptions}
                                suppliersAsOptions={suppliersAsOptions}
                            />
                            <Trend
                                report={reports[4]}
                                projectFilter={{
                                    states: ['proposal',
                                        'alignment',
                                        'negotiation',
                                        'field',
                                        'director',
                                        'internal',
                                        'vendor'],
                                    created_at: {until: moment().subtract(30, 'd').format('YYYY-MM-DD')}
                                }}
                                dateFilters={dateFilters}
                                usersAsOptions={usersAsOptions}
                                suppliersAsOptions={suppliersAsOptions}
                            />
                        </div>
                    </div>
                    <ProjectsPerPerson
                        report={reports[5]}
                        dateFilters={dateFilters}
                        usersAsOptions={usersAsOptions}
                        suppliersAsOptions={suppliersAsOptions}
                    />
                    <SupplierPoc
                        report={reports[6]}
                        dateFilters={dateFilters}
                        usersAsOptions={usersAsOptions}
                        suppliersAsOptions={suppliersAsOptions}
                    />
                </Tab>
                {(user.groups.filter((g: UserGroup) => g.key === 'supplier').length === 0 ?
                    <Tab name="funding" heading="Funding reports" className="pt-4">
                        <div className="flex w-full">
                            <div className="flex-1 rounded-xl bg-white mb-3 mr-1">
                                <Funding
                                    report={reports[7]}
                                    dateFilters={dateFilters}
                                    usersAsOptions={usersAsOptions}
                                    suppliersAsOptions={suppliersAsOptions}
                                />
                            </div>
                            <div className="flex-1 rounded-xl bg-white mb-3 ml-2">
                                <Funding
                                    report={reports[10]}
                                    dateFilters={dateFilters}
                                    usersAsOptions={usersAsOptions}
                                    suppliersAsOptions={suppliersAsOptions}
                                />
                            </div>
                        </div>
                        <div className="flex w-full">
                            <Quartals
                                report={reports[8]}
                                dateFilters={dateFilters}
                                usersAsOptions={usersAsOptions}
                                suppliersAsOptions={suppliersAsOptions}
                            />
                        </div>
                    </Tab> : <></>)
                }
                <Tab name="executed" heading="Executed projects" className="pt-4">
                    <div className="flex w-full">
                        <div className="flex-1 rounded-xl bg-white mb-3">
                            <SupplierPoc
                                report={reports[9]}
                                dateFilters={dateFilters}
                                usersAsOptions={usersAsOptions}
                                suppliersAsOptions={suppliersAsOptions}
                            />
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
};

export default Reports;
