import {FieldTemplate, LogFull} from '@contractool/schema';
import React, {FC, useContext} from 'react';
import {difference, groupBy} from 'lodash';

import {AppContext} from 'contexts';
import {Tab} from 'pages/projects/Widgets/Group/components/Member/interfaces';
import {ActivityContainer, ActivityHeader, ActivityBody} from '../../ActivityPanel';

interface Map {
    [key: string]: any
}

const MetaUpdateRenderer: FC<{ from: any, to: any }> = ({from, to}) => {
    // todo: find better solution
    const {config} = useContext(AppContext);
    const {widgets_fields: widgetFields} = config;

    const oldWidgetFields = from?.widget_fields;
    const newWidgetFields = to?.widget_fields;
    const widgetFieldExists = (member: string) => {
        return widgetFields[member];
    };

    const oldMembers = Object.keys(oldWidgetFields || {}).filter(widgetFieldExists);
    const newMembers = Object.keys(newWidgetFields || {}).filter(widgetFieldExists);


    const addedMembers = difference(newMembers, oldMembers).filter(widgetFieldExists);
    const removedMembers = difference(oldMembers, newMembers).filter(widgetFieldExists);

    // eslint-disable-next-line
    const fields = React.useMemo(() => {
        const map: Map = {};
        for (let member in widgetFields) {
            if (widgetFields.hasOwnProperty(member)) {
                map[member] = widgetFields[member].tabs ? widgetFields[member].tabs.reduce((fields: FieldTemplate[], tab: Tab) => [...fields, ...tab.fields], []) : widgetFields[member].fields;

                map[member] = groupBy(map[member], 'name');
            }
        }

        return map;
    }, [widgetFields]);

    return (
        <div>
            {addedMembers.map((member, idx) => (
                <div key={idx}>
                    Added new field - {widgetFields[member].name}
                    {/*{newWidgetFields[member]?.values && Object.keys(newWidgetFields[member].values).map((field, index) => (*/}
                    {/*    <div key={index}>*/}
                    {/*        {newWidgetFields[member].values[field] === true ? (*/}
                    {/*            <>*/}
                    {/*                {fields[member][field][0].label}: Yes*/}
                    {/*            </>*/}
                    {/*        ) : (*/}
                    {/*            <>*/}
                    {/*                {newWidgetFields[member].values[field] === false ? (*/}
                    {/*                    <>*/}
                    {/*                        {fields[member][field][0].label}: No*/}
                    {/*                    </>*/}
                    {/*                ) : (*/}
                    {/*                    <>*/}
                    {/*                        {newWidgetFields[member].values[field] !== null && (*/}
                    {/*                            <>*/}
                    {/*                                {fields[member][field][0].label}: {newWidgetFields[member].values[field]}*/}
                    {/*                            </>*/}
                    {/*                        )}*/}
                    {/*                    </>*/}
                    {/*                )}*/}
                    {/*            </>*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </div>
            ))}
            {removedMembers.map((member, idx) => (
                <div key={idx}>
                    Removed a field - {widgetFields[member].name}
                    {/*{oldWidgetFields[member]?.values && Object.keys(oldWidgetFields[member].values).map((field, index) => (*/}
                    {/*    <div key={index}>*/}
                    {/*        {fields[member][field][0].label}: {oldWidgetFields[member].values[field]}*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </div>
            ))}
            {newMembers && newMembers.filter(member => !addedMembers.includes(member))
                .filter(member => JSON.stringify(oldWidgetFields[member].values) !== JSON.stringify(newWidgetFields[member].values))
                .map((member, idx) => (
                    <div key={idx}>
                        Updated field - {widgetFields[member].name}
                        {/*{Object.keys(oldWidgetFields[member].values)*/}
                        {/*    .filter(field => oldWidgetFields[member].values[field])*/}
                        {/*    .filter(field => oldWidgetFields[member].values[field] !== newWidgetFields[member].values[field])*/}
                        {/*    .map((field, index) => (*/}
                        {/*        <div key={index}>*/}
                        {/*            {fields[member][field][0].label}: from "{oldWidgetFields[member].values[field]}" to "{newWidgetFields[member].values[field]}"*/}
                        {/*        </div>*/}
                        {/*    ))*/}
                        {/*}*/}
                        {/*{Object.keys(newWidgetFields[member].values)*/}
                        {/*    .filter(field => !oldWidgetFields[member].values[field])*/}
                        {/*    .filter(field => newWidgetFields[member].values[field])*/}
                        {/*    .map((field, index) => (*/}
                        {/*        <div key={index}>*/}
                        {/*            {fields[member][field][0].label}: from "{oldWidgetFields[member].values[field]}" to "{newWidgetFields[member].values[field]}"*/}
                        {/*        </div>*/}
                        {/*    ))*/}
                        {/*}*/}
                    </div>
                ))
            }
        </div>
    );
};

const ProjectUpdateLog: FC<{
    desc: string;
    log: LogFull;
}> = ({desc, log}) => {
    // const iconFrom = iconHandler(log.message.extra.from);
    // const iconTo = iconHandler(log.message.extra.to);
    const metaChanges = log.message.extra.changes?.meta;

    return (
        <ActivityContainer>
            <ActivityHeader
                causer={log.causer}
                desc={desc}
                created_at={log.created_at}
                timezone={log.causer.timezone}
            />
            {(metaChanges?.from || metaChanges?.to) ? (
                <ActivityBody>
                    <MetaUpdateRenderer
                        from={metaChanges.from}
                        to={metaChanges.to}
                    />
                </ActivityBody>
            ) : (
                <ActivityBody className="flex items-center">
                    <div className="flex items-center" dangerouslySetInnerHTML={{
                        __html: log.message.body + "",
                    }}>
                    </div>
                </ActivityBody>
            )}
        </ActivityContainer>
    );
};

const PureProjectUpdateLog = React.memo(ProjectUpdateLog);

export default PureProjectUpdateLog;
