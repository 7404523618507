import React, {FC, ReactElement} from 'react';

export const Page: FC<{heading: string; right?: ReactElement | boolean}> = ({
    heading,
    children,
    right,
}) => {
    return (
        <div className="p-16">
            <div className="flex justify-between">
                <h1 className="text-xl select-none mb-12 text-gray-800">{heading}</h1>

                <div>{right}</div>
            </div>

            {children}
        </div>
    );
};
