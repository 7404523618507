import * as React from 'react';

const dropdownPreSelectsFirstOptionOf = (Component: React.ComponentType<any>) =>
    (props: any) => {
        const {value, onChange, options} = props;
        React.useEffect(() => {
            if (value === '' && options.length > 0) {
                // '' is default value from getValue of formContext
                // todo: we might need null instead of '' for some input
                onChange(options[0].value);
            }
        }, [value, onChange, options]);

        return <Component {...props} />
    };

export default dropdownPreSelectsFirstOptionOf;
